import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogOverlay,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog"
import { getUserDetails } from "@/redux/features/auth/authSlice";
import axios from "axios";
import { useRef, useState } from "react";
import toast from "react-hot-toast";
import { TiWarningOutline } from "react-icons/ti";
import { useDispatch } from "react-redux";

const CancelSubscription = () => {

    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const cancelBtn = useRef(null)

    const cancelSubs = async () => {
        const token = localStorage.getItem('token')
        try {
            setLoading(true)
            toast.loading("Cancelling Subscription...", { id: "subs" })
            const { data } = await axios.post("/cancelsub", {}, { headers: { Authorization: `Bearer ${token}` } })
            await dispatch(getUserDetails()).unwrap()
            toast.success(data?.status, { id: "subs" })
            setLoading(false)
            if (cancelBtn.current) {
                cancelBtn.current.click()
            }
        } catch (error) {
            setLoading(false)
            console.log(error);
            toast.error(error?.response?.data?.detail || error.message || "Oops! Something went wrong. Please try again.", { id: "subs" });
        }
    }

    return (
        <Dialog>
            <DialogTrigger asChild>
                <p className='text-red-400 text-xs font-medium hover:underline cursor-pointer' >Cancel Subscription</p>
            </DialogTrigger>
            <DialogOverlay className="z-[9999]" >
                <DialogContent className="max-w-sm w-full p-4 bg-slate-900 border-red-700">
                    <DialogHeader>
                        <DialogTitle className="text-red-500 text-xl font-potta flex items-center gap-1" ><TiWarningOutline size={28} /> Cancel Subscription</DialogTitle>
                        <DialogDescription className="text-slate-300 font-medium" >
                            By canceling your subscription, you&apos;ll keep your remaining credits until the end of your subscription month. However, all other perks will be revoked immediately.                    </DialogDescription>
                    </DialogHeader>
                    <div className="flex items-center gap-4" >
                        <button disabled={loading} onClick={cancelSubs} type="button" className=" disabled:animate-pulse w-full bg-red-500 hover:bg-red-600 text-black px-4 py-2 rounded font-medium text-sm" >{loading ? "Cancelling..." : "Cancel Subscription"}</button>
                        <DialogClose asChild>
                            <button ref={cancelBtn} type="button" className="w-full bg-slate-700 hover:bg-slate-600 text-slate-100 px-4 py-2 rounded font-medium text-sm" >No</button>
                        </DialogClose>
                    </div>
                </DialogContent>
            </DialogOverlay>
        </Dialog>
    )
}

export default CancelSubscription