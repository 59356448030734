import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    song: null,
    playing: false,
    homeMusic: false,
    loaded: false,
    cover: null,
    userSongs: [],
    discoverSongs: [],
    likedSongs: [],
    playLoading: false,
    playingFrom: null,
    allUserSongs: [],
    mobilePlayer: false,
    viewSong: null,
    showViewSong: false,
    viewSongFrom: null,
    currentPlaylist: [],
}

const musicSlice = createSlice({
    name: "music",
    initialState,
    reducers: {
        SET_SONG(state, action) {
            state.song = action.payload
        },
        SET_CURRENT_PLAYLIST: (state, action) => {
            state.currentPlaylist = action.payload;
        },
        SET_PLAYING(state, action) {
            state.playing = action.payload
        },
        SET_HOMEMUSIC(state, action) {
            state.homeMusic = action.payload
        },
        SET_LOADED(state, action) {
            state.loaded = action.payload
        },

        SET_USERSONGS(state, action) {
            state.userSongs = action.payload
        },
        SET_DISCSONGS(state, action) {
            state.discoverSongs = action.payload
        },
        SET_PLAYLOADING(state, action) {
            state.playLoading = action.payload
        },
        SET_PLAYINGFROM(state, action) {
            state.playingFrom = action.payload
        },
        APPEND_SONG(state, action) {
            state.userSongs = [action.payload, ...state.userSongs]
        },
        APPEND_SONGS(state, action) {
            state.userSongs = [...action.payload, ...state.userSongs]
        },
        ADD_SONGS(state, action) {
            const newSongs = action.payload;
            const existingIds = new Set(state.userSongs.map(song => song.song_id));
            const filteredSongs = newSongs.filter(song => !existingIds.has(song.song_id));
            state.userSongs = [...state.userSongs, ...filteredSongs];
        },
        ADD_DISCSONGS(state, action) {
            state.discoverSongs = [...state.discoverSongs, ...action.payload];
        },
        SET_LIKEDSONGS(state, action) {
            state.likedSongs = action.payload
        },
        ADD_LIKEDSONGS(state, action) {
            state.likedSongs = [...state.likedSongs, ...action.payload];
        },
        REMOVE_LIKESONG(state, action) {
            const songId = action.payload;
            const filteredSong = state.likedSongs.filter((likedSong) => likedSong.song_id !== songId)
            state.likedSongs = filteredSong
        },
        ADD_LIKESONG(state, action) {
            const song = action.payload
            const newSongs = [song, ...state.likedSongs]
            state.likedSongs = newSongs
        },
        SET_ALLUSERSONGS(state, action) {
            state.allUserSongs = action.payload
        },
        SET_MOBILEPLAYER(state, action) {
            state.mobilePlayer = action.payload
        },
        SET_VIEWSONG(state, action) {
            state.viewSong = action.payload
        },
        SET_SHOWVIEWSONG(state, action) {
            state.showViewSong = action.payload
        },
        SET_VIEWSONGFROM(state, action) {
            state.viewSongFrom = action.payload
        },
        SET_COVER_IMAGE_URL(state, action) {
            state.cover = action.payload
        },
        REMOVE_DELETED_SONG(state, action) {
            state.userSongs = state.userSongs.filter(song => song?.song_id !== action.payload)
        }
    }
});

export const {
    SET_PLAYING,
    SET_HOMEMUSIC,
    SET_LOADED,
    SET_SONG,
    SET_USERSONGS,
    SET_DISCSONGS,
    SET_PLAYLOADING,
    SET_PLAYINGFROM,
    APPEND_SONG,
    APPEND_SONGS,
    ADD_SONGS,
    ADD_DISCSONGS,
    ADD_LIKEDSONGS,
    SET_LIKEDSONGS,
    REMOVE_LIKESONG,
    ADD_LIKESONG,
    SET_ALLUSERSONGS,
    SET_MOBILEPLAYER,
    SET_VIEWSONG,
    SET_SHOWVIEWSONG,
    SET_CURRENT_PLAYLIST,
    SET_VIEWSONGFROM,
    SET_COVER_IMAGE_URL,
    REMOVE_DELETED_SONG
} = musicSlice.actions

export default musicSlice.reducer