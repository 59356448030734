import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-hot-toast';



//Change to playlist slug
export const fetchPlaylists = createAsyncThunk(
  'playlists/fetchPlaylists',
  async (page, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/playlists?page=${page}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      return response.data.playlists || [];
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);



//Changed to playlist slug
export const deletePlaylist = createAsyncThunk(
  'playlists/deletePlaylist',
  async (playlistId, { rejectWithValue }) => {
    try {
      await axios.post(
        `/playlist/delete?playlist_slug=${playlistId}`,
        {},  // empty body
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        }
      );
      return playlistId;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);

    }
  }
);



//Changed to playlist slug
export const addPlaylist = createAsyncThunk(
  'playlist/addPlaylist',
  async ({ playlistName, publish }, { rejectWithValue }) => {
    const token = localStorage.getItem('token');

    if (!token) {
      toast.error("Authorization token missing. Please log in again.");
      return rejectWithValue("Authorization token missing.");
    }

    try {
      const response = await axios.post(
        '/playlist/create',
        { name: playlistName, publish: publish },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      toast.success("Playlist added successfully");
      return response.data;
    } catch (error) {
      console.error('Error creating playlist:', error);
      if (error.response && error.response.data) {
        toast.error(`Failed to create playlist: ${error.response.data.message}`);
        return rejectWithValue(error.response.data.message);
      } else {
        toast.error("Failed to create playlist");
        return rejectWithValue(error.message);
      }
    }
  }
);


//Changed to playlist slug
export const fetchPlaylistSongs = createAsyncThunk(
  'playlists/fetchPlaylistSongs',
  async ({ playlistId, page }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/playlist/${playlistId}?page=${page}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
    

      const { songs: playlistSongs, name, publish, user: playlistOwner } = response.data;
      const { playlistData } = response;

      const playlistCoverImage = playlistSongs.length > 0
        ? playlistSongs[0].image_url || playlistSongs[0].cover_image_url
        : "";

      return {
        playlistSongs,
        name: name || "My Playlist",
        playlistCoverImage,
        publish,
        playlistData,
        playlistOwner
      };
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);


export const fetchSongs = createAsyncThunk(
  'playlists/fetchSongs',
  async (page, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/allsongs?page=${page}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      const newSongs = response.data.songs;
      return {
        newSongs
      };
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);


//Changed to playlist slug
export const removeSong = createAsyncThunk(
  'playlists/removeSong',
  async ({ songId, playlistId }, { rejectWithValue }) => {
    try {
      const response = await axios.post('/playlist/remove', {
        song_id: songId,
        playlist_slug: playlistId
      }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      console.log('Remove song response:', response.data);
      return { songId };
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);


//Changed to playlist slug
export const addSong = createAsyncThunk(
  'playlists/addSong',
  async ({ songId, playlistId }, { rejectWithValue }) => {
    try {
      const response = await axios.post('/playlist/add', {
        song_id: songId,
        playlist_slug: playlistId,
      },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        })

      // await dispatch(fetchPlaylistSongs({ playlistId, page: 1 }));

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);

    }
  }
);






const playlistsSlice = createSlice({
  name: 'playlists',
  initialState: {
    playlists: [],
    playlistCoverImage: null,
    page: 1,
    status: null,
    error: null,
    songs: [],
    dialogSongs: [],
    playlistName: '',
    isLoading: false,
    hasMore: false,
    playlistData: null,
  },

  reducers: {
    setPlaylistCoverImage: (state, action) => {
      state.playlistCoverImage = action.payload;
    },
    APPEND_SONG_TO_PLAYLIST: (state, action) => {
      state.songs.unshift(action.payload);
    },
    APPEND_PLAYLIST_TO_PLAYLIST: (state, action) => {
      state.playlists.unshift(action.payload);
    },
    REMOVE_DELETED_SONG: (state, action) => {
      state.songs = state.songs.filter(song => song.song_id !== action.payload);
    },
    removePlaylist: (state, action) => {
      state.playlists = state.playlists.filter(
        playlist => playlist.playlist_slug !== action.payload
      );
    },
    UPDATE_PLAYLIST_DATA: (state, action) => {
      state.playlistData = action.payload;
    },
    UPDATE_PLAYLIST_NAME: (state, action) => {
      state.playlistName = action.payload;
    }

  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlaylists.pending, (state) => {
        state.status = 'loading';
        state.error = null;
        state.isLoading = true;
      })
      .addCase(fetchPlaylists.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.playlists = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchPlaylists.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
        state.isLoading = false;

      })
      // eslint-disable-next-line no-unused-vars
      .addCase(deletePlaylist.pending, (state, action) => {
        state.isLoading = true;
      })
      // eslint-disable-next-line no-unused-vars
      .addCase(deletePlaylist.fulfilled, (state, action) => {
        toast.success("Playlist deleted successfully");
        state.isLoading = false;
      })
      .addCase(deletePlaylist.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      // eslint-disable-next-line no-unused-vars
      .addCase(addPlaylist.fulfilled, (state, action) => {
        // state.status = 'succeeded';
      })
      .addCase(addPlaylist.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(fetchPlaylistSongs.pending, (state) => { state.isLoading = true; state.error = null; })
      .addCase(fetchPlaylistSongs.fulfilled, (state, action) => {
        const { playlistSongs, name, playlistCoverImage } = action.payload;
        state.isLoading = false;
        state.songs = playlistSongs;
        state.playlistName = name || '';
        state.playlistCoverImage = playlistCoverImage;
        state.playlistData = action.payload;
        state.playlistOwner = action.payload.playlistOwner;
      })
      .addCase(fetchPlaylistSongs.rejected, (state, action) => { state.isLoading = false; state.error = action.payload; })
      // New fetchSongs 
      .addCase(fetchSongs.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchSongs.fulfilled, (state, action) => {
        state.status = 'succeeded';
        if (action.payload.length === 0 || action.payload.length < 10) {
          state.hasMore = false;
        }
        state.dialogSongs = [...state.dialogSongs, ...action.payload];
      })
      .addCase(fetchSongs.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

      // New removeSong 
      .addCase(removeSong.pending, (state) => {
        state.status = 'loading';
      })
      // eslint-disable-next-line no-unused-vars
      .addCase(removeSong.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(removeSong.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(addSong.pending, (state) => {
        state.status = 'loading';
      })
      // eslint-disable-next-line no-unused-vars
      .addCase(addSong.fulfilled, (state, action) => {
        state.status = 'succeeded';

      })

      .addCase(addSong.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });

  }
});

export const { UPDATE_PLAYLIST_NAME, UPDATE_PLAYLIST_DATA, removePlaylist, APPEND_PLAYLIST_TO_PLAYLIST, REMOVE_DELETED_SONG, setPlaylistCoverImage, APPEND_SONG_TO_PLAYLIST } = playlistsSlice.actions;
export default playlistsSlice.reducer;