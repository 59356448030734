import TopBar from '@/components/navbar/TopBar';
import BottomBar from '@/components/sidebar/BottomBar';

const PlaylistViewLoader = () => {
    return (
        <div className="max-w-7xl 2xl:max-w-full min-h-screen mx-auto bg-gradient-to-br from-slate-950 via-slate-900 to-slate-800 rounded-lg overflow-hidden shadow-lg">
            <TopBar heading="Loading Playlist" />
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 mt-20 min-h-screen p-6">
                {/* Cover Image & Details */}
                <div className="lg:col-span-2 p-4 space-y-4 animate-pulse">
                    <div className="relative overflow-hidden rounded-lg bg-slate-900 aspect-video lg:aspect-w-16 lg:aspect-h-9">
                        <div className="absolute inset-0 bg-gray-700"></div>
                    </div>
                    <div className="p-4 bg-gray-800/50 backdrop-blur-sm rounded-lg shadow-lg">
                        <div className="space-y-3">
                            <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
                                <div className="h-8 bg-gray-700 rounded w-3/4 mb-2"></div>
                                <div className="h-4 bg-gray-700 rounded w-1/4"></div>
                            </div>
                            <div className="border-t border-gray-700/50 pt-3">
                                <div className="flex items-center space-x-3">
                                    <div className="h-8 w-8 rounded-full bg-gray-700"></div>
                                    <div className="space-y-2">
                                        <div className="h-4 bg-gray-700 rounded w-32"></div>
                                        <div className="h-3 bg-gray-700 rounded w-48"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Playlist Sidebar */}
                <div className="lg:max-h-[calc(100vh-14rem)] overflow-y-auto border-l border-slate-800 p-4 animate-pulse">
                    <div className="flex items-center justify-between mb-4">
                        <div className="h-6 bg-gray-700 rounded w-1/2"></div>
                        <div className="h-6 w-6 bg-gray-700 rounded-full"></div>
                    </div>
                    {[...Array(5)].map((_, index) => (
                        <div key={index} className="flex items-center gap-3 p-3 bg-slate-800/50 rounded-lg mb-2">
                            <div className="w-20 h-12 bg-gray-700 rounded"></div>
                            <div className="flex-1 space-y-2">
                                <div className="h-4 bg-gray-700 rounded w-3/4"></div>
                                <div className="h-3 bg-gray-700 rounded w-1/2"></div>
                            </div>
                            <div className="h-5 w-5 bg-gray-700 rounded-full"></div>
                        </div>
                    ))}
                </div>
            </div>
            <BottomBar />
        </div>
    );
};

export default PlaylistViewLoader;