import TopBar from "@/components/navbar/TopBar"
import BottomBar from "@/components/sidebar/BottomBar"
import { useEffect } from "react"
import { FaTelegramPlane } from "react-icons/fa"
import { FaXTwitter } from "react-icons/fa6"
import { FaEnvelope } from "react-icons/fa"
import blueBird from "../../assets/blue bird.png";
import greenBird from "../../assets/greenbird.png";
import purpuleBird from "../../assets/purple-bird.png";
import contactIllustration from "../../assets/contactIllustration.svg"; // Assuming you have or will create this image

const Contact = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="relative min-h-screen bg-gradient-to-br from-slate-950 via-slate-900 to-slate-800 overflow-hidden flex flex-col flex-grow w-full" >
            <TopBar heading="Contact Us" />
            <div className="max-w-screen-lg mx-auto px-4 md:px-10 py-20 sm:pt-24 pt-40 grid md:grid-cols-2 gap-12 items-center" >
                <div>
                    <h1 className="font-potta text-4xl text-green-400" >Get in Touch</h1>
                    <p className="mt-4 text-slate-300" >We&apos;re excited to hear from our SoundOfMeme community! Whether you have feedback, a cool meme idea, or just want to say hello, we&apos;re all ears.</p>

                    <div className="mt-8 sm:space-y-4 space-y-12">
                        <div >

                            <h2 className="text-xl font-potta text-green-400 flex items-center sm:gap-2 gap-4" >
                                <FaEnvelope /> Email
                            </h2>
                            <p className="mt-1" >If you have any questions, please contact us at:</p>

                            <p className="mt-1" > <a href="mailto:contact@soundofmeme.com" className="text-green-400 hover:text-green-300" >contact@soundofmeme.com</a></p>

                        </div>

                        <div>
                            <h2 className="text-xl font-potta text-green-400 mb-2" >Connect With Us</h2>
                            <div className="flex space-x-4" >
                                <a
                                    href="https://t.me/SoundOfMeme"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-slate-300 hover:text-green-400 transition-colors"
                                >
                                    <FaTelegramPlane size={24} />
                                </a>
                                <a
                                    href="https://twitter.com/SoundOfMemeSol"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-slate-300 hover:text-green-400 transition-colors"
                                >
                                    <FaXTwitter size={24} />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="hidden md:block">
                    <img
                        src={contactIllustration}
                        alt="Contact Illustration"
                        className="w-full max-w-md mx-auto rounded-lg shadow-lg"
                    />
                </div>
            </div>

            <BottomBar />
            <img src={blueBird} alt='bird' className='w-16 absolute top-[60%] sm:top-[80%] right-[40%] sm:right-[10%] animate-pulse ' />
            <img src={greenBird} alt='bird' className='w-16 absolute left-[5%] top-[10%] sm:top-[85%] lg:top-1/2 animate-bounce' />
            <img src={purpuleBird} alt='bird' className='w-16 absolute right-[20%] top-[35%] sm:top-[10%] animate-bounce' />
            <img src={purpuleBird} alt='bird' className='w-16 absolute sm:right-[40%] sm:top-[60%] top-[80%] right-[10%] animate-pulse' />
        </div>
    )
}
export default Contact