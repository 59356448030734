import { useEffect, useRef, useState } from "react";

const Transcripts = ({ transcript, currentTime }) => {
    const transcriptRef = useRef(null);
    const containerRef = useRef(null);
    const [isUserScrolling, setIsUserScrolling] = useState(false);
    const scrollTimeoutRef = useRef(null);

    const formatTranscript = () => {
        return transcript?.map((line, index) => {
            const isActive =
                currentTime >= line.start &&
                (index === transcript.length - 1 || currentTime < transcript[index + 1]?.start);

            return (
                <p
                    key={index}
                    ref={isActive ? (el) => (transcriptRef.current = el) : null}
                    className={`mb-2 text-slate-400 transition-colors duration-300 ${isActive ? "text-teal-300 font-medium text-xl md:text-2xl" : ""
                        }`}
                >
                    {line.text}
                </p>
            );
        });
    };

    useEffect(() => {
        const container = containerRef.current;
        if (!container) return;

        const handleScroll = () => {
            // Clear any existing timeout
            if (scrollTimeoutRef.current) {
                clearTimeout(scrollTimeoutRef.current);
            }

            // Set user scrolling to true
            setIsUserScrolling(true);

            // Set a timeout to reset user scrolling
            scrollTimeoutRef.current = setTimeout(() => {
                setIsUserScrolling(false);
            }, 2000);
        };

        container.addEventListener("scroll", handleScroll);

        return () => {
            container.removeEventListener("scroll", handleScroll);
            if (scrollTimeoutRef.current) {
                clearTimeout(scrollTimeoutRef.current);
            }
        };
    }, []);

    useEffect(() => {
        if (transcript && containerRef.current && transcriptRef.current && !isUserScrolling) {
            const container = containerRef.current;
            const activeElement = transcriptRef.current;

            if (activeElement) {
                // Get the container's bounding rectangle relative to the viewport
                const containerRect = container.getBoundingClientRect();
                // Get the active element's bounding rectangle relative to the viewport
                const elementRect = activeElement.getBoundingClientRect();
                // Calculate the relative position within the container
                const relativePosition = elementRect.top - containerRect.top;
                // Calculate the scroll position to center the active element
                const scrollPosition = container.scrollTop + relativePosition - (containerRect.height / 2) + (elementRect.height / 2);

                container.scrollTo({
                    top: scrollPosition,
                    behavior: "smooth"
                });
            }
        }
    }, [currentTime, transcript, isUserScrolling]);

    return (
        <div className="flex items-center justify-center w-full md:w-[300px] lg:w-[400px] border-y border-teal-400/20 py-6">
            <div
                ref={containerRef}
                className="flex flex-col text-center h-[300px] md:h-[500px] font-poppins overflow-y-auto w-full md:no-scrollbar mt-0 pb-16 md:pb-0 px-6 md:px-0"
            >
                {formatTranscript()}
            </div>
        </div>
    );
};

export default Transcripts;