import TopBar from '@/components/navbar/TopBar';
import BottomBar from '@/components/sidebar/BottomBar';
import PlaylistsComponent from './PlaylistComponent';
import { useSelector } from 'react-redux';
// import { useNavigate } from "react-router-dom";
import { logEvent } from 'firebase/analytics';
import { analytics } from '@/firebase';
import { useEffect, useState } from 'react';
// import { useLocation } from 'react-router-dom';
// import { SET_OTPCARD } from '@/redux/features/auth/authSlice';
// import { useForm } from 'react-hook-form';
// import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
// import { Card, CardContent, CardFooter } from '@/components/ui/card';
// import { Button } from '@/components/ui/button';
// import GoogleLoginButton from '@/components/googleLoginButton/GoogleLoginButton';
// import LoginForm from '../login/LoginForm';
// import OtpForm from '../login/OtpForm';
// import SignUpForm from '../login/SignUpForm';
// import blueBird from "../../assets/blue bird.png";
// import greenBird from "../../assets/greenbird.png";
// import purpuleBird from "../../assets/purple-bird.png";
// import { z } from 'zod';
// import { zodResolver } from '@hookform/resolvers/zod';
// import toast from 'react-hot-toast';
// import axios from 'axios';
// import {
//     Form,
//     FormControl,
//     FormDescription,
//     FormField,
//     FormItem,
//     FormLabel,
//     FormMessage,
// } from "@/components/ui/form";
// import {
//     InputOTP,
//     InputOTPGroup,
//     InputOTPSlot,
// } from "@/components/ui/input-otp";
// import { IoMdArrowRoundBack } from 'react-icons/io';
// import LoginForm from '../login/LoginForm';
// import SignUpForm from '../login/SignUpForm';
// import OtpForm from '../login/OtpForm';
import Login from '../login/Login';
 


const Playlist = () => {

    const { user } = useSelector(state => state.auth);
    // const navigate = useNavigate();
    const [showLoginForm, setShowLoginForm] = useState(false);


    useEffect(() => {
        const token = localStorage.getItem('token');
        setShowLoginForm(!user && !token);
        window.scrollTo(0, 0);
        logEvent(analytics, 'page_view', { page_title: 'Playlist Page' })
    }, [user]);


    return (
        <div className='relative min-h-screen bg-gradient-to-br from-slate-950 via-slate-900 to-slate-800 overflow-hidden flex flex-col w-full'>
            <TopBar heading={"Play Lists"} />
            {/* {user && (
                <div className='pt-24 px-4'>
                    <PlaylistsComponent />
                    <BottomBar />
                </div>
            )} */}


            {showLoginForm ? (
                <Login/> // No prop needed
            ) : (
                <div className='pt-24 px-4'>
                    <PlaylistsComponent />
                    <BottomBar />
                </div>
            )}
        </div>
    );
};






// const FormSchema = z.object({
//     otp: z.string().min(4, {
//         message: "Your one-time password must be 6 characters.",
//     }),
// })

// const OtpForm = ({ tabName }) => {

//     const navigate = useNavigate()
//     const dispatch = useDispatch()
//     const location = useLocation()
//     const [timeLeft, setTimeLeft] = useState(60);
//     const [isButtonDisabled, setIsButtonDisabled] = useState(true);

//     // otp form
//     const form = useForm({
//         resolver: zodResolver(FormSchema),
//         defaultValues: {
//             otp: "",
//         },
//     })
//     const { reset: resetOtp } = form;

//     // otp submit
//     const handleOtpSubmit = async (data) => {
//         await dispatch(verifyOtp(data)).unwrap()
//         await dispatch(getUserDetails()).unwrap()
//         // navigate("/");
//         const from = location.state?.from;
//         navigate(from);
//         resetOtp()
//     }

//     // resend OTP
//     const handleResend = async () => {
//         const otpToken = sessionStorage.getItem('otpToken')
//         try {
//             toast.loading("Resending OTP", { id: "token" })
//             await axios.post("/sendotp", {}, { headers: { Authorization: `Bearer ${otpToken}` } })
//             toast.success("OTP Sent", { id: "token" })
//             setTimeLeft(60)
//             setIsButtonDisabled(true)
//         } catch (error) {
//             console.log(error);
//             toast.error("Error Sending OTP", { id: "token" })
//         }
//     }

//     const handleBack = () => {
//         sessionStorage.removeItem('otpToken')
//         dispatch(SET_OTPCARD(false))
//     }

//     useEffect(() => {
//         if (timeLeft === 0) {
//             setIsButtonDisabled(false);
//             return;
//         }
//         const intervalId = setInterval(() => {
//             setTimeLeft(timeLeft => timeLeft - 1);
//         }, 1000);

//         return () => clearInterval(intervalId);

//     }, [timeLeft]);

//     return (
//         <TabsContent value={tabName}>
//             <Form {...form}>
//                 <form onSubmit={form.handleSubmit(handleOtpSubmit)} className="relative w-full bg-slate-800 rounded-lg p-4 flex gap-4 items-center justify-center flex-col">
//                     <button onClick={handleBack} className="absolute rounded-full p-1 top-4 left-4 bg-green-400 hover:bg-green-500 text-black " type="button"><IoMdArrowRoundBack size={24} /></button>
//                     <FormField
//                         control={form.control}
//                         name="otp"
//                         render={({ field }) => (
//                             <FormItem className="flex items-center justify-center flex-col mt-4" >
//                                 <FormLabel className="text-green-400" >One-Time Password</FormLabel>
//                                 <FormControl>
//                                     <InputOTP maxLength={4} {...field}>
//                                         <InputOTPGroup className="text-white" >
//                                             <InputOTPSlot className="border-slate-400 ring-green-400" index={0} />
//                                             <InputOTPSlot className="border-slate-400 ring-green-400" index={1} />
//                                             <InputOTPSlot className="border-slate-400 ring-green-400" index={2} />
//                                             <InputOTPSlot className="border-slate-400 ring-green-400" index={3} />
//                                         </InputOTPGroup>
//                                     </InputOTP>
//                                 </FormControl>
//                                 <FormDescription className="text-slate-300" >
//                                     Please enter the one-time password sent to your mail address.
//                                 </FormDescription>
//                                 <FormMessage />
//                             </FormItem>
//                         )}
//                     />
//                     <div className="flex items-center gap-2 w-full" >
//                         <Button className="w-full bg-green-400 hover:bg-green-500 text-black " type="submit">Submit</Button>
//                         <Button disabled={isButtonDisabled} onClick={handleResend} className="w-full bg-green-400 hover:bg-green-500 text-black " type="button">{isButtonDisabled ? `Resend in : ${timeLeft}` : "Resend OTP"}</Button>
//                     </div>
//                 </form>
//             </Form>
//         </TabsContent>
//     )
// }





// const SignUpForm = () => {

//     const dispatch = useDispatch()
//     const { handleSubmit, register, reset, formState: { errors }, watch } = useForm()
//     const password = watch('password');

//     // Signup
//     const handleSignup = async (data) => {
//         const { name, email, password, username } = data;
//         const formData = {
//             name,
//             username,
//             email,
//             password
//         }
//         await dispatch(signUpUser(formData)).unwrap()
//         reset()
//     }


//     return (
//         <TabsContent value="signup" className="mt-6" >
//             <Card className="p-0 bg-transparent border-0" >
//                 <form onSubmit={handleSubmit(handleSignup)}>
//                     <CardContent className="space-y-8 p-0">
//                         <div className="flex flex-col gap-3 relative">
//                             <input type='text'
//                                 placeholder="Name"
//                                 {...register('name', { required: "Name is required" })}
//                                 className='bg-slate-900 text-white p-2 rounded placeholder:text-sm placeholder:text-slate-500 border border-slate-700/75'
//                             />
//                             {errors.name && <p className=' absolute -bottom-5 right-1 text-xs text-green-400 font-medium  ' >{errors.name.message}</p>}
//                         </div>
//                         <div className="flex flex-col gap-3 relative">
//                             <input type='text'
//                                 placeholder="Username"
//                                 {...register('username', { required: "Username is required" })}
//                                 className='bg-slate-900 text-white p-2 rounded placeholder:text-sm placeholder:text-slate-500 border border-slate-700/75'
//                             />
//                             {errors.username && <p className=' absolute -bottom-5 right-1 text-xs text-green-400 font-medium  ' >{errors.username.message}</p>}
//                         </div>
//                         <div className="flex flex-col gap-3 relative">
//                             <input type='email'
//                                 placeholder="Email"
//                                 {...register('email', {
//                                     required: 'Email is required',
//                                     pattern: {
//                                         value: /^\S+@\S+\.\S+$/,
//                                         message: 'Invalid email format'
//                                     }
//                                 })}
//                                 className='bg-slate-900 text-white p-2 rounded placeholder:text-sm placeholder:text-slate-500 border border-slate-700/75'
//                             />
//                             {errors.email && <p className=' absolute -bottom-5 right-1 text-xs text-green-400 font-medium  ' >{errors.email.message}</p>}
//                         </div>
//                         <div className="flex flex-col gap-3 relative">
//                             <input type='password'
//                                 placeholder="Password"
//                                 autoComplete="off"
//                                 {...register('password', {
//                                     required: 'Password is required',
//                                     minLength: {
//                                         value: 8,
//                                         message: 'Password must have at least 8 characters'
//                                     },
//                                 })}
//                                 className='bg-slate-900 text-white p-2 rounded placeholder:text-sm placeholder:text-slate-500 border border-slate-700/75'
//                             />
//                             {errors.password && <p className=' absolute -bottom-5 right-1 text-xs text-green-400 font-medium  ' >{errors.password.message}</p>}
//                         </div>
//                         <div className="flex flex-col gap-3 relative">
//                             <input type='password'
//                                 placeholder="Confirm Password"
//                                 autoComplete="off"
//                                 {...register('confirmPassword', {
//                                     required: 'Confirm password is required',
//                                     validate: value =>
//                                         value === password || 'The passwords do not match'
//                                 })}
//                                 className='bg-slate-900 text-white p-2 rounded placeholder:text-sm placeholder:text-slate-500 border border-slate-700/75'
//                             />
//                             {errors.confirmPassword && <p className=' absolute -bottom-5 right-1 text-xs text-green-400 font-medium  ' >{errors.confirmPassword.message}</p>}
//                         </div>
//                     </CardContent>
//                     <CardFooter className="p-0 mt-8" >
//                         <Button type="submit" className="w-full bg-green-400 hover:bg-green-500 text-black " >SignUp</Button>
//                     </CardFooter>
//                 </form>
//                 <div className='h-px my-4 bg-slate-600' ></div>
//                 <div className='flex items-center justify-center' >
//                 <GoogleLoginButton defaultPath={location.state?.from} />
//                 </div>
//             </Card>
//         </TabsContent>
//     )
// }








// const LoginForm = () => {
//     const navigate = useNavigate();
//     const location = useLocation();
//     const dispatch = useDispatch();
//     const { handleSubmit, register, reset, formState: { errors } } = useForm();

//     const handleLogin = async (data) => {
//         const { email, password } = data;
//         const formData = { email, password };
        
//         await dispatch(loginUser(formData)).unwrap();
//         await dispatch(getUserDetails()).unwrap();
        
//         // Use location state to determine redirect
//         const from = location.pathname === '/login' ? '/' : location.state?.from;
//         console.log('from', from);
//         navigate(from);
//         reset();
//     }

//     return (

//         <TabsContent value="login" className="mt-6">
//             <Card className=" bg-transparent p-0 border-0 " >
//                 <form onSubmit={handleSubmit(handleLogin)} >
//                     <CardContent className="space-y-8  p-0">
//                         <div className="flex flex-col gap-3 relative">
//                             <input type='text'
//                                 {...register('email', {
//                                     required: 'Email or Username is required',
//                                 })}
//                                 placeholder="Email or Username"
//                                 className='bg-slate-900 text-white p-2 rounded placeholder:text-sm placeholder:text-slate-500 border-slate-700/75 border'
//                             />
//                             {errors.email && <p className=' absolute -bottom-5 right-1 text-xs text-green-400 font-medium  ' >{errors.email.message}</p>}
//                         </div>
//                         <div className="flex flex-col gap-3 relative">
//                             <input
//                                 type="password"
//                                 autoComplete="off"
//                                 placeholder="Password"
//                                 {...register('password', {
//                                     required: 'Password is required',
//                                     minLength: {
//                                         value: 8,
//                                         message: 'Password must have at least 8 characters'
//                                     }
//                                 })}
//                                 className='bg-slate-900 border border-slate-700/75 text-white p-2 placeholder:text-sm placeholder:text-slate-500 rounded'
//                             />
//                             {errors.password && <p className=' absolute -bottom-5 right-1 text-xs text-green-400 font-medium  ' >{errors.password.message}</p>}
//                         </div>
//                     </CardContent>
//                     <CardFooter className="p-0 mt-8" >
//                         <Button type="submit" className="w-full bg-green-400 hover:bg-green-500 text-black " >Login</Button>
//                     </CardFooter>
//                 </form>
//                 <div className='h-px my-4 bg-slate-600' ></div>
//                 <div className='flex items-center justify-center' >
//                     <GoogleLoginButton defaultPath={location.state?.from} />
//                 </div>
//             </Card>
//         </TabsContent>
//     )
//    // Rest of the component remains the same
// }



// const Login = () => {

//     const dispatch = useDispatch()
//     const { song } = useSelector(state => state.music)
//     const { otpCard } = useSelector(state => state.auth)

//     useEffect(() => {
//         const otpToken = sessionStorage.getItem('otpToken')

//         if (otpToken) {
//             dispatch(SET_OTPCARD(true))
//         }
//     }, [dispatch])

//     useEffect(() => {
//         window.scroll(0, 0)
//     }, [])

//     return (
//         <div className='relative min-h-screen bg-gradient-to-br from-slate-950 via-slate-900 to-slate-800 overflow-hidden flex flex-col flex-grow w-full' >
//             <TopBar heading={"Get Started"} />
//             <div className={'md:pt-20 pt-20 flex-grow flex items-center justify-center relative z-50 ' + (song ? " md:pb-32 pb-36 " : " pb-40 sm:pb-20 ")} >
//                 <Tabs defaultValue="login" className="w-full p-4 max-w-lg  ">
//                     <TabsList className="grid w-full grid-cols-2  text-slate-400 font-semibold bg-slate-900 border border-slate-700/75">
//                         <TabsTrigger className="data-[state=active]:bg-green-400" value="login">Login</TabsTrigger>
//                         <TabsTrigger className="data-[state=active]:bg-green-400 font-semibold" value="signup">SignUp</TabsTrigger>
//                     </TabsList>
//                     {!otpCard ? <LoginForm /> : <OtpForm  tabName={"login"} />}
//                     {!otpCard ? <SignUpForm /> : <OtpForm tabName={"signup"} />}
//                 </Tabs>
//             </div>
//             <BottomBar />
//             <img src={blueBird} alt='bird' className='w-16 absolute top-[80%] right-[10%]' />
//             <img src={greenBird} alt='bird' className='w-16 absolute left-[5%] top-[85%] lg:top-1/2' />
//             <img src={purpuleBird} alt='bird' className='w-16 absolute right-[20%] top-[10%]' />
//         </div>
//     )
// }



export default Playlist;