import { SongCardSkeleton } from '@/components/createSong/CreateSongLoading'
import TopBar from '@/components/navbar/TopBar'
import BottomBar from '@/components/sidebar/BottomBar'
import SongCard from '@/components/songCard/SongCard'
import useAuthCheck from '@/customHook/useAuthCheck'
import { ADD_SONGS, SET_USERSONGS } from '@/redux/features/music/musicSlice'
import axios from 'axios'
import { debounce } from 'lodash'
import { useEffect, useState } from 'react'
import { BsFillRocketFill, BsFillRocketTakeoffFill } from 'react-icons/bs'
import { GiRocketThruster } from 'react-icons/gi'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { PiWarningCircle } from "react-icons/pi";
import CancelSubscription from './CancelSubscription'

const Profile = () => {

    useAuthCheck("/login")
    const dispatch = useDispatch()
    const [term, setTerm] = useState('')
    const { song, userSongs } = useSelector(state => state.music);
    const { user } = useSelector(state => state.auth);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [noResultMsg, setNoResultMsg] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [searchSongs, setSearchSongs] = useState([])

    useEffect(() => {
        const fetchSongs = async () => {
            const token = localStorage.getItem('token');
            try {
                if (page === 1) {
                    dispatch(SET_USERSONGS([]))
                }
                const response = await axios.get(`/usersongs?page=${page}`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                // setSongs(prevSongs => [...prevSongs, ...response.data.songs]);
                dispatch(ADD_SONGS(response.data.songs))
                if (response.data.songs.length === 0 || response.data.songs.length < 20) {
                    setHasMore(false); // No more songs to load
                }
            } catch (error) {
                console.log('Error fetching songs:', error);
            }
        };
        if (user) {
            fetchSongs();
        }
    }, [page, user, dispatch]);

    // Define the debounced function directly inside useEffect
    useEffect(() => {
        const token = window.localStorage.getItem('token')
        const debouncedSearch = debounce(async () => {
            if (!term) return;
            try {
                setIsLoading(true)
                const response = await axios.get(`/searchusersongs?item=${term}`, { headers: { Authorization: `Bearer ${token}` } });
                setSearchSongs(response.data.songs)
                setIsLoading(false)
                if (response?.data?.songs?.length === 0) {
                    setNoResultMsg(true)
                } else {
                    setNoResultMsg(false)
                }
            } catch (error) {
                setIsLoading(false)
                console.error('Search failed:', error);
            }
        }, 300);
        debouncedSearch();
        return () => {
            debouncedSearch.cancel();
        };
    }, [term]);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='relative min-h-screen bg-slate-950 text-slate-300 overflow-hidden flex flex-col flex-grow w-full' >
            <TopBar heading={"Profile"} />
            <div className={' pt-20 px-4 ' + (song ? " pb-36 md:pb-28 " : " pb-24 md:pb-8 ")} >
                <div className='grid lg:grid-cols-2 xl:grid-cols-3 gap-8 ' >
                    <div className='border border-slate-800 rounded-lg max-w-xl w-full flex items-center p-4 gap-4' >
                        <div className=" text-5xl text-black font-bold aspect-square bg-green-400 w-24 flex items-center justify-center rounded-full overflow-hidden " >{user?.profile_url ? <img className="w-full object-cover" src={user?.profile_url} alt="profile" /> : user?.name?.slice(0, 2)}</div>
                        <div className=''>
                            <p className='text-2xl sm:text-3xl font-semibold text-slate-100'>{user?.name}</p>
                            <p className=' text-sm sm:text-base text-slate-400 font-medium '>{user?.email}</p>
                        </div>
                    </div>
                    <div className={`border rounded-lg max-w-xl w-full flex items-center p-4 gap-4 ${user?.plan === 1 ? " border-green-600/75 " : user?.plan === 2 ? " border-amber-600/75 " : user?.plan === 3 ? " border-slate-100/75 " : " border-slate-800 "} `} >
                        <div className='rounded-full overflow-hidden p-4' >{user?.plan === 1 ? <BsFillRocketFill size={50} className='text-green-400' /> : user?.plan === 2 ? <BsFillRocketTakeoffFill size={50} className='text-amber-300' /> : user?.plan === 3 ? <GiRocketThruster size={60} className='text-slate-100 ' /> : <PiWarningCircle size={50} className='text-slate-300' />}</div>
                        <div className=''>
                            <p className={`text-2xl sm:text-3xl font-bold ${user?.plan === 1 ? " text-green-400 " : user?.plan === 2 ? " text-amber-300 " : " text-slate-100 "} `}>{user?.plan === 1 ? "Premium" : user?.plan === 2 ? "Gold" : user?.plan === 3 ? "Platinum" : "No Active Plan"}</p>
                            <p className='text-sm text-slate-400 font-medium mt-1 '>Credits Remaining : {user?.credits}</p>
                            <div className='flex gap-3 mt-1' >
                                {user?.plan < 3 && <Link to={"/premium"} className='text-xs font-medium text-teal-400 hover:underline ' >Upgrade Plan</Link>}
                                {user?.plan > 0 && <CancelSubscription />}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='mt-8 flex flex-col flex-grow overflow-hidden' >
                    <div className=' pb-2 flex justify-between flex-col-reverse lg:flex-row gap-4' >
                        <input disabled={userSongs?.length === 0} type='text' placeholder='Search my songs' value={term}
                            onChange={(e) => setTerm(e.target.value)}
                            className=' disabled:opacity-50 flex-grow bg-transparent border border-slate-700 p-2 rounded-md lg:max-w-lg w-full focus:outline-none'
                        />
                    </div>
                    {(!isLoading && term === "") ?
                        <InfiniteScroll
                            dataLength={userSongs?.length}
                            next={() => setPage(prevPage => prevPage + 1)}
                            hasMore={hasMore}
                            loader={<Loader />}
                            endMessage={null}
                            className={' mt-8 flex-grow grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-x-4 gap-y-8 '}
                        >
                            {userSongs?.map((song) => (
                                <SongCard song={song} key={song?.song_id} playingFrom={"discoverSongs"} />
                            ))}
                        </InfiniteScroll> :
                        (!isLoading && !noResultMsg && searchSongs?.length > 0) ?
                            <div className={' mt-8 flex-grow grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-x-4 gap-y-8 '}>
                                {searchSongs?.map((song) => (
                                    <SongCard song={song} key={song?.song_id} playingFrom={"discoverSongs"} />
                                ))}
                            </div> :
                            (!isLoading && searchSongs.length === 0 && noResultMsg) ?
                                <p className='px-4' >No songs found. Try different search terms.</p> :
                                <div className={'mt-8 flex-grow grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-x-4 gap-y-8'} >
                                    <Loader />
                                </div>
                    }
                </div>
            </div>
            <BottomBar />
        </div>
    )
}

export default Profile

function Loader() {
    return (
        <>
            <SongCardSkeleton />
            <SongCardSkeleton />
            <SongCardSkeleton />
            <SongCardSkeleton />
            <SongCardSkeleton />
            <SongCardSkeleton />
            <SongCardSkeleton />
            <SongCardSkeleton />
            <SongCardSkeleton />
            <SongCardSkeleton />
            <SongCardSkeleton />
            <SongCardSkeleton />
        </>
    )
}