import { configureStore } from "@reduxjs/toolkit";
import musicReducer from "./features/music/musicSlice";
import authReducer from "./features/auth/authSlice";
import btnReducer from "./features/presale/preslaeSlice";
import searchReducer from "./features/music/searchSlice";
import createSongReducer from "./features/music/createSlice";
import playlistReducer from "./features/music/playlistSlice";

const store = configureStore({
    reducer: {
        music: musicReducer,
        auth: authReducer,
        presale: btnReducer,
        search: searchReducer,
        create: createSongReducer,
        playlists : playlistReducer,
    }
});

export default store;