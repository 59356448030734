import { Button } from "@/components/ui/button"
import {
    Card,
    CardContent,
    CardFooter,
} from "@/components/ui/card"
import {
    TabsContent,
} from "@/components/ui/tabs"
import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import { signUpUser } from "@/redux/features/auth/authSlice"
import GoogleLoginButton from "@/components/googleLoginButton/GoogleLoginButton"
import { useLocation } from "react-router-dom"



const SignUpForm = () => {

    const dispatch = useDispatch()
    const location = useLocation()
    const { handleSubmit, register, reset, formState: { errors }, watch } = useForm()
    const password = watch('password');

    // Signup
    const handleSignup = async (data) => {
        const { name, email, password, username } = data;
        const formData = {
            name,
            username,
            email,
            password
        }
        await dispatch(signUpUser(formData)).unwrap()
        reset()
    }


    return (
        <TabsContent value="signup" className="mt-6" >
            <Card className="p-0 bg-transparent border-0" >
                <form onSubmit={handleSubmit(handleSignup)}>
                    <CardContent className="space-y-8 p-0">
                        <div className="flex flex-col gap-3 relative">
                            <input type='text'
                                placeholder="Name"
                                {...register('name', { required: "Name is required" })}
                                className='bg-slate-900 text-white p-2 rounded placeholder:text-sm placeholder:text-slate-500 border border-slate-700/75'
                            />
                            {errors.name && <p className=' absolute -bottom-5 right-1 text-xs text-green-400 font-medium  ' >{errors.name.message}</p>}
                        </div>
                        <div className="flex flex-col gap-3 relative">
                            <input type='text'
                                placeholder="Username"
                                {...register('username', { required: "Username is required" })}
                                className='bg-slate-900 text-white p-2 rounded placeholder:text-sm placeholder:text-slate-500 border border-slate-700/75'
                            />
                            {errors.username && <p className=' absolute -bottom-5 right-1 text-xs text-green-400 font-medium  ' >{errors.username.message}</p>}
                        </div>
                        <div className="flex flex-col gap-3 relative">
                            <input type='email'
                                placeholder="Email"
                                {...register('email', {
                                    required: 'Email is required',
                                    pattern: {
                                        value: /^\S+@\S+\.\S+$/,
                                        message: 'Invalid email format'
                                    }
                                })}
                                className='bg-slate-900 text-white p-2 rounded placeholder:text-sm placeholder:text-slate-500 border border-slate-700/75'
                            />
                            {errors.email && <p className=' absolute -bottom-5 right-1 text-xs text-green-400 font-medium  ' >{errors.email.message}</p>}
                        </div>
                        <div className="flex flex-col gap-3 relative">
                            <input type='password'
                                placeholder="Password"
                                autoComplete="off"
                                {...register('password', {
                                    required: 'Password is required',
                                    minLength: {
                                        value: 8,
                                        message: 'Password must have at least 8 characters'
                                    },
                                })}
                                className='bg-slate-900 text-white p-2 rounded placeholder:text-sm placeholder:text-slate-500 border border-slate-700/75'
                            />
                            {errors.password && <p className=' absolute -bottom-5 right-1 text-xs text-green-400 font-medium  ' >{errors.password.message}</p>}
                        </div>
                        <div className="flex flex-col gap-3 relative">
                            <input type='password'
                                placeholder="Confirm Password"
                                autoComplete="off"
                                {...register('confirmPassword', {
                                    required: 'Confirm password is required',
                                    validate: value =>
                                        value === password || 'The passwords do not match'
                                })}
                                className='bg-slate-900 text-white p-2 rounded placeholder:text-sm placeholder:text-slate-500 border border-slate-700/75'
                            />
                            {errors.confirmPassword && <p className=' absolute -bottom-5 right-1 text-xs text-green-400 font-medium  ' >{errors.confirmPassword.message}</p>}
                        </div>
                    </CardContent>
                    <CardFooter className="p-0 mt-8" >
                        <Button type="submit" className="w-full bg-green-400 hover:bg-green-500 text-black " >SignUp</Button>
                    </CardFooter>
                </form>
                <div className='h-px my-4 bg-slate-600' ></div>
                <div className='flex items-center justify-center' >
                <GoogleLoginButton defaultPath={location.pathname === '/login' ? '/' : location.state?.from} />
                </div>
            </Card>
        </TabsContent>
    )
}

export default SignUpForm