import { useCallback, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import axios from "axios";
import { debounce } from "lodash";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Search } from "lucide-react";
import { addSong, APPEND_SONG_TO_PLAYLIST } from "@/redux/features/music/playlistSlice";
import { toast } from "react-hot-toast";
import InfiniteScroll from "react-infinite-scroll-component";
import { PiMusicNotesPlus } from "react-icons/pi";
import AddSongDialogSkeleton from "./AddSongDialogSkeleton";

const AddSongDialog = ({ isOpen, onClose, songs, fetchMoreSongs }) => {
  const dispatch = useDispatch();
  const { playlistId } = useParams();
  const [searchTerm, setSearchTerm] = useState("");
  const [isAdding, setIsAdding] = useState({});
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [noResultMsg, setNoResultMsg] = useState(false);
  const [searchPage, setSearchPage] = useState(1);
  const [hasMoreSearch, setHasMoreSearch] = useState(true);
  const [playlistSongIds, setPlaylistSongIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  // const [lastSearchTimestamp, setLastSearchTimestamp] = useState(0);


  useEffect(() => {
    if (isOpen) {
      setCurrentPage(1);
      setHasMore(true);
    }
  }, [isOpen]);

  useEffect(() => {
    const fetchPlaylistSongs = async () => {
      if (isOpen && playlistId) {
        setIsLoading(true);
        try {
          const token = window.localStorage.getItem("token");
          const response = await axios.get(
            `/getplaylistsongs`,
            {
              params: { playlist_slug: playlistId },  
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          console.log('Full API response:', response);
          console.log('Full API response:', response.data);

          setPlaylistSongIds(response.data);
          setHasMore(true);
        } catch (error) {
          console.error("Failed to fetch playlist songs:", error);
          toast.error("Failed to load playlist songs");
          setHasMore(false);
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchPlaylistSongs();
  }, [isOpen, playlistId]);

  const filteredSongs = songs.filter((song) => !playlistSongIds.includes(song.song_id));

  useEffect(() => {
    if (!isOpen) {
      setSearchTerm("");
      setSearchResults([]);
      setSearchPage(1);
      setCurrentPage(1);
      setHasMoreSearch(true);
      setNoResultMsg(false);
    }
  }, [isOpen]);


  useEffect(() => {
    const token = window.localStorage.getItem("token");
    const debouncedSearch = debounce(async () => {
      if (!searchTerm) {
        setSearchResults([]);
        setNoResultMsg(false);
        setHasMoreSearch(true);
        setSearchPage(1);
        return;
      }
      
      try {
        setIsSearching(true);
        const response = await axios.get(
          `/songsmenus?search=${searchTerm}&page=${searchPage}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        const filteredSongs = response.data.songs.filter(
          (song) => !playlistSongIds.includes(song.song_id)
        );
        setSearchResults(prev => searchPage === 1 ? filteredSongs : [...prev, ...filteredSongs]);

        setHasMoreSearch(filteredSongs.length === 20);
        setNoResultMsg(searchPage === 1 && filteredSongs.length === 0);
      } catch (error) {
        console.error("Search failed:", error);
        toast.error("Failed to search songs");
      } finally {
        setIsSearching(false);
      }
    }, 300);

    debouncedSearch();
    return () => debouncedSearch.cancel();
  }, [searchTerm, playlistSongIds, searchPage]);


  const loadMoreSearchResults = async () => {
    if (!hasMoreSearch || !searchTerm) return;
    const nextPage = searchPage + 1;
    // setLastSearchTimestamp(Date.now());
    setSearchPage(nextPage);
  };

  const handleLoadMoreSongs = useCallback(() => {
    const nextPage = currentPage + 1;
    fetchMoreSongs(nextPage);
    setCurrentPage(nextPage);
  }, [ fetchMoreSongs, currentPage]);

  // const handleAddSong = useCallback(
  //   async (song) => {
  //     if (isAdding[song.song_id]) return;

  //     setIsAdding((prev) => ({ ...prev, [song.song_id]: true }));
  //     try {
  //       const response = await dispatch(
  //         addSong({
  //           songId: song.song_id,
  //           playlistId,
  //         })
  //       ).unwrap();

  //       // Check song addition status
  //       if (response.status === 'Song already in the playlist') {
  //         toast.error(response.status);
  //       } else {
  //         dispatch(APPEND_SONG_TO_PLAYLIST(song));
  //         toast.success(`Added "${song.song_name}" to playlist`);
  //       }

  //       setPlaylistSongIds((prev) => [...prev, song.song_id]);
        
  //       // Remove song from search and default results
  //       if (searchTerm) {
  //         setSearchResults((prev) =>
  //           prev.filter((s) => s.song_id !== song.song_id)
  //         );
  //       }

  //       // Update timestamp to prevent search trigger on song addition 
  //       // setLastSearchTimestamp(Date.now());
  //     } catch (error) {
  //       toast.error(`Failed to add song: ${error}`);
  //     } finally {
  //       setIsAdding((prev) => ({ ...prev, [song.song_id]: false }));
  //     }
  //   },
  //   [dispatch, playlistId, isAdding, searchTerm]
  // );




  const handleAddSong = useCallback(
    async (song) => {
      if (isAdding[song.song_id]) return;
  
      setIsAdding((prev) => ({ ...prev, [song.song_id]: true }));
      try {
        const response = await dispatch(
          addSong({
            songId: song.song_id,
            playlistId,
          })
        ).unwrap();
  
        // Check song addition status
        if (response.status === 'Song already in the playlist') {
          toast.error(response.status);
        } else {
          dispatch(APPEND_SONG_TO_PLAYLIST(song));
          toast.success(`Added "${song.song_name}" to playlist`);
        }
  
        // Update playlist song IDs
        setPlaylistSongIds((prev) => [...prev, song.song_id]);
        
        // Remove song from search and default results
        if (searchTerm) {
          setSearchResults((prev) =>
            prev.filter((s) => s.song_id !== song.song_id)
          );
        }
  
        // If the filtered songs are now less than a certain threshold, fetch more
        const remainingFilteredSongs = filteredSongs.filter(s => s.song_id !== song.song_id);
        if (remainingFilteredSongs.length <= 20) {
          // Fetch the next page of songs
          handleLoadMoreSongs();
        }
      } catch (error) {
        toast.error(`Failed to add song: ${error}`);
      } finally {
        setIsAdding((prev) => ({ ...prev, [song.song_id]: false }));
      }
    },
    [dispatch, playlistId, isAdding, searchTerm, filteredSongs, handleLoadMoreSongs]
  );



  



  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-2xl max-h-[80vh] overflow-hidden bg-slate-900 border-green-400 text-slate-300">
        <DialogHeader>
          <DialogTitle className="text-xl font-semibold mb-4">
            Add Songs to Playlist
          </DialogTitle>
          <DialogDescription className="text-slate-400">
            Search and add songs to your playlist
          </DialogDescription>
        </DialogHeader>

        <div className="relative mb-4">
          <Input
            type="text"
            placeholder="Search songs..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full pl-10 bg-slate-800 border-green-400"
          />
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-slate-400" size={20} />
        </div>

        <div id="scrollableDiv" className="overflow-y-auto max-h-[60vh] pb-12">
          {isLoading ? (
            <div><AddSongDialogSkeleton /></div>
          ) : searchTerm === "" ? (
            <InfiniteScroll
              dataLength={songs.length}
              next={handleLoadMoreSongs}
              hasMore={hasMore}
              loader={<AddSongDialogSkeleton />}
              scrollableTarget="scrollableDiv"
            >
              {filteredSongs.map((song, i) => (
                <SongItem
                  key={`${song.song_id}-${i}`}
                  song={song}
                  isAdding={isAdding}
                  handleAddSong={handleAddSong}
                />
              ))}
            </InfiniteScroll>
          ) : (
            <>
              {isSearching && searchPage === 1 ? (
                <div><AddSongDialogSkeleton /></div>
              ) : (
                <InfiniteScroll
                  dataLength={searchResults.length}
                  next={loadMoreSearchResults}
                  hasMore={hasMoreSearch}
                  loader={<AddSongDialogSkeleton />}
                  scrollableTarget="scrollableDiv"
                >
                  {searchResults.map((song, index) => (
                    <SongItem
                      key={`${song.song_id}-${index}`}
                      song={song}
                      isAdding={isAdding}
                      handleAddSong={handleAddSong}
                    />
                  ))}
                </InfiniteScroll>
              )}

              {noResultMsg && searchResults.length === 0 && !isSearching && (
                <div className="flex justify-center p-4">
                  <p className="text-slate-400">
                    No songs found for your search.
                  </p>
                </div>
              )}
            </>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

const SongItem = ({ song, isAdding, handleAddSong }) => (
  <div className="flex items-center justify-between p-4 hover:bg-slate-800 rounded-lg mb-2">
    <div className="flex items-center space-x-4">
      <img
        src={song.image_url || "/api/placeholder/48/48"}
        alt={song.song_name}
        className="w-12 h-12 rounded object-cover"
      />
      <div>
        <h4 className="font-medium text-white">{song.song_name}</h4>
        <p className="text-sm text-slate-400">
          {song.username || "Unknown Artist"}
        </p>
      </div>
    </div>
    <Button
      onClick={() => handleAddSong(song)}
      disabled={isAdding[song.song_id]}
      variant="secondary"
      className="flex items-center gap-1 bg-green-400 hover:bg-green-300 min-w-[100px]"
    >
      {isAdding[song.song_id] ? (
        <span className="flex items-center gap-2">
          <span className="h-4 w-4 border-2 border-slate-400 border-t-transparent rounded-full animate-spin"></span>
          Adding...
        </span>
      ) : (
        <>
          <PiMusicNotesPlus className="w-5 h-5" />
          Add
        </>
      )}
    </Button>
  </div>
);

export default AddSongDialog;