import { useState, useCallback } from 'react';
import { toast } from 'react-hot-toast';
import axios from 'axios';
import { Dialog, DialogClose, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import PlaylistSelector from './PlaylistSelector';
import { RiPlayListAddFill } from 'react-icons/ri';

const AddToPlaylistDialog = ({
    isOpen,
    onOpenChange,
    song,
    onSuccessfulAdd
}) => {
    const [selectedPlaylist, setSelectedPlaylist] = useState(null);
    const [isNewPlaylistDialogOpen, setIsNewPlaylistDialogOpen] = useState(false);
    const [newPlaylistName, setNewPlaylistName] = useState('');
    const [publish, setPublish] = useState(false);
    const [isAdding, setIsAdding] = useState(false);

    const handleAddSongToPlaylist = useCallback(async () => {
        if (!selectedPlaylist || !song?.song_id) {
            toast.error('Please select a playlist and song');
            return;
        }
        try {
            const response = await axios.post('/playlist/add', {
                song_id: song.song_id,
                playlist_slug: selectedPlaylist.playlist_slug
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (response.data.status === 'Song already in the playlist') {
                toast.error(response.data.status);
            } else {
                toast.success('Song added to playlist successfully!');
                onSuccessfulAdd && onSuccessfulAdd();
            }
            onOpenChange(false);
            setSelectedPlaylist(null);
        } catch (error) {
            console.error('Error adding song to playlist:', error);
            toast.error(error.response?.data?.message || 'Failed to add song to playlist');
        }
    }, [selectedPlaylist, song, onOpenChange, onSuccessfulAdd]);

    const handleAddPlaylist = useCallback(async () => {
        if (newPlaylistName.trim() === '') {
            toast.error("Playlist name cannot be empty");
            return;
        }
        try {
            setIsAdding(true);

            await axios.post('/playlist/create', {
                name: newPlaylistName,
                publish
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            setIsAdding(false);

            setIsNewPlaylistDialogOpen(false);
            // Automatically open the main playlist dialog after creating a new playlist
            setIsNewPlaylistDialogOpen(false);
            setNewPlaylistName('');
            onOpenChange(true);
        } catch (error) {
            toast.error(`Failed to create playlist: ${error}`);
        } finally {
            setIsAdding(false);
        }
    }, [newPlaylistName, publish, onOpenChange]);

    return (
        <>
            <Dialog open={isOpen} onOpenChange={onOpenChange}>
                <DialogContent className="w-full max-w-md sm:max-w-lg p-4 bg-gradient-to-r from-slate-950 via-slate-900 to-slate-950 text-slate-200 border border-green-400">
                    <DialogHeader className="space-y-2">
                        <div className='flex flex-col items-start gap-2'>
                            <div className="flex items-center justify-between w-full">
                                <DialogTitle className="text-lg font-semibold text-green-400 font-potta">
                                    Add Song to Playlist
                                </DialogTitle>
                                <button
                                    onClick={() => {
                                        onOpenChange(false);
                                        setIsNewPlaylistDialogOpen(true);
                                    }}
                                    className="flex items-center justify-center text-green-400 hover:text-green-300"
                                >
                                    <RiPlayListAddFill className="text-2xl" />
                                </button>
                            </div>
                            <DialogDescription className="text-sm text-slate-300 font-poppins">
                                Confirm adding song to playlist
                            </DialogDescription>
                        </div>
                    </DialogHeader>

                    <div className="relative flex items-center gap-4 bg-slate-900 p-3 rounded-lg shadow-md shadow-slate-800">
                    <img
                            src={song?.image_url}
                            alt="Song Cover"
                            className="w-12 h-12 rounded-lg flex-shrink-0"
                        />
                        <div className="truncate flex flex-col items-start">
                            <p className="font-semibold text-slate-100 truncate w-full">{song?.song_name}</p>
                            <p className="text-sm text-slate-400 truncate w-full">
                                Playlist: {selectedPlaylist?.name || 'Not selected'}
                            </p>
                        </div>
                    </div>

                    <DialogFooter className="mt-4">
                        <div className="flex flex-col sm:flex-row gap-4 w-full">
                            <div className="w-full sm:w-68 border border-green-400 rounded-md">
                                <PlaylistSelector
                                    song={song}
                                    onSelectPlaylist={(selectedPlaylist) => {
                                        setSelectedPlaylist(selectedPlaylist);
                                    }}
                                    isDisabled={false}
                                />
                            </div>
                            <div className="flex flex-row justify-end gap-2 ">
                                <Button
                                    onClick={handleAddSongToPlaylist}
                                    className="w-full sm:w-auto bg-green-400 hover:bg-green-300 text-slate-900"
                                >
                                    Add
                                </Button>

                                <DialogClose asChild>
                                    <Button
                                        variant="secondary"
                                        className="w-full sm:w-auto bg-slate-700 hover:bg-slate-800 text-slate-300"
                                    >
                                        Cancel
                                    </Button>
                                </DialogClose>
                            </div>
                        </div>
                    </DialogFooter>
                </DialogContent>
            </Dialog>

            {/* New Playlist Dialog */}
            <Dialog open={isNewPlaylistDialogOpen} onOpenChange={setIsNewPlaylistDialogOpen}>
                <DialogContent className="bg-gradient-to-r from-slate-950 via-slate-900 to-slate-950 text-slate-300 border-green-400 outline-1">
                    <DialogHeader className="flex flex-col items-start">
                        <DialogTitle>Create New Playlist</DialogTitle>
                        <DialogDescription className="text-slate-400 text-xs sm:text-md">
                            Fill in the details for your new playlist
                        </DialogDescription>
                    </DialogHeader>
                    <div className="space-y-4">
                        <input
                            type="text"
                            placeholder="Playlist Name..."
                            value={newPlaylistName}
                            onChange={(e) => setNewPlaylistName(e.target.value)}
                            className="w-full px-2 py-3 rounded bg-slate-800 text-sm text-slate-200 placeholder-slate-400 focus:outline-none focus:ring-1 focus:ring-green-400 focus:border-transparent"
                        />
                        <label className="switch flex items-center justify-between border-b pb-2 border-slate-700 cursor-pointer">
                            <p className="font-semibold text-sm">Publish</p>
                            <input
                                checked={publish}
                                onChange={(e) => setPublish(e.target.checked)}
                                type="checkbox"
                            />
                            <span className="slider"></span>
                        </label>
                    </div>
                    <DialogFooter>
                       <div className='flex flex-row justify-end gap-2'>
                       <Button
                            onClick={handleAddPlaylist}
                            disabled={isAdding}
                            className="bg-green-400 px-8 hover:bg-green-300 text-slate-800"
                        >
                            {isAdding ? 'Creating...' : 'Save'}
                        </Button>
                        <DialogClose asChild>
                            <Button className="bg-slate-700 px-6 hover:bg-slate-800 text-slate-300">
                                Cancel
                            </Button>
                        </DialogClose>
                       </div>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default AddToPlaylistDialog;