import TopBar from '@/components/navbar/TopBar'
import BottomBar from '@/components/sidebar/BottomBar'
import { Dialog, DialogContent, DialogDescription, DialogOverlay, DialogTitle, DialogTrigger } from '@/components/ui/dialog'
import { analytics } from '@/firebase'
import axios from 'axios'
import { logEvent } from 'firebase/analytics'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { BsFillRocketFill, BsFillRocketTakeoffFill } from 'react-icons/bs'
import { FaCheck } from 'react-icons/fa6'
import { GiRocketThruster } from 'react-icons/gi'
import { IoCheckmarkDoneOutline } from 'react-icons/io5'
import { useSelector } from 'react-redux'

const Subscription = () => {

    const [loading, setLoading] = useState(false)
    const [subLoading, setSubLoading] = useState(false)
    const { user } = useSelector(state => state.auth)
    const [selectedPlan, setSelectedPlan] = useState(null)
    const [coupon, setCoupon] = useState('')
    const [price, setPrice] = useState(0)
    const [applied, setApplied] = useState(false)

    useEffect(() => {
        window.scrollTo(0, 0)
        logEvent(analytics, 'page_view', { page_title: 'Subscription Page' })
    }, [])

    const buyPremium = async (plan) => {
        if (!user) {
            return toast.error("Please login to continue", { id: "premium" })
        }

        const token = window.localStorage.getItem('token')
        try {
            setSubLoading(true)
            toast.loading("Opening Payment Page", { id: "premium" })

            const response = await axios.post("/checkout",
                {
                    catagory: plan,
                    coupon: applied ? coupon : ''
                },
                { headers: { Authorization: `Bearer ${token}` } }
            )
            logEvent(analytics, 'begin_checkout', {
                currency: 'USD',
                plan: `plan_${plan}`,
                plan_name: plan === 1 ? 'Premium' : plan === 2 ? 'Gold' : 'Platinum',
                price: price,
                coupon: applied ? coupon : 'none'
            });
            window.location.href = response.data.url
            setSubLoading(false)
        } catch (error) {
            setSubLoading(false)
            toast.error(error?.response?.data?.detail || error.message || "Oops! Error Occured", { id: "premium" });
            console.log(error);
        }
    }

    const verifyCoupon = async () => {

        if (!coupon || coupon === "" || coupon.length === 0) return toast.error("Please enter coupon value", { id: "coupon" })

        const token = window.localStorage.getItem('token')
        try {
            setLoading(true)
            const { data } = await axios.post('/checkcoupon', { coupon, catagory: selectedPlan }, { headers: { Authorization: `Bearer ${token}` } })
            const discountPrecentage = data?.percentage
            const discountedPrice = discountPrecentage - (discountPrecentage * discountPrecentage / 100)
            setPrice(discountedPrice)
            setApplied(true)
            console.log(data);
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error);
            toast.error(error?.response?.data?.detail || error.message || "Oops! Error Occured", { id: "premium" });
        }
    }


    return (
        <div className='relative min-h-screen bg-slate-950 overflow-hidden flex flex-col flex-grow w-full' >
            <TopBar heading={"Buy Premium"} />
            <div className='pt-20 px-4 pb-32' >
                <h1 className='text-4xl font-bold text-slate-100 text-center' >Choose Your Plan</h1>
                <p className='text-center mt-2' >Select the perfect plan that fits your needs. Enjoy exclusive benefits and start your journey today!</p>
                <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 justify-items-center gap-8 2xl:gap-16 mt-8 sm:mt-16 w-full xl:max-w-screen-lg mx-auto' >
                    <Dialog>
                        <div className='rounded-2xl flex flex-col max-w-xs w-full border border-green-600/75 hover:shadow-xl hover:shadow-green-700/50 p-4 bg-green-300/10' >
                            <h3 className='text-4xl font-bold uppercase text-green-400 flex items-center gap-2' >
                                <BsFillRocketFill size={24} className='mt-1' />
                                Premium
                            </h3>
                            <div className='flex items-center my-8' >
                                <p className='text-7xl font-bold text-green-400 ' >$14<span className='text-base font-medium text-slate-200' >/month</span> </p>
                            </div>
                            <div className=' flex-grow flex flex-col  gap-2 mb-4' >
                                <Benifit text={"5,000 Credits"} />
                                <Benifit text={"2 simultaneous song generations"} />
                            </div>
                            <DialogTrigger asChild>
                                <button onClick={() => {
                                    setApplied(false)
                                    setCoupon("")
                                    setPrice(14)
                                    setSelectedPlan(1)
                                }}
                                    disabled={user?.plan >= 1} className='disabled:opacity-50 bg-green-400 hover:bg-green-500 w-full text-black h-10 rounded font-semibold' >{user?.plan === 1 ? "Your current plan" : user?.plan === 2 ? "Subscribed to Gold " : user?.plan === 3 ? "Subscribed to Platinum" : "Select"}</button>
                            </DialogTrigger>
                        </div>
                        <div className='rounded-2xl flex flex-col max-w-xs w-full border border-amber-600/75 hover:shadow-xl hover:shadow-amber-700/50 p-4 bg-amber-300/10' >
                            <h3 className='text-4xl font-bold uppercase text-amber-300 flex items-center gap-2' >
                                <BsFillRocketTakeoffFill size={24} className=' mt-1' />
                                Gold
                            </h3>
                            <div className='flex items-center my-8' >
                                <p className='text-7xl font-bold text-amber-300 ' >$19<span className='text-base font-medium text-slate-200' >/month</span> </p>
                            </div>
                            <div className=' flex-grow flex flex-col  gap-2 mb-4' >
                                <Benifit text={"10,000 Credits"} />
                                <Benifit text={"3 simultaneous song generations"} />
                            </div>
                            <DialogTrigger asChild>
                                <button onClick={() => {
                                    setApplied(false)
                                    setCoupon("")
                                    setPrice(19)
                                    setSelectedPlan(2)
                                }}
                                    disabled={user?.plan >= 2} className='disabled:opacity-50 bg-amber-300 hover:bg-amber-400 w-full text-black h-10 rounded font-semibold' >{user?.plan === 2 ? "Your curent plan " : user?.plan === 3 ? "Subscribed to Platinum" : "Select"}</button>
                            </DialogTrigger>
                        </div>
                        <div className='rounded-2xl flex flex-col max-w-xs w-full border border-slate-100/75 hover:shadow-xl hover:shadow-slate-700 p-4 bg-slate-100/10' >
                            <h3 className='text-4xl font-bold uppercase text-slate-100 flex items-center gap-2' >
                                <GiRocketThruster size={24} className='mt-1' />
                                Platinum
                            </h3>
                            <div className='flex items-center my-8' >
                                <p className='text-7xl font-bold text-slate-100 ' >$45<span className='text-base font-medium text-slate-200' >/month</span> </p>
                            </div>
                            <div className=' flex-grow flex flex-col  gap-2 mb-4' >
                                <Benifit text={"50,000 Credits"} />
                                <Benifit text={"5 simultaneous song generations"} />
                            </div>
                            <DialogTrigger asChild>
                                <button onClick={() => {
                                    setApplied(false)
                                    setCoupon("")
                                    setPrice(45)
                                    setSelectedPlan(3)
                                }}
                                    disabled={user?.plan === 3} className='disabled:opacity-50 bg-slate-100 hover:bg-slate-200 w-full text-black h-10 rounded font-semibold' >{user?.plan === 3 ? "Your current plan" : "Select"}</button>
                            </DialogTrigger>
                        </div>
                        <DialogOverlay className='z-[9999]' >
                            <DialogContent className={`max-w-[300px] w-full bg-gradient-to-br from-slate-800 to-slate-950 p-4 ${selectedPlan === 1 ? " border-green-400 " : selectedPlan === 2 ? " border-amber-300 " : selectedPlan === 3 ? " border-slate-100" : " border-slate-300 "}`}>
                                <DialogTitle className={`p-0 text-slate-500 font-potta justify-center text-2xl flex items-center gap-1 ${selectedPlan === 1 ? " text-green-400 " : selectedPlan === 2 ? " text-amber-300 " : selectedPlan === 3 ? " text-slate-100" : " text-slate-300 "}`} >
                                    {selectedPlan === 1 && <><BsFillRocketFill size={20} /> Premium Plan </>}
                                    {selectedPlan === 2 && <> <BsFillRocketTakeoffFill size={20} /> Gold Plan</>}
                                    {selectedPlan === 3 && <><GiRocketThruster size={20} /> Platinum Plan</>}
                                </DialogTitle>
                                <DialogDescription className={`text-center ${selectedPlan === 1 ? " text-green-400 " : selectedPlan === 2 ? " text-amber-300 " : selectedPlan === 3 ? " text-slate-100" : " text-slate-300 "}`} >
                                    <p className='text-6xl font-bold mt-6 mb-8 ' >${price}<span className='text-base font-medium text-slate-200' >/month</span> </p>
                                </DialogDescription>
                                {applied && <p className={` text-center text-xs font-medium flex items-center justify-center gap-1 ${selectedPlan === 1 ? " text-green-400 " : selectedPlan === 2 ? " text-amber-300 " : selectedPlan === 3 ? " text-slate-100" : " text-slate-300 "}`} ><IoCheckmarkDoneOutline size={20} />  Coupon Applied</p>}
                                <div className='flex gap-2' >
                                    <input
                                        onChange={(e) => setCoupon(e.target.value)}
                                        className={`w-full focus:outline-none placeholder:text-sm placeholder:text-slate-500 px-2 py-1 rounded bg-slate-950 border text-slate-200 ${selectedPlan === 1 ? " border-green-400 " : selectedPlan === 2 ? " border-amber-300 " : selectedPlan === 3 ? " border-slate-100" : " border-slate-300 "}`}
                                        type='text' placeholder='Coupon (optional)' />
                                    <button onClick={verifyCoupon} disabled={loading} type='button' className={`disabled:animate-pulse px-4 py-1.5 rounded text-sm font-medium ${selectedPlan === 1 ? " bg-green-400 hover:bg-green-300 " : selectedPlan === 2 ? " bg-amber-300 hover:bg-amber-400 " : selectedPlan === 3 ? " bg-slate-100 hover:bg-slate-300 " : " text-slate-300 "} `} >{loading ? "Applying..." : !loading && applied ? "Applied" : "Apply"}</button>
                                </div>
                                <button disabled={subLoading} onClick={() => buyPremium(selectedPlan)} className={`disabled:animate-pulse px-4 py-2 rounded font-medium ${selectedPlan === 1 ? " bg-green-400 hover:bg-green-500 " : selectedPlan === 2 ? " bg-amber-300 hover:bg-amber-400" : selectedPlan === 3 ? " bg-slate-100 hover:bg-slate-300" : " bg-slate-300 "} `} >{subLoading ? "Processing..." : "Subscribe"}</button>
                            </DialogContent>
                        </DialogOverlay>
                    </Dialog>
                </div>
            </div>
            <BottomBar />
        </div>
    )
}

export default Subscription


function Benifit({ text }) {
    return (
        <div className='flex items-center gap-2' >
            <FaCheck size={24} />
            <p className='font-medium text-sm' >{text}</p>
        </div>
    )
}