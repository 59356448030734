import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    creatingSongs: [],
    extendSong: null,
    cloneSong: null,
    selectedTab: "create",
    createDesc: '',
    customTitle: '',
    customLyrics: '',
    customDesc: '',
    liveSong: {
        streamUrl: null,
        isBuffering: false,
        isPlaying: false
    },
}

const createSongSlice = createSlice({
    name: "create",
    initialState,
    reducers: {
        ADD_CREATING_SONG(state, action) {
            state.creatingSongs.unshift(action.payload)
        },
        REMOVE_CREATING_SONG(state, action) {
            state.creatingSongs = state.creatingSongs.filter(song => song.creationId !== action.payload.creationId);
        },
        UPDATE_CREATING_SONG(state, action) {
            const index = state.creatingSongs.findIndex(song => song.creationId === action.payload.creationId);
            if (index !== -1) {
                state.creatingSongs[index] = { ...state.creatingSongs[index], ...action.payload };
            }
        },
        SET_EXTENDSONG(state, action) {
            state.extendSong = action.payload
        },
        SET_CLONESONG(state, action) {
            state.cloneSong = action.payload
        },
        SET_SELECTEDTAB(state, action) {
            state.selectedTab = action.payload
        },
        SET_CREATEDESC(state, action) {
            state.createDesc = action.payload
        },
        SET_CUSTOMTITLE(state, action) {
            state.customTitle = action.payload
        },
        SET_CUSTOMLYRICS(state, action) {
            state.customLyrics = action.payload
        },
        SET_CUSTOMDESC(state, action) {
            state.customDesc = action.payload
        },
        PLAY_LIVESONG(state, action) {
            state.liveSong = action.payload
        },
        // eslint-disable-next-line no-unused-vars
        STOP_LIVESONG(state, action) {
            state.liveSong = null
        },
        SET_BUFFERING(state, action) {
            state.liveSong.isBuffering = action.payload
        }
    }
});

export const {
    ADD_CREATING_SONG,
    REMOVE_CREATING_SONG,
    UPDATE_CREATING_SONG,
    SET_CLONESONG,
    SET_EXTENDSONG,
    SET_SELECTEDTAB,
    SET_CREATEDESC,
    SET_CUSTOMTITLE,
    SET_CUSTOMDESC,
    SET_CUSTOMLYRICS,
    STOP_LIVESONG,
    PLAY_LIVESONG,
    SET_BUFFERING
} = createSongSlice.actions

export default createSongSlice.reducer