import { SongCardSkeleton } from "@/components/createSong/CreateSongLoading";
import TopBar from "@/components/navbar/TopBar";
import BottomBar from "@/components/sidebar/BottomBar";
import SongCard from "@/components/songCard/SongCard";
import { ADD_LIKEDSONGS, SET_LIKEDSONGS } from "@/redux/features/music/musicSlice";
import axios from "axios"
import { debounce } from "lodash";
import Lottie from "lottie-react";
import { useCallback, useEffect, useState } from "react"
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import likedAni from "../../assets/like.json"
import { Button } from "@/components/ui/button";
import { BiLogInCircle } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { FaListUl } from "react-icons/fa6";
import { HiViewGrid } from "react-icons/hi";
import SongCardListView, { SongCardMyCreationSkeleton } from "@/components/songCard/SongCardListView";

const LikedSongs = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    // const [selected, setSelected] = useState("all");
    const { song, likedSongs } = useSelector(state => state.music);
    const { user } = useSelector(state => state.auth);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [noResultMsg, setNoResultMsg] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [searchSongs, setSearchSongs] = useState([])
    const [term, setTerm] = useState('')
    const tabViewType = localStorage.getItem('tabViewLiked')
    const [tabView, setTabView] = useState(tabViewType)

    useEffect(() => {
        const fetchSongs = async () => {
            const token = localStorage.getItem('token');
            try {
                if (page === 1) {
                    dispatch(SET_LIKEDSONGS([]))
                }
                const response = await axios.get(`/likedsongs?page=${page}`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                dispatch(ADD_LIKEDSONGS(response.data.songs))
                if (response.data.songs.length === 0 || response.data.songs.length < 20) {
                    setHasMore(false); // No more songs to load
                }
            } catch (error) {
                console.log('Error fetching songs:', error);
            }
        };
        if (user) {
            fetchSongs();
        }
    }, [page, user, dispatch]);

    // Define the debounced function directly inside useEffect
    useEffect(() => {
        const token = window.localStorage.getItem('token')
        const debouncedSearch = debounce(async () => {
            if (!term) return;
            try {
                setIsLoading(true)
                const response = await axios.get(`/searchliked?item=${term}`, { headers: { Authorization: `Bearer ${token}` } });
                setSearchSongs(response.data.songs)
                setIsLoading(false)
                if (response?.data?.songs?.length === 0) {
                    setNoResultMsg(true)
                } else {
                    setNoResultMsg(false)
                }
            } catch (error) {
                setIsLoading(false)
                console.error('Search failed:', error);
            }
        }, 300);
        debouncedSearch();
        return () => {
            debouncedSearch.cancel();
        };
    }, [term]);

    // change view
    const handleViewChange = useCallback(() => {
        const view = localStorage.getItem('tabViewLiked')
        if (view === 'list') {
            setTabView('grid')
            localStorage.setItem('tabViewLiked', 'grid')
        } else {
            setTabView('list')
            localStorage.setItem('tabViewLiked', 'list')
        }
    }, [])
    // Change Tab view
    useEffect(() => {
        if (localStorage.getItem('tabViewLiked') === null) {
            setTabView('grid')
            localStorage.setItem('tabViewLiked', 'grid')
        } else {
            setTabView(tabView)
        }
    }, [tabView])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='relative min-h-screen bg-slate-950 overflow-hidden flex flex-col flex-grow w-full' >
            <TopBar heading={"Liked Songs"} />
            {user && <div className='md:pt-20 pt-20 flex flex-col flex-grow overflow-hidden' >
                <div className=' pb-2 flex justify-between gap-4 px-4' >
                    <input disabled={likedSongs?.length === 0} type='text' placeholder='Search liked songs' value={term}
                        onChange={(e) => setTerm(e.target.value)}
                        className=' disabled:opacity-50 flex-grow bg-transparent border border-slate-700 p-2 rounded-md lg:max-w-lg w-full focus:outline-none'
                    />
                    <button onClick={handleViewChange} className=' h-10 aspect-square flex items-center p-1 justify-center border rounded border-slate-700 hover:bg-slate-900 hover:text-green-400' type='button' >
                        {tabView === "grid" ? <FaListUl size={26} /> : <HiViewGrid size={26} />}
                    </button>
                </div>
                {(!isLoading && term === "") ?
                    <InfiniteScroll
                        dataLength={likedSongs?.length}
                        next={() => setPage(prevPage => prevPage + 1)}
                        hasMore={hasMore}
                        loader={<Loader tabView={tabView} />}
                        endMessage={null}
                        className={' flex-grow py-4 pt-3 w-full grid  ' + (song ? " pb-36 md:pb-28 " : " pb-24 ") + (tabView === "list" ? " grid-cols-1 gap-x-4 gap-y-3 " : " grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-x-4 gap-y-8 px-4 ")}
                    >
                        {likedSongs?.map((song) => (
                            tabView === "list" ?
                                <SongCardListView author={song?.username === user?.username ? true : false} extend={true} song={song} key={song?.song_id} playingFrom={"userSongs"} />
                                :
                                <SongCard song={song} key={song?.song_id} playingFrom={"userSongs"} />
                        ))}
                    </InfiniteScroll> :
                    (!isLoading && !noResultMsg && searchSongs?.length > 0) ?
                        <div className={'flex-grow p-4 w-full grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-x-4 gap-y-8 ' + (song ? " pb-36 md:pb-28 " : " pb-24 ")}>
                            {searchSongs?.map((song) => (
                                <SongCard key={song?.song_id} song={song} playingFrom={"userSongs"} />
                            ))}
                        </div> :
                        (!isLoading && searchSongs.length === 0 && noResultMsg) ?
                            <p className='px-4' >No songs found. Try different search terms.</p> :
                            <div className={'flex-grow p-4 w-full grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-x-4 gap-y-8 ' + (song ? " pb-36 md:pb-28 " : " pb-24 ")} >
                                <Loader tabView={tabView} />
                            </div>
                }
            </div>}
            {!user && <div className=' flex items-center justify-center h-screen'>
                <div>
                    <div className='max-w-sm w-full mb-32 relative' >
                        <Lottie animationData={likedAni} />
                        <p className=' text-center w-full text-green-400 font-semibold' >Login to view your liked songs</p>
                        <div className='mt-4 w-full flex items-center justify-center' > <Button className="bg-green-400 text-center text-black hover:bg-green-300 font-semibold px-4 py-2  flex items-center gap-2" onClick={() => navigate("/login")} >Login<BiLogInCircle className="mt-1" size={24} /></Button></div>
                    </div>
                </div>
            </div>}
            <BottomBar />
        </div>
    )
}

export default LikedSongs



function Loader({ tabView }) {
    return (
        tabView === "list" ? <>
            <SongCardMyCreationSkeleton />
            <SongCardMyCreationSkeleton />
            <SongCardMyCreationSkeleton />
            <SongCardMyCreationSkeleton />
            <SongCardMyCreationSkeleton />
            <SongCardMyCreationSkeleton />
            <SongCardMyCreationSkeleton />
            <SongCardMyCreationSkeleton />
            <SongCardMyCreationSkeleton />
            <SongCardMyCreationSkeleton />
        </> : <>
            <SongCardSkeleton />
            <SongCardSkeleton />
            <SongCardSkeleton />
            <SongCardSkeleton />
            <SongCardSkeleton />
            <SongCardSkeleton />
            <SongCardSkeleton />
            <SongCardSkeleton />
            <SongCardSkeleton />
            <SongCardSkeleton />
        </>
    )
}