import { Link } from "react-router-dom"
import logo from "../../assets/footer-frog.png"
import { useEffect } from "react"

const PrivacyPolicy = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="bg-gradient-to-br from-slate-950 to-slate-800 text-slate-300 min-h-screen font-sans" >
            <div className="fixed top-0 left-0 w-full bg-slate-950 h-16 border-b border-slate-800" >
                <div className="mx-auto max-w-screen-lg px-4 md:px-10 flex items-center justify-between" >
                    <Link to={"/"} className="flex items-center gap-4 py-4 " >
                        <img src={logo} className="max-w-[40px] w-full rounded-full" alt="img" />
                        <div className=" font-semibold text-center lg:text-xl text-green-400" >SoundOfMeme</div>
                    </Link>
                    <Link to="/" className="text-slate-300 font-medium hover:text-green-400" >Home</Link>
                </div>
            </div>
            <div className="max-w-screen-lg mx-auto px-4 md:px-10 py-20" >
                <h1 className="font-potta text-4xl text-green-400" >Privacy Policy</h1>
                <p className="mt-8 font-medium" >Effective Date: <span className="text-green-400" >30 September 2024</span> </p>
                <p className="mt-8" >SoundOfMeme,  we operates the Sound of Meme app and website. This Privacy Policy outlines our policies regarding the collection, use, and disclosure of personal information when you use our Service and the choices you have associated with that information.</p>
                <p className="mt-4" >By using the Service, you agree to the collection and use of information in accordance with this policy.</p>
                <h2 className="mt-8 text-2xl font-potta text-green-400" >Information We Collect</h2>
                <p className="mt-4" >We collect the following types of information:</p>
                <h2 className="mt-8 text-lg font-medium text-slate-100" >1. Personal Information</h2>
                <p className="mt-4" >When you register or interact with our Service, we may collect:</p>
                <ul className="list-disc ml-5 mt-4 flex flex-col gap-2" >
                    <li><span className="font-medium text-slate-100" >Name</span></li>
                    <li><span className="font-medium text-slate-100" >Email</span></li>
                    <li><span className="font-medium text-slate-100" >User generated content:</span> Titles, custom lyrics, images, MP3 files, and other content you upload to the app. </li>
                </ul>
                <h2 className="mt-8 text-lg font-medium text-slate-100" >2. Usage Data</h2>
                <p className="mt-4" >We may collect information automatically when you access the Service, including:</p>
                <ul className="list-disc ml-5 mt-4 flex flex-col gap-2" >
                    <li><span className="font-medium text-slate-100" >IP Address</span></li>
                    <li><span className="font-medium text-slate-100" >Browser Type and Version</span></li>
                    <li><span className="font-medium text-slate-100" >Device Information </span> (e.g., device type, operating system, unique device IDs)</li>
                    <li><span className="font-medium text-slate-100" >App Usage Data </span> (e.g., features used, crash data, log files)</li>
                </ul>
                <h2 className="mt-8 text-lg font-medium text-slate-100" >3. Cookies</h2>
                <p className="mt-4" >We use cookies and similar tracking technologies to monitor activity on the Service and store certain information. You can instruct your browser to refuse cookies or indicate when a cookie is being sent.</p>
                <h2 className="mt-8 text-2xl font-potta text-green-400" >How We Use Your Information</h2>
                <p className="mt-4" >We use the information we collect for various purposes, including:</p>
                <ul className="list-disc ml-5 mt-4 flex flex-col gap-2" >
                    <li>To provide and maintain the Service</li>
                    <li>To personalize your experience</li>
                    <li>To analyze usage patterns and improve the Service</li>
                    <li>To send service-related notifications, updates, and promotional content</li>
                    <li>To detect and prevent fraud, unauthorized activities, or breaches of our Terms of Service</li>
                </ul>
                <h2 className="mt-8 text-2xl font-potta text-green-400" >Sharing and Disclosure of Information</h2>
                <p className="mt-4" >We may share your information in the following situations:</p>
                <ul className="list-disc ml-5 mt-4 flex flex-col gap-2" >
                    <li><span className="font-medium text-slate-100" >Service Providers: </span>We may employ third-party companies and individuals to facilitate our Service (e.g., hosting services, analytics providers) who may access personal data on our behalf.</li>
                    <li><span className="font-medium text-slate-100" >Legal Requirements:</span> We may disclose your information if required to do so by law or to protect the rights, property, or safety of Persist Ventures, our users, or the public.</li>
                    <li><span className="font-medium text-slate-100" >Business Transfers:</span> In the event of a merger, acquisition, or asset sale, your personal data may be transferred.</li>
                </ul>
                <h2 className="mt-8 text-2xl font-potta text-green-400" >Data Retention</h2>
                <p className="mt-4" >We retain your personal data only for as long as necessary for the purposes set out in this Privacy Policy. We may also retain usage data for internal analysis purposes. Usage data is generally retained for a shorter period, except when used to strengthen the security or improve the functionality of our Service.</p>
                <h2 className="mt-8 text-2xl font-potta text-green-400" >Payment Processing</h2>
                <p className="mt-4" >We utilize third-party service providers that act as resellers of, and/or payment processors for the fees payable for, licenses to certain of our Services and/or content that may be purchased through our Services (each, a &quot;Payment Processor&quot;). In order to purchase such Services through our Services, you will be required to provide credit/debit card and/or financial institution account information directly to the Payment Processor. Such information is not collected or stored by us.</p>
                <h2 className="mt-8 text-2xl font-potta text-green-400" >Security of Your Data</h2>
                <p className="mt-4" >We prioritize the security of your personal data and use industry-standard measures to protect it. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</p>
                <h2 className="mt-8 text-2xl font-potta text-green-400" >International Data Transfers</h2>
                <p className="mt-4" >Your information, including personal data, may be transferred to — and maintained on — computers located outside your state, province, country, or other governmental jurisdiction where data protection laws may differ from those in your jurisdiction.</p>
                <h2 className="mt-8 text-2xl font-potta text-green-400" >Your Rights and Choices</h2>
                <p className="mt-4" >Depending on your location, you may have certain rights regarding your personal data, including:</p>
                <ul className="list-disc ml-5 mt-4 flex flex-col gap-2" >
                    <li><span className="font-medium text-slate-100" >Access:</span> You can request access to the personal information we hold about you.</li>
                    <li><span className="font-medium text-slate-100" >Correction:</span> You can request that we correct any inaccuracies in your personal information.</li>
                    <li><span className="font-medium text-slate-100" >Deletion:</span> You can request the deletion of your personal data, subject to legal limitations.</li>
                    <li><span className="font-medium text-slate-100" >Opt-out:</span> You can opt-out of receiving marketing communications at any time.</li>
                </ul>
                <p className="mt-4" >To exercise these rights, please contact us at <a href="mailto:contact@soundofmeme.com" className="text-green-400" >contact@soundofmeme.com</a> .</p>
                <h2 className="mt-8 text-2xl font-potta text-green-400" >Children&apos;s Privacy</h2>
                <p className="mt-4" >Our Service is not intended for use by anyone under the age of 13. We do not knowingly collect personal information from children under 13. If you are a parent or guardian and believe your child has provided us with personal information, please contact us to request deletion.</p>
                <h2 className="mt-8 text-2xl font-potta text-green-400" >Changes to This Privacy Policy</h2>
                <p className="mt-4" >We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. Changes are effective immediately upon posting.</p>
                <h2 className="mt-8 text-2xl font-potta text-green-400" >Contact Us</h2>
                <p className="mt-4" >If you have any questions about this Privacy Policy, please contact us at:</p>
                <p className="mt-4" > <span className="text-slate-100 font-medium" >Email:</span> <a href="mailto:contact@soundofmeme.com" className="text-green-400" >contact@soundofmeme.com</a></p>

            </div>
        </div>
    )
}

export default PrivacyPolicy