import { useNavigate } from "react-router-dom";
import { Button } from "../ui/button";
import { Card, CardContent, CardDescription, CardFooter, CardHeader } from "../ui/card";
import { TabsContent } from "../ui/tabs";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { v4 as uuidv4 } from 'uuid';
import { APPEND_SONGS } from "@/redux/features/music/musicSlice";
import axios from "axios";
import ani1 from "../../assets/createani1.gif"
import ani2 from "../../assets/createani2.gif"
import ani3 from "../../assets/createani3.gif"
import { SET_CREDITS } from "@/redux/features/auth/authSlice";
import { RiDeleteBin6Line } from "react-icons/ri";
import { IoCameraOutline } from "react-icons/io5";
import { FaCircleCheck } from "react-icons/fa6";
import { ImHappy2 } from "react-icons/im";
import { GiRoastChicken } from "react-icons/gi";
import { AiFillMessage } from "react-icons/ai";
import { ADD_CREATING_SONG, REMOVE_CREATING_SONG, STOP_LIVESONG, UPDATE_CREATING_SONG } from "@/redux/features/music/createSlice";
import imageCompression from 'browser-image-compression';
import { logEvent } from "firebase/analytics";
import { analytics } from "@/firebase";

const genreTags = [
    'Pop', 'Rock', 'Jazz', 'Blues', 'Classical', 'Country', 'Electronic', 'Dance',
    'Hip Hop', 'Rap', 'R&B', 'Soul', 'Reggae', 'Folk', 'Metal', 'Punk', 'Funk',
    'Disco', 'House', 'Techno', 'Trance', 'Dubstep', 'Drum and Bass', 'Ambient',
    'Indie', 'Gospel', 'Opera', 'Ska', 'Grunge', 'Swing', 'Bluegrass', 'Latin',
    'Flamenco', 'Celtic', 'World', 'New Age', 'EDM', 'Lo-Fi', 'Alternative',
    'K-Pop', 'J-Pop', 'C-Pop', 'Afrobeat', 'Bollywood', 'Heavy Metal', 'Thrash Metal',
    'Black Metal', 'Glitch Hop', 'Trap', 'Grime'
];

const CreateSongFromImg = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [selectedTags, setSelectedTags] = useState([]);
    const creatinRef = useRef({})
    const [genre, setGenre] = useState('')
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedCaptureFile, setSelectedCaptureFile] = useState(null);
    const fileInputRef = useRef(null);
    const { user } = useSelector(state => state.auth)
    const [toPublish, setToPublish] = useState(true)
    const [link, setLink] = useState('');
    const [imageSrc, setImageSrc] = useState(null);
    const fileInputRef2 = useRef(null);
    const pollIntervalsRef = useRef({});
    const [prompt, setPrompt] = useState(1)
    const [message, setMessage] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const options = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 1024,
        useWebWorker: true,
    }

    const updateSelectedTagsFromDescription = useCallback((description) => {
        const tags = description.split(',').map(tag => tag.trim());
        const validTags = tags.filter(tag => genreTags.includes(tag));
        setSelectedTags(validTags);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // Update selectedTags whenever createDesc changes
        updateSelectedTagsFromDescription(genre);
    }, [genre, updateSelectedTagsFromDescription]);

    // handle toggle tags
    const handleToggleTag = (tag) => {
        let newDesc;
        const parts = genre.split(',').map(part => part.trim()).filter(part => part !== '');

        if (selectedTags.includes(tag)) {
            // Remove tag
            newDesc = parts.filter(part => part !== tag).join(', ');
        } else {
            // Add tag
            if (selectedTags.length >= 5) {
                return toast.error("Only 5 tags allowed", { id: "warningTag" });
            }
            if (parts.includes(tag)) {
                return; // Tag already exists, do nothing
            }
            newDesc = [...parts, tag].join(', ');
        }

        if (newDesc.length > 500) {
            return toast.error("Max 500 characters allowed", { id: "chars" });
        }
        setGenre(newDesc)
    };

    const handleChange = (event) => {
        const newValue = event.target.value;
        setGenre(newValue)
    };

    // create song request
    const handleCreateImgSong = async () => {
        // validation
        if (!selectedFile && !link && !selectedCaptureFile) {
            return toast.error("Please choose a image or enter a URL", { id: "create" })
        }
        if (prompt === 2 && (!message || message.length < 20)) {
            return toast.error("Message must be at least 20 characters", { id: "create" })
        }
        // formdata
        setIsLoading(true)
        toast.loading("Initializing", { id: "credits" })
        let formData = new FormData()
        formData.append('publish', toPublish)
        if (selectedFile) formData.append('file', await imageCompression(selectedFile, options))
        if (selectedCaptureFile) formData.append('file', await imageCompression(selectedCaptureFile, options))
        if (link) formData.append('url', link)
        if (prompt === 0) formData.append('prompt', 0)
        if (prompt === 1) formData.append('prompt', 1)
        if (prompt === 2) formData.append('prompt', message)
        if (genre || genre.length > 0) formData.append('genre', genre)
        // unique id
        const creationId = uuidv4();
        try {
            const token = localStorage.getItem('token')
            const { data } = await axios.get("/balance", { headers: { Authorization: `Bearer ${token}` } })
            dispatch(SET_CREDITS(data?.amount))
            if (data?.amount < 150) {
                setIsLoading(false)
                return toast.error("Not enough credits", { id: "credits" })
            } else {
                toast.dismiss("credits")
            }
            toast.loading("Sending Request", { id: creationId })
            const response = await axios.post("https://engine.soundofmeme.com/image",
                formData,
                { headers: { Authorization: `Bearer ${token}` } })

            const songIds = response.data.songs
            const songIdsArr = songIds?.split(',')
            const songsArray = songIdsArr.map(id => ({ song_id: parseInt(id) }))
            creatinRef.current[creationId] = true
            dispatch(ADD_CREATING_SONG({ creationId: creationId, feat: "create", songs: songsArray }))
            changeStatus(creationId)
            startPollingForSongCompletion(songIds, creationId)
            setIsLoading(false)
            if (window.innerWidth < 640 || user?.plan === 0) {
                navigate("/creations")
            }
        } catch (error) {
            setIsLoading(false)
            handleError(error, creationId)
        }
    }

    // Polling method for song completion
    const startPollingForSongCompletion = (songIds, creationId) => {
        const interval = setInterval(async () => {
            try {
                const token = localStorage.getItem('token');
                const { data } = await axios.get(`/songs/?song_ids=${songIds}`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                if (data.status === 'completed') {
                    clearInterval(interval);
                    delete pollIntervalsRef.current[songIds];
                    dispatch(REMOVE_CREATING_SONG({ creationId }));
                    dispatch(APPEND_SONGS(data.songs));
                    dispatch(STOP_LIVESONG())
                    toast.success(`All done! Your song is ready to play.`, { id: creationId });
                    delete creatinRef.current[creationId];
                    logEvent(analytics, 'song_creation', {
                        method: "image",
                        success: true,  // Custom parameter to track success/failure
                        songId: songIds, // Optional: Any additional data you want to track
                    });
                } else {
                    dispatch(UPDATE_CREATING_SONG({ creationId: creationId, songs: data.songs }))
                }
            } catch (error) {
                clearInterval(interval)
                dispatch(STOP_LIVESONG())
                delete pollIntervalsRef.current[songIds];
                handleError(error, creationId);
                logEvent(analytics, 'song_creation', {
                    method: "image",
                    success: true,  // Custom parameter to track success/failure
                    songId: songIds, // Optional: Any additional data you want to track
                });
            }
        }, 10000);
        // Store interval ID for cleanup
        pollIntervalsRef.current[songIds] = interval;
    };

    // Handle errors
    const handleError = (error, creationId) => {
        console.error(error);
        dispatch(REMOVE_CREATING_SONG({ creationId }));
        delete creatinRef.current[creationId];
        toast.error(error?.response?.data?.detail || error.message || "Oops! Something went wrong. Please try again.", { id: creationId });
    };

    // Change status function
    const changeStatus = useCallback((creationId) => {
        toast("Crafting your tune, hang tight!", { id: creationId })
        dispatch(UPDATE_CREATING_SONG({ creationId: creationId, ani: ani1, text: "Analyzing your description..." }))
        setTimeout(() => {
            if (!creatinRef.current[creationId]) return toast.dismiss(creationId);
            dispatch(UPDATE_CREATING_SONG({ creationId: creationId, ani: ani2, text: "Adding the rhythms..." }))
        }, 15000);
        setTimeout(() => {
            if (!creatinRef.current[creationId]) return toast.dismiss(creationId);
            dispatch(UPDATE_CREATING_SONG({ creationId: creationId, ani: ani3, text: "Composing your melody..." }))
        }, 30000);
    }, [dispatch])

    const handleFileChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            if (file.type.match('image.*')) {
                setSelectedFile(file);
            } else {
                clearFileSelection();
                toast.error('Please select an image file.', { id: "file" });
            }
        } else {
            setSelectedFile(null);
        }
    };

    // capture image function
    const handleCapture = (event) => {
        const file = event.target.files[0];
        if (file && file.type.startsWith('image')) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImageSrc(reader.result); // Display the image
                // Convert Base64 string to a File object
                const dataUrl = reader.result;
                const blob = dataURItoBlob(dataUrl);
                const imageFile = new File([blob], `photo_${Date.now()}.jpg`, { type: 'image/jpeg' });
                // Now you can handle imageFile just like the selectedFile
                setSelectedCaptureFile(imageFile);
            };
            reader.readAsDataURL(file);
        }
    };

    // Helper function to convert data URI to Blob
    function dataURItoBlob(dataURI) {
        const byteString = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
    }


    const clearFileSelection = () => {
        if (fileInputRef.current) fileInputRef.current.value = '';
        setSelectedFile(null);
    };
    const clearImageSelection = () => {
        if (fileInputRef2.current) fileInputRef2.current.value = '';
        setImageSrc(null);
        setSelectedCaptureFile(null)
    };

    const handleClearImageFiles = () => {
        clearFileSelection()
        clearImageSelection()
    }

    // handle Link change
    const handleLinkChange = (e) => {
        setLink(e.target.value)
    }

    // check device
    function isMobileDevice() {
        const userAgent = window.navigator.userAgent || navigator.userAgent || window.opera;
        const mobilePatterns = /Android|webOS|iPhone|iPad|iPod|Macintosh|BlackBerry|IEMobile|Opera Mini/i;
        return mobilePatterns.test(userAgent);
    }

    return (
        <TabsContent value="image">
            <Card className="bg-transparent border-0 " >
                <div className="flex justify-between items-center text-slate-400 mb-1 text-sm" >
                    <p className="font-semibold" >Credits: <span>{user?.credits}</span></p>
                    <p className="font-semibold" >Cost: <span>150</span></p>
                </div>
                <CardHeader className="p-0 my-2" >
                    <CardDescription className='text-slate-400 text-sm font-medium' >
                        Transform visuals into melodies! Upload an image or provide a URL.
                    </CardDescription>
                </CardHeader>
                <CardContent className="space-y-2 text-slate-100 flex flex-col gap-4 p-0 mt-4">
                    <div className="flex flex-col gap-2" >
                        <p className="text-slate-300 text-sm" >Choose a photo</p>
                        <div className="flex items-center gap-2" >
                            {(imageSrc || selectedFile) && <img className="h-9 rounded aspect-square object-cover border border-green-400" src={imageSrc || URL.createObjectURL(selectedFile)} />}
                            {(!selectedFile && !selectedCaptureFile) ?
                                <>
                                    <div className="flex items-center gap-2 w-full" >
                                        <input
                                            disabled={(selectedCaptureFile || link) ? true : false}
                                            type="file"
                                            accept="image/*"
                                            onChange={handleFileChange}
                                            ref={fileInputRef}
                                            className=" disabled:opacity-50 block flex-grow h-10 file:h-10 file:pb-1 file:bg-slate-800 file:border-0 file:text-teal-400 w-full text-sm text-slate-300 border border-teal-400/50 rounded file:mr-4 cursor-pointer bg-slate-900 focus:outline-none dark:placeholder-gray-400"
                                        />
                                    </div>
                                    {isMobileDevice() && <div className="w-full" >
                                        <input
                                            type="file"
                                            accept="image/*"
                                            capture="environment"
                                            style={{ display: 'none' }}
                                            ref={fileInputRef2}
                                            onChange={handleCapture}
                                        />
                                        <div className="flex items-center gap-2">
                                            <button disabled={(selectedFile || link) ? true : false} className={" disabled:opacity-50 h-10 w-full border flex items-center justify-center gap-2 bg-slate-900 rounded font-semibold text-sm " + (selectedCaptureFile ? " border-red-500/50 text-red-500 " : " border-teal-400/50 text-teal-400 ")} onClick={() => fileInputRef2.current.click()}>{!imageSrc ? <><IoCameraOutline className="mt-0.5" size={20} /> Take Photo  </> : "Remove Photo"}</button>
                                        </div>
                                    </div>}
                                </> : <button className={" disabled:opacity-50 h-10 w-full border flex items-center justify-center gap-2 bg-slate-900 rounded font-semibold text-sm  border-red-500/50 text-red-500 "} onClick={handleClearImageFiles}>Remove Photo</button>}
                        </div>
                    </div>
                    <div className="w-full h-px bg-slate-700 my-4 relative" >
                        <span className="absolute -top-3.5 left-1/2 -translate-x-1/2 bg-slate-900 px-2" >or</span>
                    </div>
                    <div className="flex items-center gap-2" >
                        <input
                            disabled={(selectedFile || selectedCaptureFile) ? true : false}
                            type="text"
                            onChange={handleLinkChange}
                            value={link}
                            placeholder="Image URL"
                            className=" disabled:opacity-50 p-2 block flex-grow h-10 w-full text-sm text-slate-300 border border-slate-700/75 rounded bg-slate-900 focus:outline-none dark:placeholder-gray-400"
                        />
                        {link && <button onClick={() => setLink('')} type="button" className="bg-red-800 border border-slate-700/75 h-10 w-10 flex items-center justify-center rounded" ><RiDeleteBin6Line size={24} /></button>}
                    </div>
                    <div>
                        <p className="text-slate-400 mb-2 text-sm" >Prompt Style</p>
                        <div className=" grid grid-cols-2 xs:flex sm:grid items-center gap-4" >
                            <button onClick={() => setPrompt(1)} className={"border flex-grow p-4 rounded flex items-center justify-between " + (prompt === 1 ? " border-green-400 " : " border-slate-700/75 ")} >
                                <div className="flex flex-col items-start" >
                                    <div className="flex items-center gap-2 text-sm sm:text-base font-semibold " >
                                        <ImHappy2 size={24} />
                                        <span>Boast</span>
                                    </div>
                                </div>
                                {prompt === 1 ?
                                    <FaCircleCheck size={16} className="text-green-400" />
                                    : <div className="w-4 h-4 border border-white rounded-full" ></div>}
                            </button>
                            <button onClick={() => setPrompt(0)} className={"border flex-grow p-4 rounded flex items-center justify-between " + (prompt === 0 ? " border-green-400 " : " border-slate-700/75 ")} >
                                <div className="flex flex-col items-start" >
                                    <div className="flex items-center gap-2 text-sm sm:text-base font-semibold " >
                                        <GiRoastChicken size={24} />
                                        <span>Roast</span>
                                    </div>
                                </div>
                                {prompt === 0 ?
                                    <FaCircleCheck size={16} className="text-green-400" />
                                    : <div className="w-4 h-4 border border-white rounded-full" ></div>}
                            </button>
                            <button onClick={() => setPrompt(2)} className={" hidden xs:flex sm:hidden border flex-grow p-4 rounded items-center justify-between " + (prompt === 2 ? " border-green-400 " : " border-slate-700/75 ")} >
                                <div className="flex flex-col items-start" >
                                    <div className="flex items-center gap-2 text-sm sm:text-base font-semibold " >
                                        <AiFillMessage size={24} />
                                        <span>Custom</span>
                                    </div>
                                </div>
                                {prompt === 2 ?
                                    <FaCircleCheck size={16} className="text-green-400" />
                                    : <div className="w-4 h-4 border border-white rounded-full" ></div>}
                            </button>
                        </div>
                        <button onClick={() => setPrompt(2)} className={" flex w-full mt-4 xs:hidden sm:flex border flex-grow p-4 rounded items-center justify-between " + (prompt === 2 ? " border-green-400 " : " border-slate-700/75 ")} >
                            <div className="flex flex-col items-start" >
                                <div className="flex items-center gap-2 text-sm sm:text-base font-semibold " >
                                    <AiFillMessage size={24} />
                                    <span>Custom</span>
                                </div>
                            </div>
                            {prompt === 2 ?
                                <FaCircleCheck size={16} className="text-green-400" />
                                : <div className="w-4 h-4 border border-white rounded-full" ></div>}
                        </button>
                    </div>
                    {prompt === 2 && <div className="flex flex-col gap-3">
                        <textarea value={message} onChange={e => setMessage(e.target.value)} id="thoughts" placeholder="Your thoughts!" className="bg-slate-900 rounded-md min-h-28 placeholder:text-sm w-full placeholder:text-slate-500 p-2 border border-slate-700/75 focus:outline-none focus:ring-1 ring-green-400 " ></textarea>
                    </div>}
                    <div className="relative ">
                        <div className="flex items-center gap-4 mb-2 justify-between text-xs font-medium text-slate-300" >
                            <p>Genre</p>
                            <p>{genre?.length}/500</p>
                        </div>
                        <textarea
                            id="description"
                            type="text"
                            value={genre}
                            onChange={handleChange}
                            maxLength={500}
                            className="w-full bg-slate-900 rounded-md min-h-16 p-2 placeholder:text-sm placeholder:text-slate-500 border border-slate-700/75 focus:outline-none focus:ring-1 ring-green-400 "
                            placeholder="Genre"
                        ></textarea>
                    </div>
                    <div className="flex items-center  gap-2 overflow-x-auto whitespace-nowrap w-full" >
                        {genreTags.map(tag => (
                            <button className={"text-xs py-0.5 px-1 rounded-md mb-2 w-full " + (selectedTags.includes(tag) ? " bg-green-400 text-black " : " bg-slate-700 ")} key={tag} onClick={() => handleToggleTag(tag)}>
                                {tag}
                            </button>
                        ))}
                    </div>

                    <label className="switch flex items-center justify-between border-b  pb-1 border-slate-700 cursor-pointer ">
                        <p className="font-semibold text-sm" >Publish</p>
                        <input checked={toPublish} onChange={(e) => setToPublish(e.target.checked)} type="checkbox" />
                        <span className="slider"></span>
                    </label>
                </CardContent>
                <CardFooter className="p-0 mt-6" >
                    <Button disabled={isLoading} onClick={handleCreateImgSong} className=' disabled:opacity-50 w-full bg-green-400 text-black hover:bg-green-300 font-semibold' >Create Song</Button>
                </CardFooter>
            </Card>
        </TabsContent>
    )
}

export default CreateSongFromImg