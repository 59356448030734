import { SET_MOBILEPLAYER, SET_PLAYING, SET_PLAYINGFROM, SET_PLAYLOADING, SET_SHOWVIEWSONG, SET_SONG, SET_VIEWSONG, SET_VIEWSONGFROM } from "@/redux/features/music/musicSlice"
import axios from "axios"
import { useState } from "react"
import toast from "react-hot-toast"
import { IoIosShareAlt } from "react-icons/io"
import { IoHeart, IoPlay, IoPlayOutline } from "react-icons/io5"
import { Oval, Rings } from "react-loader-spinner"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { MdOutlinePublicOff, MdPublic } from "react-icons/md"
import { HiOutlineArrowPath } from "react-icons/hi2";
import { TbDownload } from "react-icons/tb";
import { SET_CREATEDESC, SET_CUSTOMDESC, SET_CUSTOMLYRICS, SET_CUSTOMTITLE, SET_SELECTEDTAB } from "@/redux/features/music/createSlice"
import { DropdownMenu, DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem, DropdownMenuTrigger } from "../ui/dropdown-menu"
import { PiDotsThreeVerticalBold } from "react-icons/pi"
import AddToPlaylistDialog from "./AddToPlaylistDialog"
import { AiTwotoneFolderAdd } from "react-icons/ai"
// import { BsArrowsExpandVertical } from "react-icons/bs"
// import { GiMusicalNotes } from "react-icons/gi"

const SongCardCreatePage = ({ song, playingFrom, author, imageUrl }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    // eslint-disable-next-line no-unused-vars
    const [userSong, setUserSong] = useState(song)
    const { song: selectedSong, playing, playLoading } = useSelector(state => state.music)
    const { user } = useSelector(state => state.auth)
    const [downloadLoading, setdownloadLoading] = useState(false);
    const [isAddToPlaylistOpen, setIsAddToPlaylistOpen] = useState(false);

    const displayImage = imageUrl || userSong?.image_url;

    const handlePlay = () => {
        dispatch(SET_PLAYING(true))
        dispatch(SET_PLAYINGFROM(playingFrom))
        dispatch(SET_SONG(song))
        if (selectedSong?.song_url !== userSong?.song_url) {
            dispatch(SET_PLAYLOADING(true))
        }
    }

    const handlePause = () => {
        dispatch(SET_PLAYING(false))
    }

    // copy song link
    const copyToClipboard = (url) => {
        navigator.clipboard.writeText(url).then(() => {
            console.log('URL copied to clipboard!');
            toast.success('URL copied to clipboard!', { id: "copyToast" })
        }).catch(err => {
            console.error('Failed to copy:', err);
        });
    };

    const handleShare = async (song) => {
        const url = `https://song.soundofmeme.com/${song?.slug}`;
        const title = 'Check out this song!';
        // const text = userSong?.song_name + ":";

        if (navigator.share) {
            try {
                await navigator.share({
                    title,
                    // text,
                    url
                });
                console.log('Song shared successfully!');
            } catch (error) {
                console.error('Error sharing:', error);
            }
        } else {
            copyToClipboard(url)
        }
    };

    const handleMobilePlayer = (e) => {
        e.stopPropagation()
        if (song?.song_id !== selectedSong?.song_id) {
            dispatch(SET_VIEWSONG(song))
            dispatch(SET_SHOWVIEWSONG(true))
            dispatch(SET_VIEWSONGFROM(playingFrom))
        } else {
            dispatch(SET_VIEWSONG(null))
            dispatch(SET_SHOWVIEWSONG(false))
            dispatch(SET_VIEWSONGFROM(null))
        }
        dispatch(SET_MOBILEPLAYER(true))
    }

    const handleRecreate = (song) => {

        if (song?.prompt === null || song.prompt === '' || song.prompt === undefined) {
            toast.error('Feature not available on this song', { id: "recreate" })
            return
        }
        if (song?.prompt?.type === "create" || song?.prompt?.type === "custom") {
            if (song.prompt.type === "create") {
                dispatch(SET_SELECTEDTAB('create'))
                dispatch(SET_CREATEDESC(song.prompt.desc))
                navigate("/create")
            } else if (song.prompt.type === "custom") {
                dispatch(SET_SELECTEDTAB('custom'))
                dispatch(SET_CUSTOMDESC(song.prompt.genre))
                dispatch(SET_CUSTOMTITLE(song.prompt.desc))
                dispatch(SET_CUSTOMLYRICS(song.prompt.lyrics2))
                navigate("/create")
            }
        } else {
            toast.error('Feature not available on this song', { id: "recreate" })
            return
        }
    }

    // download song
    function download(url) {
        const a = document.createElement('a');
        a.href = url;
        a.download = `Sound of Meme`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }
    const downloadSong = async (song) => {

        if (!user) {
            return toast.error('Login to download the song')
        }
        const token = window.localStorage.getItem('token')
        try {
            setdownloadLoading(true)
            toast.loading("Starting Download", { id: "download" })
            const response = await axios.get(`/download?song_id=${song?.song_id}`, { headers: { Authorization: `Bearer ${token}` } });
            const signedUrl = response.data.url;
            download(signedUrl);
            setdownloadLoading(false)
            toast.success("Download Started", { id: "download" })
        } catch (error) {
            setdownloadLoading(false)
            console.error('Error fetching signed URL:', error);
            toast.error('Error Occured. Please try again.', { id: "download" });
        }
    };

    const handleAddToPlaylist = (e) => {
        e.stopPropagation();
        setIsAddToPlaylistOpen(true);
        // setIsDrawerOpen(false);
    };

    return (
        <div className={"flex gap-2 py-1 w-full px-4" + (selectedSong?.song_id === userSong?.song_id ? " bg-green-400/10 " : " hover:bg-slate-900 ")}>
            <div className={" flex-shrink-0 w-full max-w-[80px] overflow-hidden rounded-md relative select-none " + (selectedSong?.song_id === userSong?.song_id && playing ? " border-2 animate-border shadow-lg shadow-cyan-300 " : selectedSong?.song_id === userSong?.song_id ? " shadow-lg shadow-cyan-300/70 border border-green-400/75 " : " border border-green-400/60 ")} >
                <img className="w-full h-full object-cover select-none aspect-square" src={displayImage} alt='songCover' />
                <div className={"absolute inset-0 w-full  flex items-center justify-center   transition-opacity duration-200" + (selectedSong?.song_id === song?.song_id && playing ? " opacity-100  bg-black/60 backdrop-blur " : selectedSong?.song_id === userSong?.song_id ? " opacity-100 bg-black/50 backdrop-blur " : " opacity-0 hover:opacity-100 bg-black/50 ")} >
                    {(selectedSong?.song_id === userSong?.song_id && playLoading) ?
                        <div className="w-full flex items-center justify-center cursor-pointer" >
                            <Oval
                                visible={true}
                                height="20"
                                width="20"
                                strokeWidth={6}
                                strokeWidthSecondary={6}
                                color="#ffffff"
                                ariaLabel="oval-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                            />
                        </div> :
                        (selectedSong?.song_id === userSong?.song_id && playing) ?
                            <div onClick={handlePause} className="w-full flex items-center justify-center cursor-pointer" >
                                <Rings
                                    visible={true}
                                    height="60"
                                    width="60"
                                    color="#ffffff"
                                    ariaLabel="rings-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                /></div>
                            : <div onClick={handlePlay} className="w-full flex items-center justify-center cursor-pointer" ><IoPlayOutline size={30} /> </div>
                    }
                </div>
            </div>
            <div className="flex justify-between flex-grow truncate gap-0 sm:gap-4" >
                <div onClick={handleMobilePlayer} className=" cursor-pointer flex flex-col gap-3 truncate flex-grow " >
                    <p className={" font-lato font-semibold truncate lg:text-lg capitalize " + (userSong?.song_id === selectedSong?.song_id && " cursor-pointer text-green-400 ")} >{userSong?.song_name}</p>
                    <div className={" select-none flex gap-2 text-xs font-semibold text-slate-400 font-comforta " + (userSong?.song_id === selectedSong?.song_id && " cursor-pointer ")} >
                        {userSong?.tags?.slice(0, 3)?.map((tag, index) => (
                            <span key={index} className="capitalize cursor-default truncate" >{tag}</span>
                        ))}
                    </div>
                    <div className="flex items-center gap-4" >
                        <div className="flex gap-1 cursor-default text-xs items-center text-white flex-shrink-0" >
                            <IoPlay size={14} />
                            <span className="text-slate-400">{userSong?.views}</span>
                        </div>
                        <div className="flex gap-1 cursor-default text-xs items-center">
                            <IoHeart size={14} />
                            <span className="text-slate-400">{userSong?.likes}</span>
                        </div>
                        <div className="cursor-default" >
                            {userSong?.publish === true ? <MdPublic size={14} /> : <MdOutlinePublicOff size={14} />}
                        </div>
                    </div>
                </div>
                <div className=" hidden sm:flex items-center justify-end gap-2 lg:gap-4 text-xs lg:text-sm" >
                    {author && (song?.prompt?.type === "create") && <button onClick={() => handleRecreate(song)} type="button" className=" hidden h-8 xl:flex items-center gap-1 text-violet-400 hover:bg-violet-400/10 font-semibold border border-violet-400/50 px-2 py-2 rounded "  > <HiOutlineArrowPath size={18} /> Recreate</button>}
                    <button onClick={(e) => {
                        e.stopPropagation()
                        handleShare(song)
                    }} type="button" className=" h-8 hidden xl:flex items-center gap-1 text-amber-300 hover:bg-teal-400/10 font-semibold border border-amber-300/50 px-2 py-2 rounded "  > <IoIosShareAlt size={18} /></button>
                    <button disabled={downloadLoading} onClick={() => downloadSong(song)} type="button" className=" disabled:animate-pulse  h-8 hidden xl:flex items-center gap-1 text-cyan-400 hover:bg-cyan-400/10 font-semibold border border-cyan-400/50 px-2 py-2 rounded "  > <TbDownload size={18} /></button>
                    <button  onClick={handleAddToPlaylist} type="button" className=" disabled:animate-pulse  h-8 hidden xl:flex items-center gap-1 text-cyan-400 hover:bg-cyan-400/10 font-semibold border border-cyan-400/50 px-2 py-2 rounded "  >  <AiTwotoneFolderAdd size={18} /></button>
                    <DropdownMenu >
                        <DropdownMenuTrigger asChild>
                            <button className="p-1 flex justify-end xl:hidden" > <PiDotsThreeVerticalBold size={22} /> </button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent className="bg-slate-900 w-auto border-slate-700 text-slate-200 mr-4 z-[999] relative">
                            <DropdownMenuGroup>
                                {/* <DropdownMenuItem onClick={handleExtend} className="cursor-pointer p-1.5 focus:text-black text-green-400 focus:bg-green-400 flex items-center gap-2 focus:outline-none rounded">
                                    <BsArrowsExpandVertical />
                                    <span>Extend</span>
                                </DropdownMenuItem>
                                <DropdownMenuItem onClick={handleClone} className="cursor-pointer p-1.5 focus:text-black text-cyan-400 focus:bg-cyan-400 flex items-center gap-2 focus:outline-none rounded">
                                    <GiMusicalNotes />
                                    <span>Clone</span>
                                </DropdownMenuItem> */}
                                <DropdownMenuItem onClick={handleAddToPlaylist} className="cursor-pointer p-1.5 focus:text-black text-cyan-400 focus:bg-cyan-400 flex items-center gap-2 focus:outline-none rounded">
                                <AiTwotoneFolderAdd />
                                <span>Add to Playlist</span>
                                 </DropdownMenuItem>
                                {author && song?.prompt !== null && song?.prompt?.type !== null && song?.prompt?.type === "create" &&
                                    <DropdownMenuItem onClick={(e) => {
                                        e.stopPropagation()
                                        handleRecreate(song)
                                    }} className="cursor-pointer p-1.5 focus:text-black text-violet-400 focus:bg-violet-400 flex items-center gap-2 focus:outline-none rounded">
                                        <HiOutlineArrowPath />
                                        <span>Recreate</span>
                                    </DropdownMenuItem>
                                }
                                <DropdownMenuItem onClick={() => downloadSong(song)} className=" cursor-pointer p-1.5 focus:text-black text-cyan-400 focus:bg-cyan-400 flex items-center gap-2 focus:outline-none rounded">
                                    <TbDownload />
                                    <span>Downloadd</span>
                                </DropdownMenuItem>
                                <DropdownMenuItem onClick={(e) => {
                                    e.stopPropagation()
                                    handleShare(song)
                                }} className=" cursor-pointer p-1.5 focus:text-black text-amber-400 focus:bg-amber-400 flex items-center gap-2 focus:outline-none rounded">
                                    <IoIosShareAlt />
                                    <span>Share</span>
                                </DropdownMenuItem>
                            </DropdownMenuGroup>
                        </DropdownMenuContent>
                    </DropdownMenu>
                </div>
            </div>
            <AddToPlaylistDialog
                    isOpen={isAddToPlaylistOpen}
                    onOpenChange={setIsAddToPlaylistOpen}
                    song={song}
                />
        </div>
    )
}

export default SongCardCreatePage

export const SongCardCreatePageSkeleton = () => {
    return (
        <div className="w-full flex items-center gap-2 animate-pulse py-1 px-4" >
            <div className="w-full max-w-[80px] aspect-square rounded bg-slate-800" ></div>
            <div className="flex items-center gap-4 flex-grow h-full" >
                <div className="flex flex-col justify-between gap-2 h-full flex-grow" >
                    <div className="h-4 rounded-full mt-1 max-w-sm w-full bg-slate-800" ></div>
                    <div className="flex items-center gap-2" >
                        <div className="w-10 bg-slate-800 rounded-full h-3" ></div>
                        <div className="w-10 bg-slate-800 rounded-full h-3" ></div>
                        <div className="w-10 bg-slate-800 rounded-full h-3" ></div>
                    </div>
                    <div className="flex items-center gap-2 mb-1" >
                        <div className="w-10 bg-slate-800 rounded-full h-3" ></div>
                        <div className="w-10 bg-slate-800 rounded-full h-3" ></div>
                        <div className="w-3 bg-slate-800 rounded-full h-3" ></div>
                        <div className="w-3 bg-slate-800 rounded-full h-3" ></div>
                    </div>
                </div>
                <div className="flex items-center justify-end gap-2 lg:gap-4 flex-grow" >
                    <div className=" hidden xl:block h-7 w-20 bg-slate-800 rounded" ></div>
                    <div className=" hidden xl:block h-7 w-9 bg-slate-800 rounded" ></div>
                    <div className=" hidden xl:block h-7 w-9 bg-slate-800 rounded" ></div>
                    <div className=" xl:hidden h-7 opacity-0 rounded text-slate-800" ><PiDotsThreeVerticalBold size={28} /> </div>
                </div>
            </div>
        </div>
    )
}