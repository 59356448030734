import { Link, useLocation, useNavigate } from "react-router-dom"
import { HiMiniHome } from "react-icons/hi2";
import { PiMusicNotesPlusFill } from "react-icons/pi";
import { TbMusicHeart } from "react-icons/tb";
import { MdOutlineLibraryMusic } from "react-icons/md";
import { Button } from "../ui/button";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { BiSolidPlaylist } from "react-icons/bi";

const BottomBar = () => {

    const navigate = useNavigate()
    const location = useLocation()
    const { user } = useSelector(state => state.auth)
    const handleClick = () => {
        if (user) {
            navigate("/create")
        } else {
            toast.error("Login to create a song", { id: 'login' })
            navigate("/login")
        }
    }

    return (
        <div className="fixed bottom-0 left-0 w-full bg-slate-900  z-[999] md:hidden rounded-t-xl" >
            <div className="grid grid-cols-3 align-middle h-16 px-4" >
                <div className="flex items-center justify-between" >
                    <Link to={"/"} ><HiMiniHome className={location.pathname === "/" ? " text-green-400 " : " text-slate-100 "} size={32} /></Link>
                    <Link to={"/liked"} ><TbMusicHeart className={"mr-3 xs:mr-5 sm:mr-8 " + (location.pathname === "/liked" ? " text-green-400 " : " text-slate-100 ")} size={32} /></Link>
                </div>
                <div className="flex items-center justify-center" >
                    <Button onClick={handleClick} className='focus:outline-none ' >
                        <PiMusicNotesPlusFill className="text-black bg-green-400 p-1 rounded-full animate-glow-2" size={48} />
                    </Button>
                </div>
                <div className={"flex items-center justify-between "} >
                    <Link to={"/playlist"} ><BiSolidPlaylist className={"ml-3 xs:ml-5 sm:ml-8 " + (location.pathname === "/playlist" ? " text-green-400 " : " text-slate-100 ")} size={32} /></Link>
                    <Link to={"/creations"} ><MdOutlineLibraryMusic className={location.pathname === "/creations" ? " text-green-400 " : " text-slate-100 "} size={32} /></Link>
                </div>
            </div>
        </div>
    )
}

export default BottomBar