import { useNavigate } from "react-router-dom";
import { Button } from "../ui/button";
import { Card, CardContent, CardDescription, CardFooter, CardHeader } from "../ui/card";
import { TabsContent } from "../ui/tabs";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { v4 as uuidv4 } from 'uuid';
import { APPEND_SONGS } from "@/redux/features/music/musicSlice";
import axios from "axios";
import ani1 from "../../assets/createani1.gif"
import ani2 from "../../assets/createani2.gif"
import ani3 from "../../assets/createani3.gif"
import { Input } from "../ui/input";
import { SET_CREDITS } from "@/redux/features/auth/authSlice";
import { ADD_CREATING_SONG, REMOVE_CREATING_SONG, SET_CUSTOMDESC, SET_CUSTOMLYRICS, SET_CUSTOMTITLE, STOP_LIVESONG, UPDATE_CREATING_SONG } from "@/redux/features/music/createSlice";
import { analytics } from "@/firebase";
import { logEvent } from "firebase/analytics";

const CustomCreateSongComp = () => {

    const genreTags = [
        'Pop', 'Rock', 'Jazz', 'Blues', 'Classical', 'Country', 'Electronic', 'Dance',
        'Hip Hop', 'Rap', 'R&B', 'Soul', 'Reggae', 'Folk', 'Metal', 'Punk', 'Funk',
        'Disco', 'House', 'Techno', 'Trance', 'Dubstep', 'Drum and Bass', 'Ambient',
        'Indie', 'Gospel', 'Opera', 'Ska', 'Grunge', 'Swing', 'Bluegrass', 'Latin',
        'Flamenco', 'Celtic', 'World', 'New Age', 'EDM', 'Lo-Fi', 'Alternative',
        'K-Pop', 'J-Pop', 'C-Pop', 'Afrobeat', 'Bollywood', 'Heavy Metal', 'Thrash Metal',
        'Black Metal', 'Glitch Hop', 'Trap', 'Grime'
    ];

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [selectedTags, setSelectedTags] = useState([]);
    // creating ref to use in change status function
    const creatinRef = useRef({})
    const { user } = useSelector(state => state.auth)
    const { customDesc, customLyrics, customTitle } = useSelector(state => state.create)
    const [toPublish, setToPublish] = useState(true)
    const pollIntervalsRef = useRef({});
    const [isLoading, setIsLoading] = useState(false)


    const updateSelectedTagsFromDescription = useCallback((description) => {
        const tags = description?.split(',').map(tag => tag.trim());
        const validTags = tags?.filter(tag => genreTags.includes(tag));
        setSelectedTags(validTags);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        updateSelectedTagsFromDescription(customDesc);
    }, [customDesc, updateSelectedTagsFromDescription]);

    // handle toggle tags
    const handleToggleTag = (tag) => {
        let newDesc;
        const parts = customDesc.split(',').map(part => part.trim()).filter(part => part !== '');
        if (selectedTags.includes(tag)) {
            newDesc = parts.filter(part => part !== tag).join(', ');
        } else {
            if (selectedTags.length >= 5) {
                return toast.error("Only 5 tags allowed", { id: "warningTag" });
            }
            if (parts.includes(tag)) {
                return; // Tag already exists, do nothing
            }
            newDesc = [...parts, tag].join(', ');
        }
        if (newDesc.length > 500) {
            return toast.error("Max 500 characters allowed", { id: "chars" });
        }
        dispatch(SET_CUSTOMDESC(newDesc));
        // selectedTags will be updated by the useEffect
    };

    const handleChange = (event) => {
        const newValue = event.target.value;
        dispatch(SET_CUSTOMDESC(newValue));
    };

    // Custom Create reequest
    const handleCustomCreate = async () => {

        if (!customTitle || customTitle.length === 0) {
            return toast.error("Please enter song title", { id: "create" })
        }
        if (!customLyrics || customLyrics.length === 0) {
            return toast.error("Please enter song lyrics", { id: "create" })
        }
        if (!customDesc || customDesc.length === 0 || customDesc === ",") {
            return toast.error("Please enter song description", { id: "create" })
        }

        const formData = {
            title: customTitle,
            prompt: customLyrics,
            genre: customDesc,
            publish: toPublish,
        }
        const creationId = uuidv4();
        try {
            setIsLoading(true)
            toast.loading("Initializing", { id: "credits" })
            const token = localStorage.getItem('token')
            const { data } = await axios.get("/balance", { headers: { Authorization: `Bearer ${token}` } })
            dispatch(SET_CREDITS(data?.amount))
            if (data?.amount < 150) {
                setIsLoading(false)
                return toast.error("Not enough credits", { id: "credits" })
            } else {
                toast.dismiss("credits")
            }
            toast.loading("Sending Request", { id: creationId })
            const response = await axios.post("https://engine.soundofmeme.com/custom",
                formData,
                { headers: { Authorization: `Bearer ${token}` } })

            const songIds = response.data.songs
            const songIdsArr = songIds?.split(',')
            const songsArray = songIdsArr.map(id => ({ song_id: parseInt(id) }))
            creatinRef.current[creationId] = true
            dispatch(ADD_CREATING_SONG({ creationId: creationId, feat: "create", songs: songsArray }))
            changeStatus(creationId)
            startPollingForSongCompletion(songIds, creationId)
            setIsLoading(false)
            dispatch(SET_CUSTOMDESC(''))
            dispatch(SET_CUSTOMTITLE(''))
            dispatch(SET_CUSTOMLYRICS(''))
            if (window.innerWidth < 640 || user?.plan === 0) {
                navigate("/creations")
            }
        } catch (error) {
            setIsLoading(false)
            handleError(error, creationId)
        }
    }

    // Polling method for song completion
    const startPollingForSongCompletion = (songIds, creationId) => {
        const interval = setInterval(async () => {
            try {
                const token = localStorage.getItem('token');
                const { data } = await axios.get(`/songs/?song_ids=${songIds}`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                if (data.status === 'completed') {
                    clearInterval(interval);
                    delete pollIntervalsRef.current[songIds];
                    dispatch(REMOVE_CREATING_SONG({ creationId }));
                    dispatch(APPEND_SONGS(data.songs));
                    dispatch(STOP_LIVESONG())
                    toast.success(`All done! Your song is ready to play.`, { id: creationId });
                    delete creatinRef.current[creationId];
                    logEvent(analytics, 'song_creation', {
                        success: true,
                        songId: songIds,
                        method: "custom",
                    });
                } else {
                    dispatch(UPDATE_CREATING_SONG({ creationId: creationId, songs: data.songs }))
                }
            } catch (error) {
                clearInterval(interval)
                dispatch(STOP_LIVESONG())
                delete pollIntervalsRef.current[songIds];
                handleError(error, creationId);
                logEvent(analytics, 'song_creation', {
                    success: false,
                    songId: songIds,
                    method: "custom",
                });
            }
        }, 10000);
        // Store interval ID for cleanup
        pollIntervalsRef.current[songIds] = interval;
    };

    // Handle errors
    const handleError = (error, creationId) => {
        console.error(error);
        dispatch(REMOVE_CREATING_SONG({ creationId }));
        delete creatinRef.current[creationId];
        toast.error(error?.response?.data?.detail || error.message || "Oops! Something went wrong. Please try again.", { id: creationId });
    };

    // Change status function
    const changeStatus = useCallback((creationId) => {
        toast("Crafting your tune, hang tight!", { id: creationId })
        dispatch(UPDATE_CREATING_SONG({ creationId: creationId, ani: ani1, text: "Analyzing your description..." }))
        setTimeout(() => {
            if (!creatinRef.current[creationId]) return toast.dismiss(creationId);
            dispatch(UPDATE_CREATING_SONG({ creationId: creationId, ani: ani2, text: "Adding the rhythms..." }))
        }, 15000);
        setTimeout(() => {
            if (!creatinRef.current[creationId]) return toast.dismiss(creationId);
            dispatch(UPDATE_CREATING_SONG({ creationId: creationId, ani: ani3, text: "Composing your melody..." }))
        }, 30000);
    }, [dispatch])

    const handleTitleChange = (e) => {
        dispatch(SET_CUSTOMTITLE(e.target.value))
    }
    const handleLyricsChange = (e) => {
        dispatch(SET_CUSTOMLYRICS(e.target.value))
    }

    return (
        <TabsContent value="custom">
            <Card className="bg-transparent border-0 " >
                <div className="flex justify-between items-center text-slate-400 mb-1 text-sm" >
                    <p className="font-semibold" >Credits: <span>{user?.credits}</span></p>
                    <p className="font-semibold" >Cost: <span>150</span></p>
                </div>
                <CardHeader className="p-0 my-2" >
                    <CardDescription className='text-slate-400 text-sm font-medium' >
                        Share your title, lyrics, and choose a genre to shape your song&apos;s vibe.
                    </CardDescription>
                </CardHeader>
                <CardContent className="text-slate-100 flex flex-col gap-4 p-0 mt-4">
                    <div>
                        <div className=" px-1 flex items-center gap-4 mb-2 justify-between text-xs font-medium text-slate-300" >
                            <p>Title</p>
                            <p>{customTitle?.length}/80</p>
                        </div>
                        <Input maxLength={80} value={customTitle} onChange={handleTitleChange} id="title" type="text" className="bg-slate-900 border border-slate-700/75 placeholder:text-slate-500 w-full focus-visible:ring-1 ring-offset-green-400/75 focus:outline-none " />
                    </div>
                    <div className="">
                        <div className=" px-1 flex items-center gap-4 mb-2 justify-between text-xs font-medium text-slate-300" >
                            <p>Lyrics</p>
                            <p>{customLyrics?.length}/3000</p>
                        </div>
                        <textarea maxLength={3000} value={customLyrics} onChange={handleLyricsChange} id="lyrics" className="bg-slate-900 rounded-md min-h-48 placeholder:text-sm w-full placeholder:text-slate-500 p-2 border border-slate-700/75 focus:ring-1 ring-green-400 focus:outline-none" ></textarea>
                    </div>
                    <div>
                        <div className=" px-1 flex items-center gap-4 mb-2 justify-between text-xs font-medium text-slate-300" >
                            <p>Genre</p>
                            <p>{customDesc?.length}/120</p>
                        </div>
                        <textarea
                            id="description"
                            type="text"
                            value={customDesc}
                            maxLength={120}
                            onChange={handleChange}
                            className="bg-slate-900 border border-slate-700/75 w-full p-2 rounded-md placeholder:text-sm placeholder:text-slate-500 focus:outline-none focus:ring-1 ring-green-400"
                        ></textarea>
                    </div>
                    <div className="flex items-center  gap-2 overflow-x-auto whitespace-nowrap w-full" >
                        {genreTags.map(tag => (
                            <button className={"text-xs py-0.5 px-1 rounded-md mb-2 w-full " + (selectedTags?.includes(tag) ? " bg-green-400 text-black " : " bg-slate-700 ")} key={tag} onClick={() => handleToggleTag(tag)}>
                                {tag}
                            </button>
                        ))}
                    </div>
                    <label className="switch flex items-center justify-between border-b  pb-1 border-slate-700 cursor-pointer ">
                        <p className="font-semibold text-sm" >Publish</p>
                        <input checked={toPublish} onChange={(e) => setToPublish(e.target.checked)} type="checkbox" />
                        <span className="slider"></span>
                    </label>
                </CardContent>
                <CardFooter className="p-0 mt-6" >
                    <Button disabled={isLoading} onClick={handleCustomCreate} className='disabled:opacity-50  w-full bg-green-400 text-black hover:bg-green-300 font-semibold' >Create Song</Button>
                </CardFooter>
            </Card>
        </TabsContent>
    )
}

export default CustomCreateSongComp