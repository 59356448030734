import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import authService from './authService';
import toast from 'react-hot-toast';
import { logEvent } from 'firebase/analytics';
import { analytics } from '@/firebase';

const initialState = {
    authLoading: false,
    isLoggedIn: false,
    authDialog: false,
    otpCard: false,
    user: null,
}

export const loginUser = createAsyncThunk("auth/login",
    async (formData, thunkAPI) => {
        try {
            return await authService.loginUser(formData)
        } catch (error) {
            console.log(error);
            const message = error?.response?.data?.detail || "Error logging in";
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const signUpUser = createAsyncThunk("auth/signup",
    async (formData, thunkAPI) => {
        try {
            const response = await authService.signUpUser(formData)
            logEvent(analytics, 'sign_up', {
                method: 'email',
                email: formData.email
            });
            return response
        } catch (error) {
            console.log(error);
            const message = error?.response?.data?.detail || "Error Creating Account";
            console.log(message);
            return thunkAPI.rejectWithValue(message)
        }
    }
)
export const verifyOtp = createAsyncThunk("auth/verify",
    async (formData, thunkAPI) => {
        try {
            return await authService.verifyOtp(formData)
        } catch (error) {
            console.log(error);
            const message = error?.response?.data?.detail || "Error Verifying OTP";
            return thunkAPI.rejectWithValue(message)
        }
    }
)
export const getUserDetails = createAsyncThunk("auth/getUser",
    async (_, thunkAPI) => {
        try {
            return await authService.getUserDetails()
        } catch (error) {
            console.log(error);
            const message = error?.response?.data?.detail || "Error getting user details";
            return thunkAPI.rejectWithValue(message)
        }
    }
)

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        SET_ISLOADING(state, action) {
            state.isLoading = action.payload
        },
        SET_LOGIN(state, action) {
            state.isLoggedIn = action.payload
        },
        SET_AUTH_DIALOG(state, action) {
            state.authDialog = action.payload
        },
        SET_USER(state, action) {
            state.user = action.payload
        },
        SET_OTPCARD(state, action) {
            state.otpCard = action.payload
        },
        SET_CREDITS(state, action) {
            state.user.credits = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            // login user cases
            .addCase(loginUser.pending, (state) => {
                state.authDialog = true
                toast.loading("Logging In", { id: "login" })
            })
            .addCase(loginUser.fulfilled, (state, action) => {
                if (action.payload?.access_token) {
                    state.authLoading = false
                    state.isLoggedIn = true
                    state.authDialog = false
                    localStorage.setItem('token', action.payload?.access_token)
                    toast.success("Logged In", { id: "login" })
                } else if (action.payload?.session_token) {
                    state.authLoading = false
                    state.otpCard = true
                    sessionStorage.setItem('otpToken', action.payload?.session_token)
                    toast.success("OTP Sent to mail", { id: "login" })
                }
            })
            .addCase(loginUser.rejected, (state, action) => {
                state.authLoading = false
                toast.error(action.payload, { id: "login" })
            })
            // signup user cases
            .addCase(signUpUser.pending, (state) => {
                state.authDialog = true
                toast.loading("Sending Verification OTP...", { id: "signup" })
            })
            .addCase(signUpUser.fulfilled, (state, action) => {
                const { session_token } = action.payload;
                state.authLoading = false
                state.otpCard = true
                sessionStorage.setItem('otpToken', session_token)
                toast.success("OTP Sent to mail", { id: "signup" })
            })
            .addCase(signUpUser.rejected, (state, action) => {
                state.authLoading = false;
                state.isLoggedIn = false;
                toast.error(action.payload, { id: "signup" })
            })
            // verify OTP cases
            .addCase(verifyOtp.pending, (state) => {
                state.authDialog = true
                toast.loading("Verifying OTP...", { id: "verify" })
            })
            .addCase(verifyOtp.fulfilled, (state, action) => {
                const { access_token } = action.payload;
                state.authLoading = false
                state.isLoggedIn = true
                state.authDialog = false
                state.otpCard = false
                sessionStorage.removeItem('otpToken')
                localStorage.setItem('token', access_token)
                toast.success("Account Verified", { id: "verify" })
            })
            .addCase(verifyOtp.rejected, (state, action) => {
                state.authLoading = false;
                state.isLoggedIn = false;
                toast.error(action.payload, { id: "verify" })
            })
            // get user details cases
            .addCase(getUserDetails.pending, () => {
                // toast.loading("Logging In", { id: "login" })
            })
            .addCase(getUserDetails.fulfilled, (state, action) => {
                state.user = action.payload
                state.isLoggedIn = true
            })
            .addCase(getUserDetails.rejected, (state) => {
                state.isLoggedIn = false;
                state.user = null
            })
    }
});

export const {
    SET_LOGIN,
    SET_AUTH_DIALOG,
    SET_USER,
    SET_ISLOADING,
    SET_OTPCARD,
    SET_CREDITS
} = authSlice.actions

export default authSlice.reducer