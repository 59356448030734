import {
    Tabs,
    TabsList,
    TabsTrigger,
} from "@/components/ui/tabs"
import CreateSongComp from "./CreateSongComp"
import CustomCreateSongComp from "./CustomCreateSongComp"
import CloneSongComp from "./CloneSongComp"
import { useDispatch, useSelector } from "react-redux"
import ExtendSongComp from "./ExtendSongComp"
import { useEffect, useState } from "react"
import { SET_ALLUSERSONGS } from "@/redux/features/music/musicSlice"
import axios from "axios"
import toast from "react-hot-toast"
import CreateSongFromImg from "./CreateSongFromImg"
import CreateSongFromCSV from "./CreateSongFromCSV"

// const allowedEmails = ["jacksonjezio@gmail.com", "Jacksonjezio@gmail.com", "shah@123.com", "advaidv@gmail.com"]; // Use an array for allowed emails

const CreateSong = () => {

    const dispatch = useDispatch()
    const { user } = useSelector(state => state.auth)
    const { selectedTab, createDesc, customTitle, customLyrics, extendSong, cloneSong } = useSelector(state => state.create)
    const [isLoading, setIsLoading] = useState(false)
    // const [showCsv, setShowCsv] = useState(false)

    // get all user songs
    useEffect(() => {
        async function getUserSongs() {
            const token = window.localStorage.getItem('token')
            try {
                setIsLoading(true)
                const { data } = await axios.get("/cloneusersongs", { headers: { Authorization: `Bearer ${token}` } })
                dispatch(SET_ALLUSERSONGS(data?.songs))
                setIsLoading(false)
            } catch (error) {
                setIsLoading(false)
                console.log(error);
                if (error.response.status === 401) {
                    return toast.error("Unauthorized, Please Login")
                }
                toast.error("Cannot get user songs")
            }
        }
        if (user) {
            getUserSongs()
        }
    }, [dispatch, user])

    // useEffect(() => {
    //     if (!user?.email) {
    //         setShowCsv(false);
    //         return;
    //     }
    //     setShowCsv(allowedEmails.includes(user.email));
    // }, [user]);

    // prevent reload if there is something in input element
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (
                createDesc.trim() !== '' ||
                customTitle.trim() !== '' ||
                customLyrics.trim() !== '' ||
                extendSong || cloneSong
            ) {
                event.preventDefault();
                event.returnValue = ''; // Required for modern browsers
            }
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [createDesc, customLyrics, customTitle, extendSong, cloneSong]);

    return (
        <Tabs defaultValue={selectedTab} className="w-full overflow-hidden">
            <div className="grid grid-cols-1 gap-2" >
                <TabsList className="flex items-center justify-between bg-slate-900 border border-slate-700/75">
                    <div className="flex-grow border-e border-slate-600" ><TabsTrigger className="w-full data-[state=active]:bg-green-400 text-slate-400 font-semibold" value="create">Create</TabsTrigger></div>
                    <div className="flex-grow border-e border-slate-600" ><TabsTrigger className="w-full data-[state=active]:bg-green-400 text-slate-400 font-semibold" value="custom">Custom</TabsTrigger></div>
                    <div className="flex-grow  border-slate-600" ><TabsTrigger className="w-full data-[state=active]:bg-green-400 text-slate-400 font-semibold" value="image">Image</TabsTrigger></div>
                    {/* <div className="flex-grow border-e border-slate-600 hidden sm:block" ><TabsTrigger className=" w-full data-[state=active]:bg-green-400 text-slate-400 font-semibold" value="extend">Extend</TabsTrigger></div> */}
                    {/* <div className="flex-grow border-slate-600 hidden sm:block" ><TabsTrigger className=" w-full data-[state=active]:bg-green-400 text-slate-400 font-semibold" value="clone">Clone</TabsTrigger></div> */}
                    {/* {showCsv && <div className="flex-grow border-s border-slate-600 hidden sm:block" ><TabsTrigger className=" w-full data-[state=active]:bg-green-400 text-slate-400 font-semibold" value="csv">Upload CSV</TabsTrigger></div>} */}
                </TabsList>
                <TabsList className="  flex items-center justify-between bg-slate-900 border border-slate-700/75">
                    <div className="flex-grow border-e border-slate-600" ><TabsTrigger className=" w-full data-[state=active]:bg-green-400 text-slate-400 font-semibold" value="extend">Extend</TabsTrigger></div>
                    <div className="flex-grow border-slate-600" ><TabsTrigger className=" w-full data-[state=active]:bg-green-400 text-slate-400 font-semibold" value="clone">Clone</TabsTrigger></div>
                    {/* {showCsv && <div className="flex-grow border-s border-slate-600" ><TabsTrigger className=" w-full data-[state=active]:bg-green-400 text-slate-400 font-semibold" value="csv">Upload CSV</TabsTrigger></div>} */}
                </TabsList>
            </div>
            <CreateSongComp />
            <CustomCreateSongComp />
            <ExtendSongComp maintenance={true} isLoading={isLoading} />
            <CloneSongComp maintenance={true} isLoading={isLoading} />
            <CreateSongFromImg />
            <CreateSongFromCSV />
        </Tabs>
    )
}

export default CreateSong