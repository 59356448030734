import { SongCardSkeleton } from "@/components/createSong/CreateSongLoading"
import TopBar from "@/components/navbar/TopBar"
import BottomBar from "@/components/sidebar/BottomBar"
import SongCard from "@/components/songCard/SongCard"
import SongCardListView, { SongCardMyCreationSkeleton } from "@/components/songCard/SongCardListView"
import SortFilter from "@/components/sortFilter/SortFilter"
import { analytics } from "@/firebase"
import { ADD_DISCSONGS, SET_DISCSONGS } from "@/redux/features/music/musicSlice"
import { SET_SEARCHSONGS } from "@/redux/features/music/searchSlice"
import axios from "axios"
import { logEvent } from "firebase/analytics"
import { debounce } from "lodash"
import { useCallback, useEffect, useState } from "react"
import { FaListUl } from "react-icons/fa6"
import { HiViewGrid } from "react-icons/hi"
import InfiniteScroll from "react-infinite-scroll-component"
import { useDispatch, useSelector } from "react-redux"

const Discover = () => {

    const dispatch = useDispatch()
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const { song, discoverSongs } = useSelector(state => state.music)
    const { user } = useSelector(state => state.auth)
    const [noResultMsg, setNoResultMsg] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const { searchSongs } = useSelector(state => state.search)
    const [term, setTerm] = useState('')
    const [sort, setSort] = useState('mostrecent')
    const tabViewType = localStorage.getItem('tabViewDiscover')
    const [tabView, setTabView] = useState(tabViewType)

    useEffect(() => {
        const fetchSongs = async () => {
            const token = localStorage.getItem('token');
            try {
                if (page === 1) {
                    dispatch(SET_DISCSONGS([]))
                }
                const response = await axios.get(`/allsongs?page=${page}&sort=${sort}`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                // setSongs(prevSongs => [...prevSongs, ...response.data.songs]);
                dispatch(ADD_DISCSONGS(response.data.songs))
                if (response.data.songs.length === 0 || response.data.songs.length < 10) {
                    setHasMore(false); // No more songs to load
                }
            } catch (error) {
                console.log('Error fetching songs:', error);
            }
        };
        fetchSongs();
    }, [page, dispatch, sort]);

    // Define the debounced function directly inside useEffect
    useEffect(() => {
        const debouncedSearch = debounce(async () => {
            if (!term) return;
            try {
                setIsLoading(true)
                const response = await axios.get(`/search?item=${term}`);
                dispatch(SET_SEARCHSONGS(response.data.songs))
                setIsLoading(false)
                if (response?.data?.songs?.length === 0) {
                    setNoResultMsg(true)
                } else {
                    setNoResultMsg(false)
                }
            } catch (error) {
                setIsLoading(false)
                console.error('Search failed:', error);
            }
        }, 300);
        debouncedSearch();
        return () => {
            debouncedSearch.cancel();
        };
    }, [term, dispatch]);

    const handleSortChange = (sort, page) => {
        setSort(sort)
        setPage(page)
    }

    const handleChange = async (e) => {
        setTerm(e.target.value)
    }

    useEffect(() => {
        if (term === "") {
            setNoResultMsg(false)
            dispatch(SET_SEARCHSONGS([]))
            setIsLoading(false)
        }
    }, [dispatch, term])

    // change view
    const handleViewChange = useCallback(() => {
        const view = localStorage.getItem('tabViewDiscover')
        if (view === 'list') {
            setTabView('grid')
            localStorage.setItem('tabViewDiscover', 'grid')
        } else {
            setTabView('list')
            localStorage.setItem('tabViewDiscover', 'list')
        }
    }, [])
    // Change Tab view
    useEffect(() => {
        if (localStorage.getItem('tabViewDiscover') === null) {
            setTabView('grid')
            localStorage.setItem('tabViewDiscover', 'grid')
        } else {
            setTabView(tabView)
        }

    }, [tabView])

    useEffect(() => {
        window.scrollTo(0, 0)
        logEvent(analytics, 'page_view', { page_title: 'Home Page' })
    }, [])

    return (
        <div className={`bg-gradient-to-br from-slate-950 to-slate-800 min-h-screen `} >
            <TopBar heading={"All Songs"} />
            <div className='pt-20 pb-2 flex flex-col sm:flex-row justify-between  gap-4 px-4' >
                <input type='text' placeholder='Search all songs' value={term}
                    onChange={handleChange}
                    className='flex-grow bg-transparent border border-slate-700 p-2 rounded-md lg:max-w-lg w-full focus:outline-none'
                />
                <div className="flex items-center gap-4 justify-between" >
                    <SortFilter setSort={handleSortChange} sortValue={sort} />
                    <button onClick={handleViewChange} className=' h-10 aspect-square flex items-center p-1 justify-center border rounded border-slate-700 hover:bg-slate-900 hover:text-green-400' type='button' >
                        {tabView === "grid" ? <FaListUl size={26} /> : <HiViewGrid size={26} />}
                    </button>
                </div>
            </div>
            {(!isLoading && term === "") ? <InfiniteScroll
                dataLength={discoverSongs.length}
                next={() => setPage(prevPage => prevPage + 1)}
                hasMore={hasMore}
                loader={<Loader tabView={tabView} />}
                endMessage={null}
                className={' flex-grow py-4 pt-3 w-full grid  ' + (song ? " pb-36 md:pb-28 " : " pb-24 ") + (tabView === "list" ? " grid-cols-1 gap-x-4 gap-y-3 " : " grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-x-4 gap-y-8 px-4 ")}
            >{discoverSongs?.map((song) => (
                tabView === "list" ?
                    <SongCardListView author={song?.username === user?.username ? true : false} extend={true} song={song} key={song?.song_id} playingFrom={"discoverSongs"} />
                    :
                    <SongCard song={song} key={song?.song_id} playingFrom={"discoverSongs"} />
            ))}
            </InfiniteScroll>
                : (!isLoading && !noResultMsg && searchSongs?.length > 0) ? <div className={'py-4 w-full grid ' + (tabView === "list" ? " grid-cols-1 gap-x-4 gap-y-3 " : " grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-x-4 gap-y-8 px-4 ") + (song ? " pb-36 md:pb-28 " : " pb-24 ")}>
                    {searchSongs?.map((song) => (
                        tabView === "list" ?
                            <SongCardListView author={song?.username === user?.username ? true : false} extend={true} song={song} key={song?.song_id} playingFrom={"userSongs"} />
                            :
                            <SongCard song={song} key={song?.song_id} playingFrom={"discoverSongs"} />))}
                </div> : (!isLoading && searchSongs.length === 0 && noResultMsg) ?
                    <p className='px-4' >No songs found. Try different search terms.</p> :
                    <div className={' flex-grow w-full grid ' + (tabView === "list" ? " grid-cols-1 gap-x-4 gap-y-3 py-4 " : " p-4 grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-x-4 gap-y-8 ") + (song ? " pb-36 md:pb-28 " : " pb-24 ")} >
                        <Loader tabView={tabView} />
                    </div>
            }
            <BottomBar />
        </div>
    )
}

export default Discover


function Loader({ tabView }) {
    return (
        tabView === "list" ? <>
            <SongCardMyCreationSkeleton />
            <SongCardMyCreationSkeleton />
            <SongCardMyCreationSkeleton />
            <SongCardMyCreationSkeleton />
            <SongCardMyCreationSkeleton />
            <SongCardMyCreationSkeleton />
            <SongCardMyCreationSkeleton />
            <SongCardMyCreationSkeleton />
            <SongCardMyCreationSkeleton />
            <SongCardMyCreationSkeleton />
        </> : <>
            <SongCardSkeleton />
            <SongCardSkeleton />
            <SongCardSkeleton />
            <SongCardSkeleton />
            <SongCardSkeleton />
            <SongCardSkeleton />
            <SongCardSkeleton />
            <SongCardSkeleton />
            <SongCardSkeleton />
            <SongCardSkeleton />
        </>
    )
}