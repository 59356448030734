import { useState, useEffect, useRef } from 'react';
import Lottie from 'lottie-react';
import {  useSelector } from 'react-redux';
import barsAni from "../../assets/soundbars.json"
import playlistImgCover from '../../assets/meme-1.png';



const SimpleAudioVisualizer = ({ isPlaying, coverImageUrl }) => {
  const [bars, setBars] = useState(Array(48).fill(0));
  const [activeBarIndex, setActiveBarIndex] = useState(-1);
  const animateRef = useRef();

  const { playing } = useSelector(state => state.music);


  useEffect(() => {
    if (playing && animateRef.current) {
      animateRef.current.play();
      animateRef.current.setSpeed(1.8);
    } else if (animateRef.current) {
      animateRef.current.pause();
    }
  }, [playing]);

  useEffect(() => {
    let interval;

    if (isPlaying) {
      interval = setInterval(() => {
        setBars(prevBars =>
          prevBars.map((_, index) => {
            const position = index / prevBars.length;
            const wave = Math.sin(position * Math.PI * 4 + Date.now() / 500);
            const wave2 = Math.cos(position * Math.PI * 2 + Date.now() / 750);
            const baseHeight = Math.random() * 100;
            const combinedWave = (wave * 0.6 + wave2 * 0.4) * 30;
            const finalHeight = Math.min(100, Math.max(5, baseHeight + combinedWave));

            return finalHeight * (index === activeBarIndex ? 1.2 : 1);
          })
        );
      }, 30);
    } else {
      interval = setInterval(() => {
        setBars(prevBars => prevBars.map(height => Math.max(0, height * 0.95)));
      }, 30);
    }

    return () => clearInterval(interval);
  }, [isPlaying, activeBarIndex]);

  return (
    <div className={"relative w-full h-full overflow-hidden bg-slate-900"}>
      {/* Background Image */}
      <div className={`absolute inset-0 transition-transform duration-1000 ${isPlaying ? 'scale-110' : 'scale-100'}`}>
        <img
          src={coverImageUrl || playlistImgCover}
          alt="Album Cover"
          className={`w-full h-full object-cover opacity-80 transition-transform duration-1000 ${isPlaying ? 'animate-slow-spin' : ''
            }`}
        />
        <div className="absolute inset-0 bg-gradient-to-b from-black/40 via-black/60 to-black/80 backdrop-blur-sm" />
      </div>

      {/* Visualizer Bars */}
      <div className="absolute bottom-0 left-0 right-0 h-96 perspective-1000">
        <div className="relative w-full h-full flex items-end justify-center gap-0.5 px-4">
          {bars.map((height, index) => {
            const position = (index - bars.length / 2) / (bars.length / 2);
            const rotation = position * 25;

            return (
              <div
                key={index}
                className="group relative"
                style={{
                  width: `${100 / bars.length}%`,
                  maxWidth: '12px',
                  transform: `rotateY(${rotation}deg) translateZ(${Math.abs(position) * 30}px)`,
                  transformOrigin: 'bottom',
                }}
                onMouseEnter={() => setActiveBarIndex(index)}
                onMouseLeave={() => setActiveBarIndex(-1)}
              >
                <div
                  className={`w-full rounded-t-lg transition-all duration-100 ease-in-out ${isPlaying ? 'animate-bar-pulse' : ''
                    }`}
                  style={{
                    height: `${height}%`,
                    background: `linear-gradient(180deg, 
                      rgba(236, 72, 153, ${isPlaying ? 0.9 : 0.3}) 0%,
                      rgba(99, 102, 241, ${isPlaying ? 0.8 : 0.2}) 50%,
                      rgba(167, 139, 250, ${isPlaying ? 0.7 : 0.1}) 100%
                    )`,
                    boxShadow: isPlaying
                      ? '0 0 20px rgba(236, 72, 153, 0.3), 0 0 40px rgba(99, 102, 241, 0.2)'
                      : 'none',
                  }}
                >
                  <div className="absolute inset-0 bg-white opacity-0 transition-opacity duration-200 group-hover:opacity-20" />
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {/* Play/Pause Overlay with Lottie Animation */}
      {isPlaying && (
        <div className="w-full absolute bottom-0 left-0 ">
          <Lottie
            lottieRef={(instance) => {
              if (instance) {
                animateRef.current = instance;
              }
            }}
            animationData={barsAni}
            loop={true}
            autoplay={true}
          />
          {/* <Lottie
            lottieRef={(instance) => {
              if (instance) {
                animateRef.current = instance;
              }
            }}
              animationData={musicLoader}
              loop={true}
              autoplay={true}
          /> */}
        </div>
      )}
    </div>
  );
};

export default SimpleAudioVisualizer;