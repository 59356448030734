import { SET_BUFFERING, STOP_LIVESONG } from "@/redux/features/music/createSlice";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

const LiveSongPlayer = () => {

    const dispatch = useDispatch()
    const audioRef = useRef(null);
    const { liveSong } = useSelector(state => state.create)

    // Handle buffering state when the song is playing
    useEffect(() => {

        const audio = audioRef.current;

        const handleBuffering = () => {
            dispatch(SET_BUFFERING(true))
        };

        const handleCanPlay = () => {
            dispatch(SET_BUFFERING(false))
        };

        const handlePlaying = () => {
            dispatch(SET_BUFFERING(false))
        };

        if (audio) {
            audio.addEventListener('waiting', handleBuffering);
            audio.addEventListener('canplay', handleCanPlay);
            audio.addEventListener('playing', handlePlaying);
        }

        // Clean up event listeners on component unmount
        return () => {
            if (audio) {
                audio.removeEventListener('waiting', handleBuffering);
                audio.removeEventListener('canplay', handleCanPlay);
                audio.removeEventListener('playing', handlePlaying);
            }
        };
    }, [dispatch, liveSong]);

    useEffect(() => {
        const audio = audioRef.current;
        
        if (!liveSong?.streamUrl || !liveSong?.isPlaying) {
            audio.pause();
            audio.currentTime = 0;
            return;
        }

        const playAudio = async () => {
            try {
                dispatch(SET_BUFFERING(true));
                audio.src = liveSong.streamUrl;
                await audio.load();
                await audio.play();
            } catch (error) {
                console.error("Error playing audio:", error);
                dispatch(STOP_LIVESONG());
            }
        };

        playAudio();
    }, [liveSong?.streamUrl, liveSong?.isPlaying, dispatch]);

    return (
        <audio 
            ref={audioRef}
            preload="auto"
        >
            Your browser does not support the audio tag.
        </audio>
    )
}

export default LiveSongPlayer