import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import Select from "react-select";
import { toast } from "react-hot-toast";

const PlaylistSelector = ({
  label = "Select Playlist",
  onSelectPlaylist,
  isDisabled = false,
  className,
}) => {
  const [playlists, setPlaylists] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedPlaylist, setSelectedPlaylist] = useState(null);

  const fetchPlaylists = useCallback(async () => {
    if (loading) return;

    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const res = await axios.get(`/my-playlists`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const newPlaylists = res.data.playlists || [];
      setPlaylists((prev) => [...prev, ...newPlaylists]);
    } catch (error) {
      toast.error("Failed to load playlists.");
    } finally {
      setLoading(false);
    }
  }, [ loading]);

  useEffect(() => {
    fetchPlaylists();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const playlistOptions = playlists.map((playlist) => ({
    value: playlist.playlist_slug,
    label: playlist.name,
    playlist,
  }));

  const handlePlaylistSelect = (selectedOption) => {
    if (selectedOption) {
      setSelectedPlaylist(selectedOption.playlist);
      onSelectPlaylist(selectedOption.playlist);
    } else {
      setSelectedPlaylist(null);
      onSelectPlaylist(null);
    }
  };

  const customStyles = {
    control: (base) => ({
      ...base,
     
      background: "#1e293b", // slate-800
      borderColor: "#334155", // slate-700
      boxShadow: "none",
      "&:hover": {
        borderColor: "#334155", // slate-700
      },

    }),
    menu: (base) => ({
      ...base,
      background: "#1e293b", // slate-800
      border: "none",
      display:"flex",
      flexDirection:"column",
      justifyContent:"space-between",
      height:"190px",
      // width:"180px",
    }),
    option: (base, state) => ({
      ...base,
      background: state.isSelected ? "#4ade80" : state.isFocused ? "#334155" : "#1e293b", // green-400 : slate-700 : slate-800
      color: state.isSelected ? "#000" : "#e2e8f0", // black text for selected
      "&:hover": {
        background: "#334155", // slate-700
      },
    }),
    singleValue: (base) => ({
      ...base,
      color: "#e2e8f0", // slate-200
    }),
    input: (base) => ({
      ...base,
      color: "#e2e8f0", // slate-200
    }),
  };

  return (
    <div className={`w-full  ${className}`}>
      <Select
        inputId="playlist-select-menu"
        aria-label={label}
        options={playlistOptions}
        styles={customStyles}
        value={
          selectedPlaylist
            ? {
                value: selectedPlaylist.playlist_slug,
                label: selectedPlaylist.name,
                playlist: selectedPlaylist,
              }
            : null
        }
        onChange={handlePlaylistSelect}
        menuPlacement="auto"
        placeholder={
          selectedPlaylist
            ? `Selected playlist: ${selectedPlaylist.name}`
            : "Select playlist"
        }
        isLoading={loading}
        isDisabled={isDisabled}
        isClearable
        isSearchable
        className="text-slate-950 font-potta text-sm"
      />
    </div>
  );
};

export default PlaylistSelector;
