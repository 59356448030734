import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';
import { SET_VIEWSONG, SET_SONG, SET_PLAYING, SET_PLAYINGFROM, } from "@/redux/features/music/musicSlice";
import { REMOVE_DELETED_SONG, deletePlaylist, fetchPlaylists, fetchPlaylistSongs, removeSong, removePlaylist, UPDATE_PLAYLIST_DATA, UPDATE_PLAYLIST_NAME } from '@/redux/features/music/playlistSlice';
import TopBar from '@/components/navbar/TopBar';
import AddSongDialog from '../playlist/AddSongDialog';
import { MdOutlineDeleteSweep, MdOutlinePublic, MdOutlinePublicOff } from "react-icons/md";
import BottomBar from '@/components/sidebar/BottomBar';
import AudioVisualizer from '@/components/AudioVisualizer/AudioVisualizer';
import { IoHeart } from 'react-icons/io5';
import PlaylistViewLoader from './PlaylistViewLoader';
import { Button } from '@/components/ui/button';
import { Rings } from 'react-loader-spinner';
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger, } from "@/components/ui/dialog";
import { RiDeleteBin6Line } from 'react-icons/ri';
import toast from 'react-hot-toast';
import deleteImgCover from '../../assets/meme-1.png'
import { PiMusicNotesPlus } from "react-icons/pi";
import playlistImgCover from '../../assets/meme-1.png';
import { FaShareFromSquare } from 'react-icons/fa6';
import { MdDriveFileRenameOutline } from "react-icons/md";

// import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
// import LoginForm from '../login/LoginForm';
// import OtpForm from '../login/OtpForm';
// import SignUpForm from '../login/SignUpForm';
// import blueBird from "../../assets/blue bird.png";
// import greenBird from "../../assets/greenbird.png";
// import purpuleBird from "../../assets/purple-bird.png";
// import { SET_OTPCARD } from '@/redux/features/auth/authSlice';
import Login from '../login/Login';



const PlaylistView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector(state => state.auth);
  const { songs: playlistSongs, loading, error, playlistName, playlistCoverImage, playlistData, playlistOwner } = useSelector(state => state.playlists);
  const { playing, song: activeSong } = useSelector(state => state.music);
  const { playlistId } = useParams();
  const [dialogSongs, setDialogSongs] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogPage, setDialogPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [dialogLoading, setDialogLoading] = useState(false);
  const [currentSongId, setCurrentSongId] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  // const [playlistName, setPlaylistName] = useState('');
  // const [playlistCoverImage, setPlaylistCoverImage] = useState(null);



  // Pagination state for playlist songs
  const [page, setPage] = useState(1);
  const [localSongs, setLocalSongs] = useState([]);
  const [localHasMore, setLocalHasMore] = useState(true);
  const [isSharedView, setIsSharedView] = useState(false);
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [newPlaylistName, setNewPlaylistName] = useState('');




  useEffect(() => {
    const token = localStorage.getItem('token');
    setShowLoginForm(!user && !token);
    window.scrollTo(0, 0);
  }, [user]);


  useEffect(() => {
    // Reset shared view state when playlist changes
    if (playlistId) {
      // Check if the current URL indicates a shared playlist view
      // const currentPath = window.location.pathname;
      // const isSharedRoute = currentPath.includes('/shared/') || currentPath.includes('/playlistview/');

      // Determine shared view based on user and playlist owner
      const isUserOwnPlaylist = user?.email === playlistOwner;


      setIsSharedView(!isUserOwnPlaylist);
    }
  }, [playlistId, user, playlistOwner]);




  const handleDeletePlaylist = async () => {
    try {
      setDeleteLoading(true);
      await dispatch(deletePlaylist(playlistId)).unwrap();
      dispatch(removePlaylist(playlistId));

      // toast.success(`Playlist "${playlistName}" deleted successfully`);
      setIsDeleteDialogOpen(false);
      navigate('/playlist');
      setDeleteLoading(false);
    } catch (error) {
      toast.error(`Failed to delete playlist: ${error.message || 'Unknown error'}`);
      dispatch(fetchPlaylists(page));
    }
  };

  const fetchMoreLocalSongs = useCallback(
    async (currentPage) => {
      if (!localHasMore) return;

      try {
        const response = await dispatch(fetchPlaylistSongs({ playlistId, page: currentPage })).unwrap();


        const songs = response.playlistSongs || [];



        if (songs.length === 0 || songs.length < 20) {
          setLocalHasMore(false); // No more pages to load
        }

        // //This is for pagination to load more songs
        setLocalSongs((prevSongs) => {
          const uniqueNewSongs = songs.filter(
            (newSong) => !prevSongs.some((prevSong) => prevSong.song_id === newSong.song_id)
          );
          return [...prevSongs, ...uniqueNewSongs]; // Append new songs for pagination
        });


        setPage(currentPage);
        setLocalHasMore(songs.length === 20);
      } catch (error) {
        // console.error('Error fetching more playlist songs:', error);
        console.log('Error fetching more playlist songs:', error.detail);
        toast.error(`Failed to load playlist: ${playlistId}`);


        if (error == 'Request failed with status code 404') {
          navigate('/playlist');
        }

        setLocalHasMore(false); // Stop infinite scroll on error
      }


    },
    [dispatch, playlistId, localHasMore, navigate]
  );

  const handleMore = useCallback(() => {
    if (!localHasMore) return;
    fetchMoreLocalSongs(page + 1);
  }, [localHasMore, fetchMoreLocalSongs, page]);


  // global playlist songs changes are reflected in local playlist songs
  useEffect(() => {
    setLocalSongs((prevSongs) => {
      const newSongs = playlistSongs.filter(
        (globalSong) => !prevSongs.some((localSong) => localSong.song_id === globalSong.song_id)
      );
      return [...prevSongs, ...newSongs]; // Append to maintain order
    });
  }, [playlistSongs]);





  // Reset states when switching playlists
  useEffect(() => {
    setLocalSongs([]);
    setPage(1); // Reset page number
    setLocalHasMore(true); // Allow infinite scroll for new playlist
  }, [playlistId]);

  // Initial fetch of playlist songs
  useEffect(() => {
    fetchMoreLocalSongs(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playlistId]);



  const fetchMoreSongs = async () => {
    if (!hasMore || dialogLoading) return;
    setDialogLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`/allsongs?page=${dialogPage}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      const newSongs = response.data.songs;
      if (newSongs.length === 0 || newSongs.length < 20) {
        setHasMore(false);
      } else {
        setDialogPage(prev => prev + 1);
      }
      setDialogSongs(prevSongs => {
        const existingSongIds = prevSongs.map(song => song.song_id);
        const uniqueNewSongs = newSongs.filter(song => !existingSongIds.includes(song.song_id));
        return [...prevSongs, ...uniqueNewSongs];
      });
    } catch (error) {
      console.error('Error fetching more songs:', error);
    } finally {
      setDialogLoading(false);
    }
  };


  const handleSongClick = (song) => {
    dispatch(SET_SONG(song));
    dispatch(SET_PLAYING(true));
    dispatch(SET_PLAYINGFROM('playlists'));
    dispatch(SET_VIEWSONG(song));
    if (currentSongId === song.song_id && isPlaying) {
      setIsPlaying(false);
      dispatch(SET_PLAYING(false));
    } else {
      setCurrentSongId(song.song_id);
      setIsPlaying(true);
    }
  };

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
    fetchMoreSongs();
  };

  const handleCloseDialog = useCallback(() => {
    setIsDialogOpen(false);
  }, []);

  const removeSongFromPlaylist = async (songId, event) => {
    event.stopPropagation();
    try {
      const result = await dispatch(removeSong({ songId, playlistId }));

      // Explicitly update local songs state
      setLocalSongs(prevSongs =>
        prevSongs.filter(song => song.song_id !== songId)
      );

      if (result.payload?.status === 'Song removed') {
        dispatch(REMOVE_DELETED_SONG(songId));

      } else {
        dispatch(REMOVE_DELETED_SONG(songId));

      }
    } catch (error) {
      console.error('Removal error:', error);
      toast.error(`Failed to remove song: ${error.message || 'Unknown error'}`);
    }
  };


  //This is not completed yet
  const handleShare = async (playlist) => {
    if (!playlistData || !playlistData.publish) {
      toast.error('This playlist is not public and cannot be shared.');
      return;
    }

    // Create a simplified playlist view URL
    const url = `http://soundofmeme.com/playlistview/${playlist?.playlist_slug || playlistId}?page=${page}`; // Change this to the actual URL
    const title = `Playlist: ${playlistName}`;

    // Prepare playlist details for sharing
    const shareData = {
      title,
      text: `Check out this playlist: ${playlistName} with ${localSongs.length} songs`,
      url
    };



    if (navigator.share) {
      try {
        await navigator.share(shareData);

      } catch (error) {
        console.error('Error sharing:', error);
      }
    } else {
      // Fallback for browsers without native sharing
      navigator.clipboard.writeText(url).then(() => {
        toast.success('Playlist link copied to clipboard');
      }).catch((error) => {
        console.error('Failed to copy:', error);
        toast.error('Failed to copy playlist link');
      });
    }
  };


  //This is completed
  const handlePublic = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      toast.error("Authentication token is missing");
      return;
    }
    try {
      const newPublishStatus = !playlistData.publish;
      await axios.post(
        '/playlist/update-publish',
        {
          playlist_slug: playlistId,
          publish: newPublishStatus,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      // Update local Redux state immediately
      dispatch(UPDATE_PLAYLIST_DATA({
        ...playlistData,
        publish: newPublishStatus
      }));

      toast.success(
        newPublishStatus
          ? 'Playlist is now public'
          : 'Playlist is now private'
      );
    } catch (error) {
      console.error('Error during API call:', error.response?.data || error.message);
      toast.error('Failed to update playlist privacy');
    }
  };


  const handleOpenRenamePlaylist = () => {
    setNewPlaylistName(playlistName || '');
    setShowDialog(true);
  };

  const handleUpdatePlaylistName = async () => {
    if (!newPlaylistName) {
      toast.error('Playlist name cannot be empty');
      return;
    }
    try {
      const token = localStorage.getItem('token');
      await axios.post(
        '/playlist/update-name',
        {
          playlist_slug: playlistId,
          name: newPlaylistName, // Change key to `name`
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );


      // Update local Redux state immediately
      dispatch(UPDATE_PLAYLIST_NAME(newPlaylistName));

      toast.success('Playlist name updated successfully');
      setShowDialog(false);
    } catch (error) {
      console.error('Error during API call:', error.response?.data || error.message);
      toast.error('Failed to update playlist name');
    }
  };



  if (loading) return <div><PlaylistViewLoader /></div>;
  if (error) return <div className="flex items-center justify-center min-h-screen bg-slate-950"><div className="text-red-500">{error}</div></div>;

  return (
    <div className={'max-w-7xl 2xl:max-w-full min-h-screen  mx-auto bg-gradient-to-br from-slate-950 via-slate-900 to-slate-800 rounded-lg overflow-hidden shadow-lg'}>
      <TopBar
        heading={
          <>
            {playlistName}
            {!isSharedView && (
              <Dialog open={showDialog} onOpenChange={setShowDialog}>
                <DialogTrigger asChild>
                  <button onClick={handleOpenRenamePlaylist} className="text-green-400 hover:text-green-300 transition-colors pl-1 bottom-2 relative">
                    <MdDriveFileRenameOutline size={20} />
                  </button>
                </DialogTrigger>
                <DialogContent className="truncate  bg-gradient-to-r from-slate-950 via-slate-900 to-slate-950 text-slate-300 border-green-400 ">
                  <DialogHeader>
                    <div className='flex flex-col space-y-2 items-start'>
                      <DialogTitle>Add New Playlist</DialogTitle>
                      <DialogDescription className="text-slate-400 font-normal text-xs sm:text-md">
                        Fill in the details for your new playlist.
                      </DialogDescription>
                    </div>
                  </DialogHeader>
                  <div className="space-y-4">
                    <input
                      type="text"
                      placeholder="Playlist Name"
                      value={newPlaylistName || ''}
                      onChange={(e) => setNewPlaylistName(e.target.value)}
                      className="w-full p-2 rounded bg-slate-800 text-sm text-slate-200 placeholder-slate-400 focus:outline-none focus:ring-1 focus:ring-green-300"
                    />
                  </div>
                  <DialogFooter>
                    <div className='flex flex-row justify-end gap-2'>
                      <Button
                        onClick={handleUpdatePlaylistName}
                        className="bg-green-400 hover:bg-green-300 text-slate-700"
                      >
                        Update
                      </Button>
                      <DialogClose asChild>
                        <Button className="bg-slate-700 hover:bg-slate-800 text-slate-300">
                          Cancel
                        </Button>
                      </DialogClose>
                    </div>
                  </DialogFooter>
                </DialogContent>
              </Dialog>
            )}
          </>
        }
      />

      {showLoginForm ? (
        <Login />
      ) : (
        <div className={
          "flex flex-col lg:flex-row gap-4 min-h-[81vh]  pb-6 pt-24 " + (activeSong ? 'mb-12' : '')
        }>
          {/* Cover Image & Details */}
          <div className="lg:w-2/3 2xl:w-7/12 p-4 space-y-4">
            <div className={`2xl:h-[30rem] lg:h-96 h-52 ${activeSong ? 'h-52 lg:h-80' : ''}`}>
              <AudioVisualizer isPlaying={playing} coverImageUrl={activeSong?.image_url || playlistCoverImage || playlistImgCover} />
            </div>

            {activeSong ? (
              <div className="p-4 bg-slate-900/50 backdrop-blur-sm rounded-lg shadow-lg">
                <div className="flex flex-col space-y-3">
                  {/* Title and Views Section */}
                  <div className="flex flex-row  sm:items-center justify-between">
                    <h1 className="lg:text-2xl text-lg font-semibold font-potta text-slate-300 line-clamp-2">
                      {activeSong.song_name || 'No song selected'}
                    </h1>

                  </div>

                  {/* Artist and Tags Section */}
                  <div className="flex flex-col sm:flex-row sm:items-center justify-between border-t border-slate-950/50 pt-3">
                    <div className="flex items-center space-x-3">
                      <div className="lg:h-8 lg:w-8 h-6 w-6 rounded-full bg-slate-700 flex items-center justify-center">
                        <span className="text-slate-300 lg:font-medium font-normal">
                          {activeSong.username?.[0]?.toUpperCase() || 'A'}
                        </span>
                      </div>
                      <div>
                        <p className="text-slate-200 font-medium">
                          {activeSong.username || 'Unknown Artist'}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>

          {/* Playlist Sidebar */}
          <div className="truncate lg:min-w-[46%] 2xl:w-[35%] mx-[1rem] sm:mx-0 overflow-y-auto md:mb-36 sm:mb-44 lg:mb-0 mb-32 border-l border-slate-800 lg:p-4 md:p-4 p-0">
            <div className="flex flex-col items-start justify-between mb-4 mx-2 sm:mx-0 gap-3">
              <div className='flex flex-row justify-between items-center gap-3 w-full'>
                <div className='flex flex-row items-center gap-3 min-w-0'>
                  <h2 className="truncate flex-1 text-slate-200 font-semibold text-md sm:text-lg">{playlistName}</h2>
                  <button className='text-green-400 hover:text-green-300' onClick={() => handleShare(playlistData)}>
                    <FaShareFromSquare size={22} />
                  </button>
                </div>

                <div>
                  {!isSharedView && (
                    <button
                      onClick={handleOpenDialog}
                      className="py-[0.4rem] px-2 rounded-md flex items-center text-green-400 border border-green-300 hover:text-green-300 transition-colors"
                    >
                      <PiMusicNotesPlus size={18} />
                      <p className='text-sm'>Add Songs</p>
                    </button>
                  )}
                </div>
              </div>



              <div className='flex flex-row w-full justify-between'>
                <div className='flex flex-row gap-3'>
                  {!isSharedView && (
                    <div className="flex items-center space-x-2">
                      <span className="text-md text-slate-400">
                        {playlistData?.publish ? "Make Private:" : "Make Public:"}
                      </span>
                      <button
                        className="flex items-center justify-center  focus:outline-none"
                        onClick={handlePublic}
                      >
                        {playlistData?.publish ? (
                          <MdOutlinePublic className='text-green-400 hover:text-green-300' size={24} title="Make Private" />
                        ) : (
                          <MdOutlinePublicOff className='text-red-400 hover:text-red-300' size={24} title="Make Public" />
                        )}
                      </button>
                    </div>
                  )}

                  {!isSharedView && (
                    <Dialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
                      <DialogTrigger asChild>
                        <Button variant="ghost" size="icon" className="cursor-pointer  focus:text-red-500 hover:bg-transparent  text-red-400 hover:text-red-300 flex items-center focus:outline-none rounded">
                          <RiDeleteBin6Line size={24} />
                        </Button>
                      </DialogTrigger>
                      <DialogContent className="block max-w-sm z-[999] p-4 bg-slate-900 border-slate-700 rounded-lg ">
                        <DialogHeader>
                          <DialogTitle className="text-2xl text-left text-green-400 font-potta">Delete Playlist</DialogTitle>
                          <DialogDescription className="text-left text-slate-300 text-lg mt-1">
                            Are you sure you want to delete? This action cannot be undone.
                          </DialogDescription>
                          <div className="flex gap-2 items-center bg-slate-800 rounded-lg mt-3" >
                            <img className="w-8 rounded-lg" src={deleteImgCover} alt="cover" />
                            <p className="text-green-400 text-md font-normal font-potta " >{playlistName}</p>
                          </div>
                        </DialogHeader>
                        <DialogFooter className="flex flex-row gap-2 justify-end mt-3">
                          <Button
                            variant="outline"
                            onClick={() => setIsDeleteDialogOpen(false)}
                            className="bg-slate-800 hover:bg-slate-700 text-slate-300 hover:text-green-400 font-medium px-4 py-2 rounded-lg"
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="destructive"
                            onClick={handleDeletePlaylist}
                            className=" disabled:animate-pulse bg-slate-800 hover:bg-slate-700  hover:text-red-500 font-medium text-red-500 px-4 py-2 rounded-lg" >{deleteLoading ? "Deleting..." : "Delete"}
                          </Button>
                        </DialogFooter>
                      </DialogContent>
                    </Dialog>
                  )}
                </div>


                {/* {!isSharedView && (
                  <button onClick={handleOpenDialog} className="py-[0.6px] px-2 rounded-md truncate flex items-center  text-green-400 border border-green-300 hover:text-green-300 transition-colors">
                    <PiMusicNotesPlus size={18} /> <p className='text-sm'>Add Songs</p>
                  </button>
                )} */}
              </div>
            </div>

            <InfiniteScroll
              dataLength={localSongs.length}
              next={handleMore}
              hasMore={localHasMore}
              loader={
                <div className="">
                  <SongListLoader />
                </div>
              }
              height={400}
              className="overflow-y-auto space-y-2"
            >
              <div className="overflow-y-auto space-y-2 ">
                {localSongs && localSongs.length > 0 ? (
                  <div className="space-y-4 sm:space-y-5">
                    {localSongs.map((song, index) => (
                      song && (
                        <div
                          key={song.song_id || `song-${index}`}
                          onClick={() => handleSongClick(song)}
                          className={`
                        flex items-center gap-4 p-2 cursor-pointer 
                        hover:bg-slate-900/50 transition-colors 
                        rounded-lg 
                        ${activeSong?.song_id === song.song_id ? 'bg-slate-800' : ''}
                      `}
                        >
                          <div className="relative flex-shrink-0">
                            {activeSong?.song_id === song.song_id && isPlaying ? (
                              <div className="absolute inset-0 flex items-center justify-center">
                                <Rings visible={true} height="60" width="60" color="#10B981" ariaLabel="rings-loading" />
                              </div>
                            ) : (
                              <div className="absolute inset-0 flex items-center justify-center">
                                <Rings visible={false} height="60" width="60" color="#10B981" ariaLabel="rings-loading" />
                              </div>
                            )}
                            <img
                              src={song?.image_url || playlistImgCover}
                              alt={song.song_name}
                              className="w-16 h-10 lg:w-20 lg:h-12 object-cover rounded"
                            />
                          </div>

                          <div className="flex-1 min-w-0 mr-2">
                            <h3 className="text-xs lg:text-sm lg:font-medium font-normal text-slate-200 line-clamp-2">
                              {song.song_name}
                            </h3>
                            <div className="flex items-center space-x-2 mt-1">
                              <p className="text-xs text-slate-400">{song.username || 'Unknown Artist'}</p>
                              <div className="flex items-center space-x-1">
                                <IoHeart size={14} className="text-slate-400" />
                                <span className="text-sm text-slate-400">{song?.likes}</span>
                              </div>
                            </div>
                          </div>


                          {!isSharedView && (
                            <Button
                              onClick={(e) => {
                                e.stopPropagation();
                                removeSongFromPlaylist(song.song_id, e);
                              }}
                              className="flex-wrap text-red-400 hover:text-red-300 bg-transparent transition-colors"
                            >
                              <MdOutlineDeleteSweep size={20} />
                            </Button>
                          )}
                        </div>
                      )
                    ))}
                  </div>
                ) : (
                  <p className="text-slate-400">No songs available in this playlist.</p>
                )}
              </div>
            </InfiniteScroll>
          </div>
        </div>
      )}


      <AddSongDialog isOpen={isDialogOpen} onClose={handleCloseDialog} songs={dialogSongs} fetchMoreSongs={fetchMoreSongs} className={`pb-1 ${activeSong ? 'pb-24' : 'pb-0'}`} />
      <BottomBar />

    </div>
  );
};

// const Login = () => {

//   const dispatch = useDispatch()
//   const { song } = useSelector(state => state.music)
//   const { otpCard } = useSelector(state => state.auth)

//   useEffect(() => {
//       const otpToken = sessionStorage.getItem('otpToken')

//       if (otpToken) {
//           dispatch(SET_OTPCARD(true))
//       }
//   }, [dispatch])

//   useEffect(() => {
//       window.scroll(0, 0)
//   }, [])

//   return (
//       <div className='relative min-h-screen bg-gradient-to-br from-slate-950 via-slate-900 to-slate-800 overflow-hidden flex flex-col flex-grow w-full' >
//           <TopBar heading={"Get Started"} />
//           <div className={'md:pt-20 pt-20 flex-grow flex items-center justify-center relative z-50 ' + (song ? " md:pb-32 pb-36 " : " pb-40 sm:pb-20 ")} >
//               <Tabs defaultValue="login" className="w-full p-4 max-w-lg  ">
//                   <TabsList className="grid w-full grid-cols-2  text-slate-400 font-semibold bg-slate-900 border border-slate-700/75">
//                       <TabsTrigger className="data-[state=active]:bg-green-400" value="login">Login</TabsTrigger>
//                       <TabsTrigger className="data-[state=active]:bg-green-400 font-semibold" value="signup">SignUp</TabsTrigger>
//                   </TabsList>
//                   {!otpCard ? <LoginForm /> : <OtpForm  tabName={"login"} />}
//                   {!otpCard ? <SignUpForm /> : <OtpForm tabName={"signup"} />}
//               </Tabs>
//           </div>
//           <BottomBar />
//           <img src={blueBird} alt='bird' className='w-16 absolute top-[80%] right-[10%]' />
//           <img src={greenBird} alt='bird' className='w-16 absolute left-[5%] top-[85%] lg:top-1/2' />
//           <img src={purpuleBird} alt='bird' className='w-16 absolute right-[20%] top-[10%]' />
//       </div>
//   )
// }


export default PlaylistView;




export const SongListLoader = () => {

  return (
    <div className="lg:max-h-[calc(100vh-14rem)] overflow-y-auto border-l border-slate-800 p-4 animate-pulse">

      {[...Array(5)].map((_, index) => (
        <div key={index} className="flex items-center gap-3 p-3 bg-slate-800/50 rounded-lg mb-2">
          <div className="w-20 h-12 bg-gray-700 rounded"></div>
          <div className="flex-1 space-y-2">
            <div className="h-4 bg-gray-700 rounded w-3/4"></div>
            <div className="h-3 bg-gray-700 rounded w-1/2"></div>
          </div>
          <div className="h-5 w-5 bg-gray-700 rounded-full"></div>
        </div>
      ))}
    </div>
  );


};