import { Link, useLocation } from "react-router-dom"
import { BiHomeAlt2 } from "react-icons/bi"
import { LuMusic2, LuUserCircle } from "react-icons/lu"
import { VscHeart } from "react-icons/vsc";
import logo from "../../assets/footer-frog.png"
import { useSelector } from "react-redux";
import { PiMusicNotesPlusFill } from "react-icons/pi"
import { TbMusicSearch, TbPremiumRights } from "react-icons/tb";
import { BiSolidPlaylist } from "react-icons/bi";


const Sidebar = ({ children }) => {

    const location = useLocation()
    const { user } = useSelector(state => state.auth)
    const { song } = useSelector(state => state.music)


    return (
        <div className={` w-full min-h-screen bg-gradient-to-tr from-slate-900 to-cyan-950 text-slate-300 font-lato overflow-x-hidden fade-in`} >
            <div className=" fixed top-0 left-0 h-full w-52 lg:w-64 bg-slate-900 hidden md:block flex-shrink-0" >
                <Link to="/" className="flex items-center gap-4 py-4 pl-4 border-b border-slate-800 h-16" >
                    <img src={logo} className="max-w-[40px] w-full rounded-full" alt="img" />
                    <div className=" font-semibold text-center lg:text-xl text-green-400" >SoundOfMeme</div>
                </Link>
                <div className={"mt-4 flex flex-col gap-6 h-full overflow-y-auto  " + (song ? " pb-44 " : " pb-28 ")} >
                    <Link to={"/create"} className={"flex items-center gap-2  border-l-2 p-1.5 pl-3 " + (location.pathname === "/create" ? " border-green-400 font-semibold text-green-400" : " border-gray-950 text-slate-100 ")} > <PiMusicNotesPlusFill size={22} /> <span className=" mt-0.5" >Create</span></Link>
                    <Link to={"/"} className={"flex items-center gap-2  border-l-2 p-1.5 pl-3 " + (location.pathname === "/" ? " border-green-400 text-green-400 font-semibold  bg-slate-900" : " border-gray-950 text-slate-100 ")} > <BiHomeAlt2 size={22} /> <span className=" mt-0.5" >Discover</span></Link>
                    <Link to={"/creations"} className={"flex items-center gap-2  border-l-2 p-1.5 pl-3 " + (location.pathname === "/creations" ? " border-green-400 text-green-400 font-semibold  bg-slate-900" : " border-gray-950 text-slate-100 ")} > <LuMusic2 size={22} /> <span className="" >My Creations</span></Link>
                    {user && <Link to={"/liked"} className={"flex items-center gap-2  border-l-2 p-1.5 pl-3 " + (location.pathname === "/liked" ? " border-green-400 text-green-400 font-semibold  bg-slate-900" : " border-gray-950 text-slate-100 ")} > <VscHeart size={22} /> <span className="" >Liked Songs</span></Link>}
                    {user && <Link to={"/profile"} className={"flex items-center gap-2  border-l-2 p-1.5 pl-3 " + (location.pathname === "/profile" ? " border-green-400 text-green-400 font-semibold  bg-slate-900" : " border-gray-950 text-slate-100 ")} > <LuUserCircle size={22} /> <span className="" >Profile</span></Link>}
                    <Link to={"/search"} className={"flex items-center gap-2  border-l-2 p-1.5 pl-3 " + (location.pathname === "/search" ? " border-green-400 text-green-400 font-semibold  bg-slate-900" : " border-gray-950 text-slate-100 ")} > <TbMusicSearch size={22} /> <span className="" >Search</span></Link>
                    {(!user || user?.plan === 0) && <Link to={"/premium"} className={"flex items-center gap-2  border-l-2 p-1.5 pl-3 " + (location.pathname === "/premium" ? " border-green-400 text-green-400 font-semibold  bg-slate-900" : " border-gray-950 text-slate-100 ")} > <TbPremiumRights size={24} /> <span className="" >Buy Premium</span></Link>}
                    {user && <Link to={"/playlist"} className={"flex items-center gap-2  border-l-2 p-1.5 pl-3 " + (location.pathname === "/playlist" ? " border-green-400 text-green-400 font-semibold  bg-slate-900" : " border-gray-950 text-slate-100  ")} > <BiSolidPlaylist size={22} /> <span className="" >Playlist</span></Link>}
                </div>
            </div>
            <div className="h-full md:ml-52 lg:ml-64 " >{children}</div>
        </div>
    )
}

export default Sidebar