import { REMOVE_DELETED_SONG, SET_MOBILEPLAYER, SET_PLAYING, SET_PLAYINGFROM, SET_PLAYLOADING, SET_SHOWVIEWSONG, SET_SONG, SET_VIEWSONG, SET_VIEWSONGFROM } from "@/redux/features/music/musicSlice"
import axios from "axios"
import { useState } from "react"
import toast from "react-hot-toast"
import { IoIosShareAlt } from "react-icons/io"
import { IoHeart, IoPlay, IoPlayOutline } from "react-icons/io5"
import { Oval, Rings } from "react-loader-spinner"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Drawer, DrawerContent, DrawerDescription, DrawerTitle, DrawerTrigger } from "../ui/drawer"
import { GoKebabHorizontal } from "react-icons/go"
import { BsArrowsExpandVertical } from "react-icons/bs"
import { MdOutlinePublicOff, MdPublic } from "react-icons/md"
import { HiOutlineArrowPath } from "react-icons/hi2";
import { TbDownload } from "react-icons/tb";
import { GiMusicalNotes } from "react-icons/gi"
import { DropdownMenu, DropdownMenuContent, DropdownMenuGroup, DropdownMenuTrigger } from "../ui/dropdown-menu"
import { DropdownMenuItem } from "@radix-ui/react-dropdown-menu"
import { SET_CLONESONG, SET_CREATEDESC, SET_CUSTOMDESC, SET_CUSTOMLYRICS, SET_CUSTOMTITLE, SET_EXTENDSONG, SET_SELECTEDTAB } from "@/redux/features/music/createSlice"
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogOverlay,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog"
import { MdDelete } from "react-icons/md";
import { AiTwotoneFolderAdd } from "react-icons/ai"
import AddToPlaylistDialog from "./AddToPlaylistDialog"


const SongCardListView = ({ song, playingFrom, author, imageUrl }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [userSong, setUserSong] = useState(song)
    const [loading, setLoading] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState(false)
    const { song: selectedSong, playing, playLoading } = useSelector(state => state.music)
    const { user } = useSelector(state => state.auth)
    const [downloadLoading, setdownloadLoading] = useState(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [isAddToPlaylistOpen, setIsAddToPlaylistOpen] = useState(false);


    const displayImage = imageUrl || userSong?.image_url;





    const handlePlay = () => {
        if (!song?.song_url) {
            toast.error("Cannot play this song, please reload.", { id: "song" })
            return
        }
        dispatch(SET_PLAYING(true))
        dispatch(SET_PLAYINGFROM(playingFrom))
        dispatch(SET_SONG(song))
        if (selectedSong?.song_url !== userSong?.song_url) {
            dispatch(SET_PLAYLOADING(true))
        }
    }

    const handlePause = () => {
        dispatch(SET_PLAYING(false))
    }

    const handleExtend = (e) => {
        e.stopPropagation()
        dispatch(SET_SELECTEDTAB("extend"))
        dispatch(SET_EXTENDSONG({
            song_id: userSong?.song_id,
            song_name: userSong?.song_name
        }))
        navigate("/create")
    }
    const handleClone = (e) => {
        e.stopPropagation()
        dispatch(SET_SELECTEDTAB("clone"))
        dispatch(SET_CLONESONG({
            song_id: userSong?.song_id,
            song_name: userSong?.song_name
        }))
        navigate("/create")
    }

    // copy song link
    const copyToClipboard = (url) => {
        navigator.clipboard.writeText(url).then(() => {
            console.log('URL copied to clipboard!');
            toast.success('URL copied to clipboard!', { id: "copyToast" })
        }).catch(err => {
            console.error('Failed to copy:', err);
        });
    };

    const handleShare = async (song) => {
        const url = `https://song.soundofmeme.com/${song?.slug}`;
        const title = 'Check out this song!';
        // const text = userSong?.song_name + ":";

        if (navigator.share) {
            try {
                await navigator.share({
                    title,
                    // text,
                    url
                });
                console.log('Song shared successfully!');
            } catch (error) {
                console.error('Error sharing:', error);
            }
        } else {
            copyToClipboard(url)
        }
    };

    const handleMobilePlayer = (e) => {
        e.stopPropagation()
        if (song?.song_id !== selectedSong?.song_id) {
            dispatch(SET_VIEWSONG(song))
            dispatch(SET_SHOWVIEWSONG(true))
            dispatch(SET_VIEWSONGFROM(playingFrom))
        } else {
            dispatch(SET_VIEWSONG(null))
            dispatch(SET_SHOWVIEWSONG(false))
            dispatch(SET_VIEWSONGFROM(null))
        }
        dispatch(SET_MOBILEPLAYER(true))
    }

    const handlePublish = async (e) => {
        e.stopPropagation()

        const token = window.localStorage.getItem('token')

        try {
            if (userSong?.publish) {
                setLoading(true)
                toast.loading("Unpublishing", { id: "publish" })
                await axios.post("/private",
                    { song_id: userSong?.song_id },
                    { headers: { Authorization: `Bearer ${token}` } }
                )
                setUserSong(prevSong => ({
                    ...prevSong,
                    publish: !prevSong.publish
                }));
                toast.success("Unpublished", { id: "publish" })
                setLoading(false)
            } else {
                setLoading(true)
                toast.loading("Publishing", { id: "publish" })
                await axios.post("/public",
                    { song_id: userSong?.song_id },
                    { headers: { Authorization: `Bearer ${token}` } }
                )
                setUserSong(prevSong => ({
                    ...prevSong,
                    publish: !prevSong.publish
                }));
                toast.success("Published", { id: "publish" })
                setLoading(false)
            }
        } catch (error) {
            console.log(error);
            setLoading(false)
            toast.error(error?.response?.data?.detail || "Error Occurred", { id: "publish" })

        }
    }

    const handleRecreate = (song) => {

        if (song?.prompt === null || song.prompt === '' || song.prompt === undefined) {
            toast.error('Feature not available on this song', { id: "recreate" })
            return
        }
        if (song?.prompt?.type === "create" || song?.prompt?.type === "custom") {
            if (song.prompt.type === "create") {
                dispatch(SET_SELECTEDTAB('create'))
                dispatch(SET_CREATEDESC(song.prompt.desc))
                navigate("/create")
            } else if (song.prompt.type === "custom") {
                dispatch(SET_SELECTEDTAB('custom'))
                dispatch(SET_CUSTOMDESC(song.prompt.genre))
                dispatch(SET_CUSTOMTITLE(song.prompt.desc))
                dispatch(SET_CUSTOMLYRICS(song.prompt.lyrics2))
                navigate("/create")
            }
        } else {
            toast.error('Feature not available on this song', { id: "recreate" })
            return
        }
    }

    // download song
    function download(url) {
        const a = document.createElement('a');
        a.href = url;
        a.download = `Sound of Meme`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }
    const downloadSong = async (song) => {

        if (!user) {
            return toast.error('Login to download the song')
        }
        const token = window.localStorage.getItem('token')
        try {
            setdownloadLoading(true)
            toast.loading("Starting Download", { id: "download" })
            const response = await axios.get(`/download?song_id=${song?.song_id}`, { headers: { Authorization: `Bearer ${token}` } });
            const signedUrl = response.data.url;
            download(signedUrl);
            setdownloadLoading(false)
            toast.success("Download Started", { id: "download" })
        } catch (error) {
            setdownloadLoading(false)
            console.error('Error fetching signed URL:', error);
            toast.error('Error Occured. Please try again.', { id: "download" });
        }
    };


    const handleDelete = async (id) => {
        try {

            setDeleteLoading(true)
            const token = localStorage.getItem('token')
            const { data } = await axios.post("/deletesong", { song_id: id }, { headers: { Authorization: `Bearer ${token}` } })
            toast.success(data?.message)
            setDeleteLoading(false)
            if (song?.song_id === selectedSong?.song_id) {
                dispatch(SET_SONG(null))
                dispatch(SET_PLAYING(false))
            }
            dispatch(REMOVE_DELETED_SONG(id))
        } catch (error) {
            setDeleteLoading(false)
            console.log(error);
            toast.error(error?.response?.data?.detail || error.message || "Oops! Something went wrong. Please try again.")
        }
    }

    const handleAddToPlaylist = (e) => {
        e.stopPropagation();
        setIsAddToPlaylistOpen(true);
        setIsDrawerOpen(false);
    };

    return (
        <div className={"flex gap-2 py-1 w-full px-4" + (selectedSong?.song_id === userSong?.song_id ? " bg-green-400/10 " : " hover:bg-slate-900 ")}>
            <div className={" flex-shrink-0 w-full max-w-[80px] overflow-hidden rounded-md relative select-none " + (selectedSong?.song_id === userSong?.song_id && playing ? " border-2 animate-border shadow-lg shadow-cyan-300 " : selectedSong?.song_id === userSong?.song_id ? " shadow-lg shadow-cyan-300/70 border border-green-400/75 " : " border border-green-400/60 ")} >
                <img className="w-full h-full object-cover select-none aspect-square" src={displayImage} alt='songCover' />

                <AddToPlaylistDialog
                    isOpen={isAddToPlaylistOpen}
                    onOpenChange={setIsAddToPlaylistOpen}
                    song={song}
                />

                <div className={"absolute inset-0 w-full  flex items-center justify-center   transition-opacity duration-200" + (selectedSong?.song_id === song?.song_id && playing ? " opacity-100  bg-black/60 backdrop-blur " : selectedSong?.song_id === userSong?.song_id ? " opacity-100 bg-black/50 backdrop-blur " : " opacity-0 hover:opacity-100 bg-black/50 ")} >
                    {(selectedSong?.song_id === userSong?.song_id && playLoading) ?
                        <div className="w-full flex items-center justify-center cursor-pointer" >
                            <Oval
                                visible={true}
                                height="20"
                                width="20"
                                strokeWidth={6}
                                strokeWidthSecondary={6}
                                color="#ffffff"
                                ariaLabel="oval-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                            />
                        </div> :
                        (selectedSong?.song_id === userSong?.song_id && playing) ?
                            <div onClick={handlePause} className="w-full flex items-center justify-center cursor-pointer" >
                                <Rings
                                    visible={true}
                                    height="60"
                                    width="60"
                                    color="#ffffff"
                                    ariaLabel="rings-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                /></div>
                            : <div onClick={handlePlay} className="w-full flex items-center justify-center cursor-pointer" ><IoPlayOutline size={30} /> </div>
                    }
                </div>
            </div>
            <div className="flex justify-between flex-grow truncate gap-0 sm:gap-4" >
                <div onClick={handleMobilePlayer} className=" cursor-pointer flex flex-col gap-3 truncate flex-grow " >
                    <p className={" font-lato font-semibold truncate lg:text-xl capitalize " + (userSong?.song_id === selectedSong?.song_id && " cursor-pointer text-green-400 ")} >{userSong?.song_name}</p>
                    <div className={" select-none flex gap-2 text-xs font-semibold text-slate-400 font-comforta " + (userSong?.song_id === selectedSong?.song_id && " cursor-pointer ")} >
                        {userSong?.tags?.slice(0, 3)?.map((tag, index) => (
                            <span key={index} className="capitalize cursor-default truncate" >{tag}</span>
                        ))}
                    </div>
                    <div className="flex items-center gap-4" >
                        <div className="flex gap-1 cursor-default text-xs items-center text-white flex-shrink-0" >
                            <IoPlay size={14} />
                            <span className="text-slate-400">{userSong?.views}</span>
                        </div>
                        <div className="flex gap-1 cursor-default text-xs items-center">
                            <IoHeart size={14} />
                            <span className="text-slate-400">{userSong?.likes}</span>
                        </div>
                        <div className="cursor-default" >
                            {userSong?.publish === true ? <MdPublic size={14} /> : <MdOutlinePublicOff size={14} />}
                        </div>
                    </div>
                </div>
                <div className=" hidden sm:flex items-center justify-end gap-2 lg:gap-4 text-xs lg:text-sm" >
                    {author && (song?.prompt?.type === "create") && <button onClick={() => handleRecreate(song)} type="button" className=" hidden h-8 xl:flex items-center gap-1 text-violet-400 hover:bg-violet-400/10 font-semibold border border-violet-400/50 px-2 py-2 rounded "  > <HiOutlineArrowPath size={18} /> Recreate</button>}
                    {author && <button disabled={loading} onClick={handlePublish} type="button" className="disabled:opacity-50 h-8 flex items-center gap-1 text-blue-400 hover:bg-blue-400/10 font-semibold border border-blue-400/50 px-2 py-2 rounded "  > {!userSong?.publish ? <><MdPublic size={18} /> Publish</> : <><MdOutlinePublicOff size={18} />Unpublish</>}</button>}                    <button onClick={handleClone} type="button" className=" hidden h-8 xl:flex items-center gap-1 text-teal-400 hover:bg-teal-400/10 font-semibold border border-teal-400/50 px-2 py-2 rounded "  > <GiMusicalNotes size={18} /> Clone</button>
                    <button onClick={handleExtend} type="button" className=" hidden h-8 xl:flex items-center gap-1 text-green-400 hover:bg-green-400/10 font-semibold border border-green-400/50 px-2 py-2 rounded "  > <BsArrowsExpandVertical size={18} /> Extend</button>
                    <button onClick={(e) => {
                        e.stopPropagation()
                        handleShare(song)
                    }} type="button" className=" h-8 flex items-center gap-1 text-amber-300 hover:bg-teal-400/10 font-semibold border border-amber-300/50 px-2 py-2 rounded "  > <IoIosShareAlt size={18} /></button>
                    <button onClick={() => downloadSong(song)} type="button" className=" h-8 flex md:hidden lg:flex items-center gap-1 text-cyan-400 hover:bg-cyan-400/10 font-semibold border border-cyan-400/50 px-2 py-2 rounded "  > <TbDownload size={18} /></button>
                    <button onClick={handleAddToPlaylist} type="button" className=" h-8 flex md:hidden lg:flex items-center gap-1 text-cyan-400 hover:bg-cyan-400/10 font-semibold border border-cyan-400/50 px-2 py-2 rounded "  > <AiTwotoneFolderAdd />
                    </button>
                    {author && <Dialog>
                        <DialogTrigger asChild>
                            <button type="button" className=" h-8 flex lg:flex items-center gap-1 text-red-400 hover:bg-red-400/10 font-semibold border border-red-400/50 px-2 py-2 rounded "  > <MdDelete size={18} /></button>
                        </DialogTrigger>
                        <DialogOverlay className="backdrop-blur bg-black/30 z-[999]" >
                            <DialogContent className=" block max-w-sm z-[999] p-4 bg-slate-900 border-slate-700 rounded-lg">
                                <DialogTitle className="text-2xl text-left text-red-500 font-potta" >Delete Song</DialogTitle>
                                <DialogDescription className="text-left text-slate-300 text-lg mt-1" >Are you sure you want to delete this song?</DialogDescription>
                                <div className="flex gap-2 items-center bg-slate-800 rounded-lg mt-3" >
                                    <img className="w-10 rounded-lg" src={song?.image_url} alt="cover" />
                                    <p className="text-slate-300 text-lg font-medium truncate " >{song?.song_name}</p>
                                </div>
                                <div className="grid grid-cols-2 gap-4 mt-4" >
                                    <button disabled={deleteLoading} onClick={() => { handleDelete(song?.song_id) }} type="button" className="disabled:animate-pulse bg-slate-800 hover:bg-slate-700 hover:text-red-500 font-medium text-red-500 px-4 py-2 rounded-lg" > {deleteLoading ? "Deleting..." : "Delete"}</button>
                                    <DialogClose asChild >
                                        <button type="button" className="bg-slate-800 hover:bg-slate-700 hover:text-slate-100 font-medium text-slate-300 px-4 py-2 rounded-lg" >Cancel</button>
                                    </DialogClose>
                                </div>
                            </DialogContent>
                        </DialogOverlay>
                    </Dialog>}
                    <div className="xl:hidden" >
                        <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                                <div className="cursor-pointer pl-4" >
                                    <GoKebabHorizontal size={24} />
                                </div>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent className="bg-black w-auto border-slate-500 text-slate-200 mr-4 mt-2">
                                <DropdownMenuGroup>
                                    <DropdownMenuItem onClick={handleExtend} className="cursor-pointer p-1.5 focus:text-black text-green-400 focus:bg-green-400 flex items-center gap-2 focus:outline-none rounded">
                                        <BsArrowsExpandVertical />
                                        <span>Extend</span>
                                    </DropdownMenuItem>
                                    <DropdownMenuItem onClick={handleClone} className="cursor-pointer p-1.5 focus:text-black text-cyan-400 focus:bg-cyan-400 flex items-center gap-2 focus:outline-none rounded">
                                        <GiMusicalNotes />
                                        <span>Clone</span>
                                    </DropdownMenuItem>
                                    {author && <DropdownMenuItem onClick={() => handleRecreate(song)} className="cursor-pointer p-1.5 focus:text-black text-violet-400 focus:bg-violet-400 flex items-center gap-2 focus:outline-none rounded">
                                        <HiOutlineArrowPath />
                                        <span>Recreate</span>
                                    </DropdownMenuItem>}
                                    <DropdownMenuItem onClick={() => downloadSong(song)} className=" cursor-pointer p-1.5 focus:text-black text-cyan-400 focus:bg-cyan-400 hidden md:flex lg:hidden items-center gap-2 focus:outline-none rounded">
                                        <TbDownload />
                                        <span>Downloadd</span>
                                    </DropdownMenuItem>
                                    <DropdownMenuItem onClick={handleAddToPlaylist} className="lg:hidden cursor-pointer p-1.5 focus:text-black text-cyan-400 focus:bg-cyan-400 flex items-center gap-2 focus:outline-none rounded">
                                        <AiTwotoneFolderAdd />
                                        <span>Add to Playlist</span>
                                    </DropdownMenuItem>
                                </DropdownMenuGroup>
                            </DropdownMenuContent>
                        </DropdownMenu>
                    </div>
                </div>


                <div className="sm:hidden flex items-center " >
                    <Drawer open={isDrawerOpen} onOpenChange={setIsDrawerOpen}>
                        <DrawerTrigger>
                            <GoKebabHorizontal size={40} className="pl-4" />
                        </DrawerTrigger>
                        <DrawerContent className=" font-poppins bg-gradient-to-br from-slate-950 to-slate-900 text-slate-300 pb-8 border-green-400/50" >
                            <div className="flex items-center justify-between  gap-2 my-4 px-4" >
                                <div className="flex-grow truncate" >
                                    <DrawerTitle className={" text-slate-100 font-lato font-bold truncate text-xl capitalize" + (userSong?.song_id === selectedSong?.song_id && " cursor-pointer text-green-400 ")} >{userSong?.song_name}</DrawerTitle>
                                    <DrawerDescription className={"select-none flex gap-2 text-xs font-semibold text-slate-400 font-comforta  " + (userSong?.song_id === selectedSong?.song_id && " cursor-pointer ")} >
                                        {userSong?.tags?.slice(0, 3)?.map((tag, index) => (
                                            <span key={index} className="capitalize truncate" >{tag}</span>
                                        ))}
                                    </DrawerDescription>
                                </div>
                                <img src={userSong?.image_url} className="h-12 rounded flex-shrink" />
                            </div>
                            <div className="flex flex-col gap-2" >
                                <div onClick={(e) => {
                                    e.stopPropagation()
                                    handleShare(song)
                                }} className=" text-lg py-2 px-4 text-amber-300 hover:bg-amber-300/10 w-full cursor-pointer flex gap-2 items-center select-none" >
                                    <IoIosShareAlt size={24} className="cursor-pointer flex-shrink-0" />
                                    Share
                                </div>
                                {author && <button disabled={loading} onClick={handlePublish} type="button" className=" disabled:opacity-50 py-2 px-4 text-lg text-blue-400 hover:bg-blue-400/10 w-full flex items-center gap-2 font-medium "  > {!userSong?.publish ? <><MdPublic size={24} /> Publish</> : <><MdOutlinePublicOff size={24} />Unpublish</>}</button>}
                                <button onClick={handleExtend} type="button" className=" py-2 px-4 text-lg text-green-400 hover:bg-green-400/10 w-full flex items-center gap-2 font-medium  "  > <BsArrowsExpandVertical size={24} /> Extend</button>
                                <button onClick={handleClone} type="button" className=" py-2 px-4 text-lg text-teal-400 hover:bg-teal-400/10 w-full flex items-center gap-2 font-medium  "  > <GiMusicalNotes size={24} /> Clone</button>
                                {author && (song?.prompt?.type === "create") && <button onClick={() => handleRecreate(song)} type="button" className=" py-2 px-4 text-lg text-violet-400 hover:bg-violet-400/10 w-full flex items-center gap-2 font-medium  "  > <HiOutlineArrowPath size={24} /> Recreate</button>}
                                <button disabled={downloadLoading} onClick={() => downloadSong(song)} type="button" className=" disabled:opacity-50 py-2 px-4 text-lg text-cyan-400 hover:bg-cyan-400/10 w-full flex items-center gap-2 font-medium "  ><TbDownload size={24} /> Download</button>
                                <button onClick={handleAddToPlaylist} type="button" className=" disabled:opacity-50 py-2 px-4 text-lg text-cyan-400 hover:bg-cyan-400/10 w-full flex items-center gap-2 font-medium "  ><AiTwotoneFolderAdd size={24} /> Add to Playlist</button>
                                {author && <Dialog>
                                    <DialogTrigger asChild>
                                        <button type="button" className=" disabled:opacity-50 py-2 px-4 text-lg text-red-400 hover:bg-red-400/10 w-full flex items-center gap-2 font-medium "  ><MdDelete size={24} /> Delete</button>
                                    </DialogTrigger>
                                    <DialogOverlay className="z-[999] bg-black/30 backdrop-blur" >

                                        <DialogContent className=" block max-w-sm z-[9999] p-4 bg-slate-900 border-slate-700 rounded-lg">
                                            <DialogTitle className="text-2xl text-left text-green-400 font-semibold" >Delete Song</DialogTitle>
                                            <DialogDescription className="text-left text-slate-300 text-lg mt-1" >Are you sure you want to delete the song?</DialogDescription>
                                            <div className="flex gap-2 items-center bg-slate-800 rounded-lg mt-3" >
                                                <img className="w-10 rounded-lg" src={song?.image_url} alt="cover" />
                                                <p className="text-slate-300 text-lg font-medium truncate " >{song?.song_name}</p>
                                            </div>
                                            <div className="grid grid-cols-2 gap-4 mt-4" >
                                                <button disabled={deleteLoading} onClick={() => { handleDelete(song?.song_id) }} type="button" className=" disabled:animate-pulse bg-slate-800 hover:bg-slate-700 hover:text-red-500 font-medium text-red-500 px-4 py-2 rounded-lg" >{deleteLoading ? "Deleting..." : "Delete"}</button>
                                                <DialogClose asChild >
                                                    <button type="button" className="bg-slate-800 hover:bg-slate-700 hover:text-slate-100 font-medium text-slate-300 px-4 py-2 rounded-lg" >Cancel</button>
                                                </DialogClose>
                                            </div>
                                        </DialogContent>
                                    </DialogOverlay>
                                </Dialog>}
                            </div>
                        </DrawerContent>
                    </Drawer>
                </div>
            </div>
        </div>
    )

}

export default SongCardListView

export const SongCardMyCreationSkeleton = () => {
    return (
        <div className="w-full flex items-center gap-2 animate-pulse py-1 px-4" >
            <div className="w-full max-w-[80px] aspect-square rounded bg-slate-800" ></div>
            <div className="flex items-center gap-4 flex-grow h-full" >
                <div className="flex flex-col justify-between gap-2 h-full flex-grow" >
                    <div className="h-4 rounded-full mt-1 max-w-sm w-full bg-slate-800" ></div>
                    <div className="flex items-center gap-2" >
                        <div className="w-10 bg-slate-800 rounded-full h-3" ></div>
                        <div className="w-10 bg-slate-800 rounded-full h-3" ></div>
                        <div className="w-10 bg-slate-800 rounded-full h-3" ></div>
                    </div>
                    <div className="flex items-center gap-2 mb-1" >
                        <div className="w-10 bg-slate-800 rounded-full h-3" ></div>
                        <div className="w-10 bg-slate-800 rounded-full h-3" ></div>
                        <div className="w-3 bg-slate-800 rounded-full h-3" ></div>
                        <div className="w-3 bg-slate-800 rounded-full h-3" ></div>
                    </div>
                </div>
                <div className="flex items-center justify-end gap-2 lg:gap-4 flex-grow" >
                    <div className=" hidden sm:block h-7 w-16 lg:w-20 bg-slate-800 rounded" ></div>
                    <div className=" hidden xl:block h-7 w-16 lg:w-20 bg-slate-800 rounded" ></div>
                    <div className=" hidden xl:block h-7 w-16 lg:w-20 bg-slate-800 rounded" ></div>
                    <div className=" hidden sm:block md:hidden lg:block h-7 w-9 bg-slate-800 rounded" ></div>
                    <div className=" hidden sm:block h-7 w-9 bg-slate-800 rounded" ></div>
                    <div className=" hidden sm:block h-7 w-9 bg-slate-800 rounded" ></div>
                    <GoKebabHorizontal className="opacity-50 ml-4 xl:hidden" size={24} />
                </div>
            </div>
        </div>
    )
}