import { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { Dialog, DialogTrigger, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter, DialogClose } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { TbPlaylistAdd } from "react-icons/tb";
import imgGreenBird from '../../assets/greenbird.png';
import InfiniteScroll from 'react-infinite-scroll-component';
import axios from 'axios';
import { addPlaylist } from '@/redux/features/music/playlistSlice';
import { useDispatch, useSelector } from 'react-redux';
import PlaylistLoader from '../playlist/PlaylistLoader';

const PlaylistsComponent = () => {
    const dispatch = useDispatch();
    const { playlists } = useSelector((state) => state.playlists);
    const [localPlaylists, setLocalPlaylists] = useState([]);
    const [newPlaylistName, setNewPlaylistName] = useState('');
    const [showDialog, setShowDialog] = useState(false);
    const [isAdding, setIsAdding] = useState(false);
    const [publish, setPublish] = useState(false);
    const { isLoading } = useSelector((state) => state.playlists);
    const { song } = useSelector(state => state.music);

    // Local state for pagination
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);

    const stackedCards = [0, 1, 2];

    // Fetch playlists
    const fetchPlaylists = useCallback(async (currentPage) => {
        try {
            const token = localStorage.getItem('token');
            const res = await axios.get(`/playlists?page=${currentPage}`, {
                headers: { Authorization: `Bearer ${token}` },
            });

            const fetchedPlaylists = res.data.playlists;

            setLocalPlaylists((prevPlaylists) => [
                ...prevPlaylists,
                ...fetchedPlaylists.filter(
                    (playlist) => !prevPlaylists.some((p) => p.playlist_slug === playlist.playlist_slug)
                ),
            ]);

            setHasMore(fetchedPlaylists.length === 20); // Assuming 20 is the page size
            setPage(currentPage); // Update current page after successful fetch
        } catch (error) {
            toast('Login to view playlists.', {
                icon: '⚠️',
                id: 'login-to-view-playlists',
            });
            setHasMore(false);
        }
    }, []);

    useEffect(() => {
        if (playlists.length === 0) {
            setLocalPlaylists([]);
        }
    }, [playlists]);


    useEffect(() => {
        setLocalPlaylists((prev) => {
            // Merge new playlists from global state, ensuring no duplicates
            const uniquePlaylists = playlists.filter(
                (newPlaylist) => !prev.some((existingPlaylist) => existingPlaylist.playlist_slug === newPlaylist.playlist_slug)
            );
            return [...uniquePlaylists, ...prev]; // Add new playlists at the top
        });
    }, [playlists, page]);



    // Initial and subsequent playlist fetches
    useEffect(() => {
        fetchPlaylists(1);
    }, [fetchPlaylists]);


    const handleMore = useCallback(() => {
        if (!hasMore) return;
        fetchPlaylists(page + 1);
    }, [hasMore, fetchPlaylists, page]);


    // Add new playlist
    const handleAddPlaylist = useCallback(async () => {
        if (newPlaylistName.trim() === '') {
            toast.error("Playlist name cannot be empty");
            return;
        }
        try {
            setIsAdding(true);
            const newPlaylist = await dispatch(addPlaylist({
                playlistName: newPlaylistName,
                publish
            })).unwrap();

            if (newPlaylist) {
                // dispatch(APPEND_PLAYLIST_TO_PLAYLIST(newPlaylist));
                setLocalPlaylists((prevPlaylists) => [newPlaylist, ...prevPlaylists]);

            }

            // Reset states
            setNewPlaylistName('');
            setShowDialog(false);
            setPublish(false);
        } catch (error) {
            toast.error(`Failed to create playlist: ${error.response?.data?.message || error.message}`);
        } finally {
            setIsAdding(false);
        }
    }, [newPlaylistName, publish, dispatch]);




    // Loading state for initial load
    if (isLoading && page === 1) {
        return <div><Loader /></div>;
    }

    return (
        <div className='overflow-hidden'>
            <div className={`flex flex-row justify-between `}>
                <div className="flex flex-col gap-1">
                    <h1 className="lg:text-2xl text-lg font-semibold bg-gradient-to-r from-yellow-400 via-green-400 to-yellow-500 text-transparent bg-clip-text">Curated Collections ✨</h1>
                    <p className="lg:text-sm text-xs bg-gradient-to-r from-slate-300 via-yellow-600 to-red-700 text-transparent bg-clip-text italic">Every mood, every moment, your way</p>
                </div>

                <Dialog open={showDialog} onOpenChange={setShowDialog}>
                    <DialogTrigger asChild>
                        <button className="text-green-400 hover:text-green-300 transition-colors">
                            <TbPlaylistAdd size={40} />
                        </button>
                    </DialogTrigger>
                    <DialogContent className="bg-slate-900 text-slate-300 border-slate-600">
                        <DialogHeader>
                            <DialogTitle>Add New Playlist</DialogTitle>
                            <DialogDescription className="text-slate-200">
                                Fill in the details for your new playlist.
                            </DialogDescription>
                        </DialogHeader>
                        <div className="space-y-4">
                            <input
                                type="text"
                                placeholder="Playlist Name"
                                value={newPlaylistName}
                                onChange={(e) => setNewPlaylistName(e.target.value)}
                                className="w-full p-2 rounded bg-slate-800 text-sm text-slate-200 placeholder-slate-300"
                            />
                            <label className="switch flex items-center justify-between border-b pb-1 border-slate-700 cursor-pointer ">
                                <p className="font-semibold text-sm">Publish</p>
                                <input checked={publish} onChange={(e) => setPublish(e.target.checked)} type="checkbox" />
                                <span className="slider"></span>
                            </label>
                        </div>
                        <DialogFooter>
                            <Button
                                onClick={handleAddPlaylist}
                                className="bg-green-400 hover:bg-green-300 text-slate-700"
                            >
                                {isAdding ? 'Creating...' : 'Save'}
                            </Button>
                            <DialogClose asChild>
                                <Button className="bg-slate-700 hover:bg-slate-800 text-slate-300">
                                    Cancel
                                </Button>
                            </DialogClose>
                        </DialogFooter>
                    </DialogContent>
                </Dialog>
            </div>

            <InfiniteScroll
                dataLength={localPlaylists.length}
                next={handleMore}
                hasMore={hasMore}
                loader={<Loader />}
                style={{ overflow: 'visible' }}
                className={`flex-grow sm:py-4 w-full grid sm:gap-6 md:gap-6 lg:gap-8 xl:gap-8 sm:mt-6 md:mt-6 mr-2 sm:mr-3 md:mr-4 lg:mr-5 xl:mr-6 ml-[4px] ${song ? "pb-36 md:pb-28" : "pb-24"} grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-x-4 sm:gap-y-8 sm:pr-5`}
            >
                {localPlaylists?.length > 0 ? (
                    localPlaylists.map((playlist) => (
                        <Link
                            to={`/playlistview/${playlist?.playlist_slug}`}
                            key={playlist?.id || playlist?.playlist_id}
                            className="relative group"
                        >
                            <div className="relative w-full aspect-square flex items-center justify-start sm:justify-center hover:rotate-1 transition-transform duration-300 ease-in-out">
                                {stackedCards.map((_, index) => (
                                    <div
                                        key={`${playlist?.playlist_id}-${index}`}
                                        className={`absolute w-5/6 h-3/4 sm:w-full sm:h-full md:w-full md:h-full rounded-lg 
                                ${index === 0 ? 'z-30 hover:-translate-x-1 hover:-rotate-2 transition-transform duration-300' : ''}
                                ${index === 1 ? 'z-20 -translate-y-0 rotate-2 translate-x-2' : ''} 
                                ${index === 2 ? 'z-10 -translate-y-0 rotate-3 translate-x-4' : ''}`}
                                    >
                                        <div className={`relative w-full h-full rounded-lg p-[2px] bg-gradient-to-r from-green-500 to-green-300 shadow-[0_0_10px_rgba(34,197,94,0.5)]
                                ${index !== 0 ? 'opacity-50' : ''}`}>
                                            <div className="relative w-full h-full bg-gray-900 rounded-lg overflow-hidden">
                                                <div className="absolute top-2 left-2 bg-black/50 text-white text-xs px-2 py-1 rounded-full z-20">
                                                    {playlist?.song_count || 0} Songs
                                                </div>
                                                <img
                                                    src={playlist.images?.[0] || imgGreenBird}
                                                    alt={playlist.name}
                                                    className="w-full h-full object-cover"
                                                />
                                                <div className="absolute bottom-0 w-full p-4 bg-gradient-to-t from-black/80 to-transparent">
                                                    <h3 className="text-white text-lg font-semibold truncate">
                                                        {playlist?.name || 'Hello'}
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </Link>
                    ))
                ) : (
                    <p className="col-span-full flex justify-center items-end text-slate-400"></p>
                )}
            </InfiniteScroll>
        </div>
    );
};
export default PlaylistsComponent;



function Loader() {
    return (
        <>
            <PlaylistLoader />
            <PlaylistLoader />
            <PlaylistLoader />
            <PlaylistLoader />
            <PlaylistLoader />
            <PlaylistLoader />
            <PlaylistLoader />
            <PlaylistLoader />
            <PlaylistLoader />
            <PlaylistLoader />
            <PlaylistLoader />
        </>
    )
}