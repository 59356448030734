

const PlaylistsLoader = () => {
    const stackedCards = [0, 1, 2];

    return (
        <div>
            <div>
              
                    <div 
                       
                        className="relative animate-pulse transition-transform duration-600 group-hover:scale-105 group-hover:-translate-y-1"
                    >
                        <div className=" w-full aspect-square flex items-center justify-center">
                            {stackedCards.map((cardIndex) => (
                                <div
                                    key={cardIndex}
                                    className={`
                                        absolute 
                                        sm:w-full sm:h-full
                                        md:w-full md:h-full
                                        w-3/4 h-3/4
                                        rounded-lg
                                        ${cardIndex === 0 ? 'z-30' : ''} 
                                        ${cardIndex === 1 ? 'z-20 -translate-y-0 rotate-2 translate-x-2' : ''} 
                                        ${cardIndex === 2 ? 'z-10 -translate-y-0 rotate-3 translate-x-4' : ''}
                                    `}
                                >
                                    <div className={`
                                        relative 
                                        w-full 
                                        h-full 
                                        rounded-lg 
                                        p-[2px]
                                        bg-gradient-to-r 
                                        from-green-500 
                                        to-green-300
                                        shadow-[0_0_10px_rgba(34,197,94,0.5)]
                                        ${cardIndex !== 0 ? 'opacity-50' : ''}
                                    `}>
                                        <div className="relative w-full h-full bg-gray-900 rounded-lg overflow-hidden">
                                            <div className="absolute top-2 left-2 bg-black/50 text-white text-xs px-2 py-1 rounded-full z-20">
                                                <div className="h-4 w-12 bg-gray-700 rounded"></div>
                                            </div>

                                            <div className="w-full h-full flex flex-col">
                                                <div className="w-full h-full bg-gray-800"></div>

                                                <div className="absolute bottom-0 w-full p-4 bg-gradient-to-t from-black/80 to-transparent">
                                                    <div className="h-6 w-3/4 bg-gray-700 rounded mb-2"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
               
            </div>
        </div>
    );
};

export default PlaylistsLoader;